/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import CrewsEditorWindow from '../../Window/CrewsEditorWindow.js';

export default function CrewsFilteredGridEditorPanel(props) {
	return <GridPanel
				reference="CrewsFilteredGridEditorPanel"
				model="Crews"
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={CrewsEditorWindow}
				columnsConfig={[
				    {
				        "header": "User",
				        "fieldName": "users__username",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Is Active?",
				        "fieldName": "crews__is_active",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Crew Name",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Back To Shop",
				        "fieldName": "crews__back_to_shop",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    }
				]}
				
				
				{...props}
			/>;
}