/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ItemsEditorWindow from '../Window/ItemsEditorWindow.js';

export default function ItemsGridEditor(props) {
	return <WindowedGridEditor
				reference="ItemsGridEditor"
				model="Items"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ItemsEditorWindow}
				columnsConfig={[
				    {
				        "header": "Label",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Description",
				        "fieldName": "items__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Price",
				        "fieldName": "items__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Client",
				        "fieldName": "clients__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 300 // MOD
				    }
				]}

				{...props}
			/>;
}