/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	setCurrentScreen,
	selectUser,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectIsWaitModalShown,
} from '../models/Slices/DebugSlice';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';

import CaretDown from '@onehat/ui/src/Components/Icons/CaretDown';
import CashRegister from '@onehat/ui/src/Components/Icons/CashRegister';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import House from '@onehat/ui/src/Components/Icons/House';
import Hammer from '@onehat/ui/src/Components/Icons/Hammer';
import Gauge from '@onehat/ui/src/Components/Icons/Gauge';
import MoneyBill from '@onehat/ui/src/Components/Icons/MoneyBill';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';

import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import Home from '../screens/Home';
import Logout from '../screens/Logout';
import Dashboard from '../screens/Dashboard';
import WorkOrdersManager from '../screens/WorkOrdersManager';
import PaymentsManager from '../screens/PaymentsManager';
import DiscountsManager from '../screens/DiscountsManager';
import DropdownsManager from '../screens/DropdownsManager';
import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import Util from '../screens/Util';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const
	Stack = createStackNavigator(),
	urlPrefix = '/' + AppGlobals.urlPrefix,
	tabs = [
		{
			title: 'Home',
			_icon: { as: House },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Home')),
			screenName: 'Home',
		},
		{
			title: 'Dashboard',
			_icon: { as: Gauge },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Dashboard')),
			screenName: 'Dashboard',
		},
		{
			title: 'Work Orders',
			_icon: { as: Clipboard },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders')),
			screenName: 'WorkOrdersManager',
		},
		{
			title: 'Payments',
			_icon: { as: MoneyBill },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Payments')),
			screenName: 'PaymentsManager',
		},
		{
			title: 'Discounts',
			_icon: { as: CashRegister },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Discounts')),
			screenName: 'DiscountsManager',
		},
		{
			title: 'Dropdowns',
			_icon: { as: CaretDown },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Dropdowns')),
			screenName: 'DropdownsManager',
		},
		{
			title: 'Reports',
			_icon: { as: ChartPie },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Reports')),
			screenName: 'ReportsManager',
		},
		{
			title: 'Users',
			_icon: { as: User },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Users')),
			screenName: 'UsersManager',
		},
		{
			title: 'Groups',
			_icon: { as: UserGroup },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Groups')),
			screenName: 'GroupsManager',
		},
		{
			title: 'Utilities',
			_icon: { as: Hammer },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Util')),
			screenName: 'Util',
		},
		{
			title: 'Logout',
			_icon: { as: RightFromBracket },
			path: urlPrefix + Inflector.dasherize(Inflector.underscore('Logout')),
			screenName: 'Logout',
		},
	];

function AppNavigator(props) {
	const
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		user = useSelector(selectUser),
		[currentTabIx, setCurrentTabIx] = useState(0),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Home'),
		onChangeCurrentTab = (ix) => {
			// update state
			setCurrentTabIx(ix);

			// switch nav
			const
				currentTab = tabs[ix],
				path = currentTab.path;
			linkTo(path);
			dispatch(setCurrentScreen(currentTab.screenName));
		};

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(urlPrefix + Inflector.dasherize(Inflector.underscore('Login')));
				return
			}

			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;

			setCurrentTabIx(whichTabIx);
			if (whichTab) {
				setInitialRouteName(whichTab.screenName);
			}
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'Jimax Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};

	return <Row flex={1} w="100%">
				{isWaitModalShown && <WaitMessage />}
				{alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}

				{!!currentTabIx && currentTabIx !== tabs.length -1 && // hide TabBar if showing Home or Logout
					<TabBar
						tabs={tabs}
						direction={VERTICAL}
						tabWidth={180}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={onChangeCurrentTab}
					/>}
				<Stack.Navigator {...navigatorOptions}>
					<Stack.Screen name="Home">
						{() => <Home tabs={tabs} onChangeCurrentTab={onChangeCurrentTab} />}
					</Stack.Screen>
					<Stack.Screen name="Dashboard" component={Dashboard} />
					<Stack.Screen name="WorkOrdersManager" component={WorkOrdersManager} />
					<Stack.Screen name="PaymentsManager" component={PaymentsManager} />
					<Stack.Screen name="DiscountsManager" component={DiscountsManager} />
					<Stack.Screen name="DropdownsManager" component={DropdownsManager} />
					<Stack.Screen name="ReportsManager" component={ReportsManager} />
					<Stack.Screen name="UsersManager" component={UsersManager} />
					<Stack.Screen name="GroupsManager" component={GroupsManager} />
					<Stack.Screen name="Util" component={Util} />
					<Stack.Screen name="Logout" component={Logout} />
				</Stack.Navigator>
			</Row>;
}

export default AppNavigator;

