/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import PaymentsEditorWindow from '../Window/PaymentsEditorWindow.js';

export default function PaymentsGridEditor(props) {
	return <WindowedGridEditor
				reference="PaymentsGridEditor"
				model="Payments"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={PaymentsEditorWindow}
				defaultRowHeight={24}
				columnsConfig={[
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Amount",
				        "fieldName": "payments__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Payment Date",
				        "fieldName": "payments__payment_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Comments",
				        "fieldName": "payments__comments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 400 // MOD
				    },
				    {
				        "header": "Display Name",
				        "fieldName": "payments__display_name",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}