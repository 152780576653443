import { useState, useEffect, useRef } from 'react';
import {
	Keyboard,
} from 'react-native';
import {
	Button,
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import {
	useNavigation,
} from '@react-navigation/native';
import { useSelector, useDispatch } from 'react-redux';
import {
	setUserThunk,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	setDebugMessage,
	setInfoMessage,
	setIsWaitModalShown,
	selectDebugMessage,
	selectAlertMessage,
	selectIsWaitModalShown,
	selectInfoMessage,
} from '../models/Slices/DebugSlice';
import oneHatData from '@onehat/data';
import getSecure from '@onehat/ui/src/Functions/getSecure';
import Input from '@onehat/ui/src/Components/Form/Field/Input';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox.js';
import Eye from '@onehat/ui/src/Components/Icons/Eye.js';
import EyeSlash from '@onehat/ui/src/Components/Icons/EyeSlash.js';
import RightToBracket from '@onehat/ui/src/Components/Icons/RightToBracket.js';
import ChevronRight from '@onehat/ui/src/Components/Icons/ChevronRight.js';
import User from '@onehat/ui/src/Components/Icons/User.js';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';
import Loading from '@onehat/ui/src/Components/Messages/Loading.js';
import appPng from '../components/Images/JimaxLogo.png';
import _ from 'lodash';

const 
	USERNAME = null, // 'coach', // 
	PASSWORD = null; // 'coach'; // 
	
export default function Login() {
	const
		navigation = useNavigation(),
		dispatch = useDispatch(),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		infoMessage = useSelector(selectInfoMessage),
		passwordRef = useRef(),
		usernameRef= useRef(),
		[isReady, setIsReady] = useState(false),
		[username, setUsername] = useState(USERNAME),
		[isPasswordPlaintext, setIsPasswordPlaintext] = useState(false),
		[password, setPassword] = useState(PASSWORD),
		Users = oneHatData.getRepository('Users'),
		Secure = oneHatData.getRepository('Secure'),
		isValid = !!(username && password),
		applyDefaultUsername = () => {
			getSecure('username').then((username) => {
				if (username) {
					setUsername(username);
				}
			});
		},
		login = async () => {
			try {
				if (!isValid) {
					dispatch(setAlertMessage('Please completely fill out the form first.'));
					return;
				}

				dispatch(setIsWaitModalShown(true));

				Keyboard.dismiss();

				// Login user
				let isError = false;
				const userData = await Users.login({
					username,
					password,
				}).catch(function (error) {
					if (error?.message === 'Please upgrade your password.') {
						navigation.navigate('UpgradePassword');
						return;
					}
					dispatch(setAlertMessage(error.message));
					isError = true;
				});
				if (isError || !userData) {
					dispatch(setIsWaitModalShown(false));
					return;
				}

				const user = await Users.createStandaloneEntity(userData);
				const setUserFn = setUserThunk(user);
				await dispatch(setUserFn);

				setPassword(); // Clear the password field

				navigation.replace('AppNavigator');

			} catch(error) {
				dispatch(setAlertMessage(error?.message));
			}
			dispatch(setIsWaitModalShown(false));
		};
		
	useEffect(() => {
		if (Users.isDestroyed) {
			return; // for auto-reload of Expo
		}

		applyDefaultUsername();
		
		setIsReady(true);

	}, [Users]);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}
	
	const
		inputProps = {
			w: '100%',
			borderWidth: 1,
			borderColor: 'trueGray.400',
			mb: 2,
		};
	
	return <CenterBox bg="trueGray.300">
				{isWaitModalShown && <WaitMessage />}
				{alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}
				{infoMessage && <ErrorMessage text={infoMessage} color="#000" onOk={() => dispatch(setInfoMessage(null))} />}
				<Column
					justifyContent="center"
					alignItems="center"
					h={350}
					w={400}
					borderRadius={20}
					p={5}
					bg="#fff"
				>
					<Image
						{...testProps('AppImg')}
						source={appPng}
						resizeMode="contain"
						alt="App Logo"
						h={150}
						w="90%"
						mb={3}
					/>
					<Text color="primary.500" fontWeight="bold" fontSize={20} mb={2}>Admin App</Text>
					<Input
						{...testProps('username')}
						ref={usernameRef}
						placeholder="Username"
						value={username}
						onChangeText={setUsername}
						InputLeftElement={<Pressable onPress={() => {
												usernameRef.current.focus();
											}}>
												<Icon
													as={User}
													size={5}
													ml="2"
													color="muted.400"
												/>
											</Pressable>}
						onSubmitEditing={login}
						variant="form"
						{...inputProps}
					/>
					<Input
						{...testProps('password')}
						ref={passwordRef}
						placeholder="Password"
						value={password}
						onChangeText={setPassword}
						onSubmitEditing={login}
						secureTextEntry={!isPasswordPlaintext}
						type={isPasswordPlaintext ? 'text' : 'password'}
						overflow="visible"
						InputRightElement={<Pressable onPress={() => {
												passwordRef.current.focus();
												setIsPasswordPlaintext(!isPasswordPlaintext);
											}}>
												<Icon
													as={isPasswordPlaintext ? EyeSlash : Eye}
													size={5}
													mr="2"
													color="trueGray.400"
												/>
											</Pressable>}
						variant="form"
						// onBlur={(e) => {
						// 	Keyboard.dismiss();
						// }}
						{...inputProps}
					/>

					<Button
						{...testProps('loginBtn')}
						leftIcon={<Icon as={RightToBracket} color="#fff" />}
						isDisabled={!isValid}
						onPress={ login }
						bg="primary.600"
					>
						Sign In
					</Button>

				</Column>
				<Button
					{...testProps('toCrewAppBtn')}
					rightIcon={<Icon as={ChevronRight} color="#fff" />}
					onPress={() => { location.href = '/crew/login'; }}
					bg="trueGray.400"
					mt={10}
				>
					To Crew App
				</Button>
			</CenterBox>;
}
