/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function TimeEntriesGrid(props) {
	return <Grid
				reference="TimeEntriesGrid"
				model="TimeEntries"
				columnsConfig={[
				    {
				        "header": "Start Time",
				        "fieldName": "time_entries__start_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "End Time",
				        "fieldName": "time_entries__end_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Description",
				        "fieldName": "time_entries__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}

				{...props}
			/>;
}