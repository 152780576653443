/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import PaymentsEditorWindow from '../../../Window/PaymentsEditorWindow.js';

function PaymentsComboEditor(props) {
	return <ComboEditor
				reference="PaymentsComboEditor"
				model="Payments"
				uniqueRepository={true}
				Editor={PaymentsEditorWindow}
				{...props}
			/>;
}

export default PaymentsComboEditor;