import { useState, } from 'react';
import {
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import chunkArray from '../functions/chunkArray';
import CaretDown from '@onehat/ui/src/Components/Icons/CaretDown';
import CashRegister from '@onehat/ui/src/Components/Icons/CashRegister';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import Gauge from '@onehat/ui/src/Components/Icons/Gauge';
import MoneyBill from '@onehat/ui/src/Components/Icons/MoneyBill';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import appPng from '../components/Images/JimaxLogo.png';
import Inflector from 'inflector-js';
import AppGlobals from '../AppGlobals';


function Box(props) {
	const {
			url,
			text,
			icon,
			onNavigate,
		} = props,
		[containerWidth, setContainerWidth] = useState(100),
		[isRendered, setIsRendered] = useState(false),
		onPress = () => {
			onNavigate(url);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	let fontSize = '15px',
		iconSize = '40px';
	if (containerWidth > 200) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 300) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 400) {
		fontSize = '35px';
		iconSize = '65px';
	}
	if (containerWidth > 500) {
		fontSize = '25px';
		iconSize = '65px';
	}

	return <Pressable
				{...testProps(url)}
				onLayout={onLayout} 
				onPress={onPress} 
				flex={1}
				_hover={{ bg: "primary.300" }}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				bg="primary.700"
				p={5}
				mx={2}
				borderRadius={10}
			>
				{isRendered &&
					<>
						<Icon
							as={icon}
							color="#fff"
							size={iconSize}
							minHeight={5}
							mb={2}
						/>
						<Text
							fontSize={fontSize}
							lineHeight={fontSize}
							style={{
								fontFamily: 'OpenSansCondensed_700Bold',
							}}
							color="#fff"
							textAlign="center"
						>{text}</Text>
					</>}
			</Pressable>;
}

export default function Home(props) {

	const {
			tabs,
			onChangeCurrentTab,
		} = props,
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Home',
		[containerWidth, setContainerWidth] = useState(500),
		[isRendered, setIsRendered] = useState(false),
		onNavigate = (path) => {
			const 
				whichTab = _.find(tabs, (tab) => {
					return tab.path === path;
				}),
				whichTabIx = whichTab ? tabs.indexOf(whichTab) : 0;
			onChangeCurrentTab(whichTabIx);
		},
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	if (!isActive) {
		return null;
	}

	const
		urlPrefix = '/' + AppGlobals.urlPrefix,
		rows = [],
		boxes = [
			<Box
				text="Dashboard"
				key="Dashboard"
				icon={Gauge}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Dashboard'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Work Orders"
				key="WorkOrders"
				icon={Clipboard}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Payments"
				key="Payments"
				icon={MoneyBill}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Payments'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Discounts"
				key="Discounts"
				icon={CashRegister}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Discounts'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Payments"
				key="Payments"
				icon={MoneyBill}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Payments'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Dropdowns"
				key="Dropdowns"
				icon={CaretDown}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Dropdowns'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Reports"
				key="Reports"
				icon={ChartPie}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Reports'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Users"
				key="Users"
				icon={User}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Users'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Groups"
				key="Groups"
				icon={UserGroup}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Groups'))}
				onNavigate={onNavigate}
			/>,
			<Box
				text="Logout"
				key="Logout"
				icon={RightFromBracket}
				url={urlPrefix + Inflector.dasherize(Inflector.underscore('Logout'))}
				onNavigate={onNavigate}
			/>,
		];

	let boxesPerRow = 1;
	if (containerWidth > 400) {
		boxesPerRow = 2;
	}
	if (containerWidth > 600) {
		boxesPerRow = 3;
	}
	if (containerWidth > 800) {
		boxesPerRow = 4;
	}

	const chunks = chunkArray(boxes, boxesPerRow);
	_.each(chunks, (boxes, ix) => {
		rows.push(<Row key={'row' + ix} flex={1} justifyContent="space-between" mb={3}>{boxes}</Row>);
	});
	
	return <Column flex={1} w="100%" p={10} onLayout={onLayout} >
				{isRendered && 
					<>
						<Column flex={3} pb={5}>
							<Image
								source={appPng}
								margin="auto"
								resizeMode="contain"
								alt="App Logo"
								flex={1}
								w="100%"
							/>
						</Column>
						<Column flex={5}>
							{rows}
						</Column>
					</>}
			</Column>;

}