import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import PaymentsFilteredInlineGridEditor from '../components/Grid/PaymentsFilteredInlineGridEditor.js';
import PaymentsFilteredSideGridEditor from '../components/Grid/PaymentsFilteredSideGridEditor.js';

export default function PaymentsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'PaymentsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Payments"
				reference="PaymentsManager"
				fullModeComponent={<PaymentsFilteredInlineGridEditor
										reference="PaymentsFilteredInlineGridEditor"
										useUploadDownload={true}
									/>}
				sideModeComponent={<PaymentsFilteredSideGridEditor
										reference="PaymentsFilteredSideGridEditor"
										useUploadDownload={true}
									/>}
			/>;
}
