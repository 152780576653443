import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WorkOrders = {
	
	name: 'WorkOrders',
	model: {

		idProperty: 'work_orders__id',
		displayProperty: 'work_orders__work_order_number',
		sortProperty: 'work_orders__date',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			work_orders__work_order_number: yup.string().max(100).required(),
			work_orders__case_number: yup.string().max(100).required(),
			work_orders__inspector: yup.string().max(100).required(),
			work_orders__work_order_status_id: yup.number().integer().required(),
			work_orders__client_id: yup.number().integer().required(),
			work_orders__work_order_type_id: yup.number().integer().nullable(),
			work_orders__lot_type_id: yup.number().integer().required(),
			work_orders__address_number: yup.string().max(40).nullable(),
			work_orders__address_direction: yup.string().max(10).nullable(),
			work_orders__address_street: yup.string().max(200).nullable(),
			work_orders__work_description: yup.string().max(1000).nullable(),
			work_orders__legal_description: yup.string().max(1000).nullable(),
			work_orders__tax_id_number: yup.string().max(100).nullable(),
			work_orders__items: yup.string().nullable(),
			work_orders__attributes: yup.string().nullable(),
			work_orders__estimate: yup.number().nullable(),
			work_orders__is_bid: yup.boolean().nullable(),
			work_orders__is_billed: yup.boolean().required(),
			work_orders__is_bid_approved: yup.boolean().nullable(),
			work_orders__user_id: yup.number().integer().required(),
			work_orders__date: yup.date().required(),
			work_orders__assigned_date: yup.date().nullable(),
			work_orders__completed_date: yup.date().nullable(),
			work_orders__invoiced_date: yup.date().nullable(),
			work_orders__paid_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__uuid', mapping: 'uuid', title: 'UUID', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__work_order_number', mapping: 'work_order_number', title: 'Work Order #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__case_number', mapping: 'case_number', title: 'Case Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__inspector', mapping: 'inspector', title: 'Inspector', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__work_order_status_id', mapping: 'work_order_status_id', title: 'Status', type: 'int', isFk: true, fkIdField: 'work_order_statuses__id', fkDisplayField: 'work_order_statuses__name', filterType: {"type":"WorkOrderStatusesCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrderStatusesCombo","loadAfterRender":!1}, fieldGroup: '1. Identifiers', defaultValue: 1, },
			{ name: 'work_orders__client_id', mapping: 'client_id', title: 'Client', type: 'int', isFk: true, fkIdField: 'clients__id', fkDisplayField: 'clients__name', filterType: {"type":"ClientsCombo","loadAfterRender":!1}, editorType: {"type":"ClientsCombo","loadAfterRender":!1}, fieldGroup: '1. Identifiers', },
			{ name: 'work_orders__work_order_type_id', mapping: 'work_order_type_id', title: 'Type', type: 'int', isFk: true, fkIdField: 'work_order_types__id', fkDisplayField: 'work_order_types__name', filterType: {"type":"WorkOrderTypesCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrderTypesCombo","loadAfterRender":!1}, fieldGroup: '2. Description', },
			{ name: 'work_orders__lot_type_id', mapping: 'lot_type_id', title: 'Lot Type', type: 'int', isFk: true, fkIdField: 'lot_types__id', fkDisplayField: 'lot_types__name', filterType: {"type":"LotTypesCombo","loadAfterRender":!1}, editorType: {"type":"LotTypesCombo","loadAfterRender":!1}, fieldGroup: '2. Description', },
			{ name: 'work_orders__address', mapping: 'address', title: 'Address', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__address_number', mapping: 'address_number', title: 'Address Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__address_direction', mapping: 'address_direction', title: 'Address Direction', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__address_street', mapping: 'address_street', title: 'Address Street', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__work_description', mapping: 'work_description', title: 'Work Desc', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__legal_description', mapping: 'legal_description', title: 'Legal Desc', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__tax_id_number', mapping: 'tax_id_number', title: 'Tax ID Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Description', },
			{ name: 'work_orders__items', mapping: 'items', title: 'Items', isFilteringDisabled: true, viewerType: {"type":"ItemsTag"}, editorType: {"type":"ItemsTagEditor","loadAfterRender":!1}, fieldGroup: '2. Description', },
			{ name: 'work_orders__attributes', mapping: 'attributes', title: 'Attributes', isFilteringDisabled: true, viewerType: {"type":"AttributesTag"}, editorType: {"type":"AttributesTagEditor","loadAfterRender":!1}, fieldGroup: '2. Description', },
			{ name: 'work_orders__crew', mapping: 'crew', title: 'Assigned Crew', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '2. Description', },
			{ name: 'work_orders__has_attachments', mapping: 'has_attachments', title: 'Has Attachments', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '2. Description', },
			{ name: 'work_orders__has_notes', mapping: 'has_notes', title: 'Has Notes', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '2. Description', },
			{ name: 'work_orders__has_images', mapping: 'has_images', title: 'Has Images', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '2. Description', },
			{ name: 'work_orders__display_order', mapping: 'display_order', title: 'Display Order', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '2. Description', },
			{ name: 'work_orders__estimate', mapping: 'estimate', title: 'Estimate', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '3. Amounts', },
			{ name: 'work_orders__total', mapping: 'total', title: 'Total', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. Amounts', },
			{ name: 'work_orders__amount_due', mapping: 'amount_due', title: 'Amount Due', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. Amounts', },
			{ name: 'work_orders__is_bid', mapping: 'is_bid', title: 'Is Bid?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Switches', },
			{ name: 'work_orders__is_billed', mapping: 'is_billed', title: 'Is Billed?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Switches', defaultValue: 1, },
			{ name: 'work_orders__is_bid_approved', mapping: 'is_bid_approved', title: 'Is Bid Approved?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Switches', },
			{ name: 'work_orders__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: '4. Switches', },
			{ name: 'work_orders__date', mapping: 'date', title: 'WO Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '5. Dates', },
			{ name: 'work_orders__assigned_date', mapping: 'assigned_date', title: 'Assigned Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '5. Dates', },
			{ name: 'work_orders__completed_date', mapping: 'completed_date', title: 'Completed Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '5. Dates', },
			{ name: 'work_orders__completed_week', mapping: 'completed_week', title: 'Completed Wk', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. Dates', },
			{ name: 'work_orders__invoiced_date', mapping: 'invoiced_date', title: 'Invoiced Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '5. Dates', },
			{ name: 'work_orders__paid_date', mapping: 'paid_date', title: 'Paid Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '5. Dates', },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__role', mapping: 'user.role', title: 'Role', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__id', mapping: 'client.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__name', mapping: 'client.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__vendor_number', mapping: 'client.vendor_number', title: 'Vendor Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_coversheet', mapping: 'client.show_coversheet', title: 'Show Coversheet?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_invoice', mapping: 'client.show_invoice', title: 'Show Invoice?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_original_pdf', mapping: 'client.show_original_pdf', title: 'Show Original PDF?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__parser_keyword', mapping: 'client.parser_keyword', title: 'Parser Keyword', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_order_statuses__id', mapping: 'work_order_status.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_order_statuses__name', mapping: 'work_order_status.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_order_types__id', mapping: 'work_order_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_order_types__name', mapping: 'work_order_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_order_types__price', mapping: 'work_order_type.price', title: 'Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'lot_types__id', mapping: 'lot_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'lot_types__name', mapping: 'lot_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'CrewsWorkOrders',
				'Notes',
				'Payments',
				'TimeEntries',
				'ItemsWorkOrders'
			],
			belongsTo: [
				'Users',
				'Clients',
				'WorkOrderStatuses',
				'WorkOrderTypes',
				'LotTypes'
			],
			belongsToMany: [
				'Crews',
				'Items',
				'Attributes'
			],

		},

		ancillaryFilters: {
		    "crews": "Crew",
		    "items": "Item",
		    "attributes": "Attribute"
		},

		defaultFilters: [
			// 'work_orders__work_order_status_id',
			// 'work_orders__client_id',
			// 'work_orders__work_order_type_id',
			// 'work_orders__lot_type_id',
			// 'work_orders__has_attachments',
			// 'work_orders__has_notes',
			// 'work_orders__has_images',
			// 'work_orders__display_order',
			// 'work_orders__estimate',
			// 'work_orders__total',
			// 'work_orders__amount_due',
			// 'work_orders__is_bid',
			// 'work_orders__is_billed',
			// 'work_orders__is_bid_approved',
			// 'work_orders__user_id',
			// 'work_orders__date',
			// 'work_orders__assigned_date',
			// 'work_orders__completed_date',
			// 'work_orders__completed_week',
			// 'work_orders__invoiced_date',
			// 'work_orders__paid_date'
		],

	},

	entity: {
		methods: {

			getCrewsWorkOrders: function() {
				const CrewsWorkOrders = this.getAssociatedRepository('CrewsWorkOrders');
				return CrewsWorkOrders.getBy((entity) => {
					return entity.crews_work_orders__work_order_id === this.work_orders__id;
				});
			},

			getNotes: function() {
				const Notes = this.getAssociatedRepository('Notes');
				return Notes.getBy((entity) => {
					return entity.notes__work_order_id === this.work_orders__id;
				});
			},

			getPayments: function() {
				const Payments = this.getAssociatedRepository('Payments');
				return Payments.getBy((entity) => {
					return entity.payments__work_order_id === this.work_orders__id;
				});
			},

			getTimeEntries: function() {
				const TimeEntries = this.getAssociatedRepository('TimeEntries');
				return TimeEntries.getBy((entity) => {
					return entity.time_entries__work_order_id === this.work_orders__id;
				});
			},

			getUser: function() {
				const Users = this.getAssociatedRepository('Users');
				return Users.getById(this.work_orders__user_id);
			},

			getClient: function() {
				const Clients = this.getAssociatedRepository('Clients');
				return Clients.getById(this.work_orders__client_id);
			},

			getWorkOrderStatus: function() {
				const WorkOrderStatuses = this.getAssociatedRepository('WorkOrderStatuses');
				return WorkOrderStatuses.getById(this.work_orders__work_order_status_id);
			},

			getWorkOrderType: function() {
				const WorkOrderTypes = this.getAssociatedRepository('WorkOrderTypes');
				return WorkOrderTypes.getById(this.work_orders__work_order_type_id);
			},

			getLotType: function() {
				const LotTypes = this.getAssociatedRepository('LotTypes');
				return LotTypes.getById(this.work_orders__lot_type_id);
			},

			getItemsWorkOrders: function() {
				const ItemsWorkOrders = this.getAssociatedRepository('ItemsWorkOrders');
				return ItemsWorkOrders.getBy((entity) => {
					return entity.items_work_orders__work_order_id === this.work_orders__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default WorkOrders;
