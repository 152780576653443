/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import DiscountsEditor from '../Editor/DiscountsEditor.js';

export default function DiscountsSideGridEditor(props) {
	return <SideGridEditor
				reference="DiscountsSideGridEditor"
				model="Discounts"
				isCollapsible={false}
				Editor={DiscountsEditor}
				defaultRowHeight={24}
				isResizable={true}
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 400 // MOD
				    },
				    {
				        "header": "Name",
				        "fieldName": "discounts__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Qty",
				        "fieldName": "discounts__qty",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Amount",
				        "fieldName": "discounts__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}