import { useState, useEffect, } from 'react';
import {
	Modal,
} from 'native-base';
import {
	WORK_ORDER_STATUS__RETURN_FOR_BID,
} from '../../constants/WorkOrderStatuses';
import oneHatData from '@onehat/data';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import EnvelopeRegular from '@onehat/ui/src/Components/Icons/EnvelopeRegular';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert';
import Print from '@onehat/ui/src/Components/Icons/Print';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';
import EmailBidEstimateWindow from '../Window/EmailBidEstimateWindow';
import RequireEstimateWindow from '../Window/RequireEstimateWindow';
import AppGlobals from '../../AppGlobals';
import _ from 'lodash';

function ReturnForBidWorkOrdersGrid(props) {
	const {
			alert,
			showInfo,
		} = props,
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false),
		[isRequireEstimateModalShown, setIsRequireEstimateModalShown] = useState(false),
		[isEmailBidEstimateModalShown, setIsEmailBidEstimateModalShown] = useState(false),
		[item, setItem] = useState(false),
		hasEstimate = (item) => {
			if (!item.work_orders__estimate || !item.properties.work_orders__estimate.rawValue) {
				return false;
			}
			return true;
		},
		showEstimateWindow = (item) => {
			setItem(item);
			setIsRequireEstimateModalShown(true);
		},
		closeRequiredEstimateWindow = () => setIsRequireEstimateModalShown(false),
		onSubmitRequiredEstimate = async (values) => {
			item.work_orders__estimate = values.work_orders__estimate;
			await item.save();
			closeRequiredEstimateWindow();
		},
		emailEstimate = (item) => {
			setItem(item);
			if (!hasEstimate(item)) {
				showEstimateWindow(item);
				return;
			}
			
			setIsEmailBidEstimateModalShown(true);
		},
		onSubmitEmailBidEstimate = async (values) => {

			const result = await Repository._send('POST', 'WorkOrders/emailBidEstimate/' + item.work_orders__id, values);

			const {
				root,
				success,
				total,
				message
			} = Repository._processServerResponse(result);

			if (!success) {
				alert(message);
				return;
			}

			showInfo('Email sent successfully.');
			closeEmailBidEstimateWindow();
		},
		closeEmailBidEstimateWindow = () => setIsEmailBidEstimateModalShown(false),
		printBidEstimate = (item) => {
			if (!hasEstimate(item)) {
				showEstimateWindow(item);
				return;
			}
			const
				url = AppGlobals.baseURL + 'WorkOrders/bidEstimate/' + item.id,
				win = window.open(url, '_blank');
			win.focus();
		};

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__RETURN_FOR_BID,
			});
			Repository.setSorters([{ name: 'work_orders__date', direction: 'DESC' }]);
			
			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}

	let assembledComponents = 
			<DashboardWorkOrdersGrid
				Repository={Repository}
				columnsConfig={[
					{
						header: 'Email',
						w: '60px',
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={EnvelopeRegular}
										_icon={{
											size: 'md',
										}}
										py={0}
										onPress={() => emailEstimate(item)}
										tooltip="Send Email"
									/>;
						},
					},
					{
						header: 'Print',
						w: '50px',
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={Print}
										_icon={{
											size: 'md',
										}}
										py={0}
										onPress={() => printBidEstimate(item)}
										tooltip="Print out a bid estimate"
									/>;
						},
					},
					{
						"header": "Work Order #",
						"fieldName": "work_orders__work_order_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Assigned Date",
						"fieldName": "work_orders__assigned_date",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 200 // MOD
					},
					{
						"header": "Is Bid?",
						"fieldName": "work_orders__is_bid",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 100 // MOD
					},
				]}
				{...props}
			/>;

	if (isRequireEstimateModalShown) {
		assembledComponents = 
				<>
					{assembledComponents}
					<Modal
						isOpen={true}
						onClose={closeRequiredEstimateWindow}
					>
						<RequireEstimateWindow
							onSubmit={onSubmitRequiredEstimate}
							onCancel={closeRequiredEstimateWindow}
						/>
					</Modal>
				</>;
	}
	if (isEmailBidEstimateModalShown) {
		assembledComponents = 
				<>
					{assembledComponents}
					<Modal
						isOpen={true}
						onClose={closeEmailBidEstimateWindow}
					>
						<EmailBidEstimateWindow
							onSubmit={onSubmitEmailBidEstimate}
							onCancel={closeEmailBidEstimateWindow}
						/>
					</Modal>
				</>;
	}
	return assembledComponents;
}

export default withAlert(ReturnForBidWorkOrdersGrid);
