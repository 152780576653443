/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PermissionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Custom Permissions",
		                "reference": "custom_permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_reports"
		                    },
		                    {
		                        "name": "permissions__view_dashboard"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Attachments",
		                "reference": "attachments",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attachments"
		                    },
		                    {
		                        "name": "permissions__add_attachments"
		                    },
		                    {
		                        "name": "permissions__edit_attachments"
		                    },
		                    {
		                        "name": "permissions__delete_attachments"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Attributes",
		                "reference": "attributes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attributes"
		                    },
		                    {
		                        "name": "permissions__add_attributes"
		                    },
		                    {
		                        "name": "permissions__edit_attributes"
		                    },
		                    {
		                        "name": "permissions__delete_attributes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Clients",
		                "reference": "clients",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_clients"
		                    },
		                    {
		                        "name": "permissions__add_clients"
		                    },
		                    {
		                        "name": "permissions__edit_clients"
		                    },
		                    {
		                        "name": "permissions__delete_clients"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Crews",
		                "reference": "crews",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_crews"
		                    },
		                    {
		                        "name": "permissions__add_crews"
		                    },
		                    {
		                        "name": "permissions__edit_crews"
		                    },
		                    {
		                        "name": "permissions__delete_crews"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "CrewsWorkOrders",
		                "reference": "crews_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_crews_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_crews_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_crews_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_crews_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Items",
		                "reference": "items",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_items"
		                    },
		                    {
		                        "name": "permissions__add_items"
		                    },
		                    {
		                        "name": "permissions__edit_items"
		                    },
		                    {
		                        "name": "permissions__delete_items"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "LotTypes",
		                "reference": "lot_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_lot_types"
		                    },
		                    {
		                        "name": "permissions__add_lot_types"
		                    },
		                    {
		                        "name": "permissions__edit_lot_types"
		                    },
		                    {
		                        "name": "permissions__delete_lot_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Notes",
		                "reference": "notes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_notes"
		                    },
		                    {
		                        "name": "permissions__add_notes"
		                    },
		                    {
		                        "name": "permissions__edit_notes"
		                    },
		                    {
		                        "name": "permissions__delete_notes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Payments",
		                "reference": "payments",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_payments"
		                    },
		                    {
		                        "name": "permissions__add_payments"
		                    },
		                    {
		                        "name": "permissions__edit_payments"
		                    },
		                    {
		                        "name": "permissions__delete_payments"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "TimeEntries",
		                "reference": "time_entries",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_time_entries"
		                    },
		                    {
		                        "name": "permissions__add_time_entries"
		                    },
		                    {
		                        "name": "permissions__edit_time_entries"
		                    },
		                    {
		                        "name": "permissions__delete_time_entries"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Users",
		                "reference": "users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_users"
		                    },
		                    {
		                        "name": "permissions__add_users"
		                    },
		                    {
		                        "name": "permissions__edit_users"
		                    },
		                    {
		                        "name": "permissions__delete_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WorkOrderStatuses",
		                "reference": "work_order_statuses",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_order_statuses"
		                    },
		                    {
		                        "name": "permissions__add_work_order_statuses"
		                    },
		                    {
		                        "name": "permissions__edit_work_order_statuses"
		                    },
		                    {
		                        "name": "permissions__delete_work_order_statuses"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WorkOrderTypes",
		                "reference": "work_order_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_order_types"
		                    },
		                    {
		                        "name": "permissions__add_work_order_types"
		                    },
		                    {
		                        "name": "permissions__edit_work_order_types"
		                    },
		                    {
		                        "name": "permissions__delete_work_order_types"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "WorkOrders",
		                "reference": "work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "AttributesWorkOrders",
		                "reference": "attributes_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attributes_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_attributes_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_attributes_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_attributes_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ItemsWorkOrders",
		                "reference": "items_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_items_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_items_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_items_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_items_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Discounts",
		                "reference": "discounts",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_discounts"
		                    },
		                    {
		                        "name": "permissions__add_discounts"
		                    },
		                    {
		                        "name": "permissions__edit_discounts"
		                    },
		                    {
		                        "name": "permissions__delete_discounts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WorkOrderDuplicates",
		                "reference": "work_order_duplicates",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_order_duplicates"
		                    },
		                    {
		                        "name": "permissions__add_work_order_duplicates"
		                    },
		                    {
		                        "name": "permissions__edit_work_order_duplicates"
		                    },
		                    {
		                        "name": "permissions__delete_work_order_duplicates"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups",
		                "reference": "groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups"
		                    },
		                    {
		                        "name": "permissions__add_groups"
		                    },
		                    {
		                        "name": "permissions__edit_groups"
		                    },
		                    {
		                        "name": "permissions__delete_groups"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "GroupsUsers",
		                "reference": "groups_users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups_users"
		                    },
		                    {
		                        "name": "permissions__add_groups_users"
		                    },
		                    {
		                        "name": "permissions__edit_groups_users"
		                    },
		                    {
		                        "name": "permissions__delete_groups_users"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Permissions",
		                "reference": "permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_permissions"
		                    },
		                    {
		                        "name": "permissions__add_permissions"
		                    },
		                    {
		                        "name": "permissions__edit_permissions"
		                    },
		                    {
		                        "name": "permissions__delete_permissions"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PermissionsEditor"
				title="Permissions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

