/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ItemsWorkOrdersEditorWindowSideA from '../Window/ItemsWorkOrdersEditorWindowSideA.js';

export default function ItemsWorkOrdersGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="ItemsWorkOrdersGridEditorSideA"
				model="ItemsWorkOrders"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ItemsWorkOrdersEditorWindowSideA}
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "flex": 1,
				        "minWidth": 150
				    },
				    {
				        "header": "Quantity",
				        "fieldName": "items_work_orders__quantity",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Adjustment",
				        "fieldName": "items_work_orders__adjustment",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}