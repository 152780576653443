/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WorkOrderStatusesSideGridEditor from './WorkOrderStatusesSideGridEditor.js';

export default function WorkOrderStatusesFilteredSideGridEditor(props) {
	return <WorkOrderStatusesSideGridEditor
				reference="WorkOrderStatusesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}