import { useState, useEffect, } from 'react';
import {
	WORK_ORDER_STATUS__COMPLETED,
	WORK_ORDER_STATUS__OUSTANDING,
	WORK_ORDER_STATUS__DBO,
} from '../../constants/WorkOrderStatuses';
import {
	setIsWaitModalShown,
} from '../../models/Slices/DebugSlice';
import { useDispatch } from 'react-redux';
import oneHatData from '@onehat/data';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import EnvelopeRegular from '@onehat/ui/src/Components/Icons/EnvelopeRegular';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';

function RecentlyCompletedWorkOrdersGrid(props) {
	const {
			alert,
		} = props,
		dispatch = useDispatch(),
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false),
		markAsSent = async (item) => {
			dispatch(setIsWaitModalShown(true));

			item.work_orders__work_order_status_id = WORK_ORDER_STATUS__OUSTANDING;
			await item.save();
			Repository.reload();

			dispatch(setIsWaitModalShown(false));

			alert('Marked as sent'); // TODO: handle errors
		};

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id IN]': [
					WORK_ORDER_STATUS__COMPLETED,
					WORK_ORDER_STATUS__DBO,
				],
			});
			Repository.setSorters([{ name: 'work_orders__completed_date', direction: 'DESC' }]);

			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}
	

	return <DashboardWorkOrdersGrid
				Repository={Repository}
				columnsConfig={[
					{
						header: 'Mark Sent',
						w: '80px',
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={EnvelopeRegular}
										_icon={{
											size: 'md',
										}}
										py={0}
										onPress={() => markAsSent(item)}
										tooltip="Mark as Sent"
									/>;
						},
					},
					{
						"header": "Work Order #",
						"fieldName": "work_orders__work_order_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Completed Date",
						"fieldName": "work_orders__completed_date",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 200 // MOD
					},
					{
						"header": "Is Bid?",
						"fieldName": "work_orders__is_bid",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 100 // MOD
					},
					{
						"header": "Type",
						"fieldName": "work_order_types__name",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150 // MOD
					},
				]}
				{...props}
			/>;
}
export default withAlert(RecentlyCompletedWorkOrdersGrid);