/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import DiscountsEditorWindow from '../../Window/DiscountsEditorWindow.js';

export default function DiscountsGridEditorPanel(props) {
	return <GridPanel
				reference="DiscountsGridEditorPanel"
				model="Discounts"
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={DiscountsEditorWindow}
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Name",
				        "fieldName": "discounts__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Qty",
				        "fieldName": "discounts__qty",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Amount",
				        "fieldName": "discounts__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}