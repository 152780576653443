/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function PaymentsFilteredInlineGridEditorPanel(props) {
	return <GridPanel
				reference="PaymentsFilteredInlineGridEditorPanel"
				model="Payments"
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={[
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Amount",
				        "fieldName": "payments__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Payment Date",
				        "fieldName": "payments__payment_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Comments",
				        "fieldName": "payments__comments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    },
				    {
				        "header": "Display Name",
				        "fieldName": "payments__display_name",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}