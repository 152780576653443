/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ClientsEditorWindow from '../../../Window/ClientsEditorWindow.js';

function ClientsComboEditor(props) {
	return <ComboEditor
				reference="ClientsComboEditor"
				model="Clients"
				uniqueRepository={true}
				Editor={ClientsEditorWindow}
				{...props}
			/>;
}

export default ClientsComboEditor;