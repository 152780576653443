import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Attributes = {
	
	name: 'Attributes',
	model: {

		idProperty: 'attributes__id',
		displayProperty: 'attributes__name',
		sortProperty: 'attributes__name',
		
		sorters: null,

		validator: yup.object({
			attributes__name: yup.string().max(100).required(),
			attributes__price: yup.number().nullable()
		}),
		
		properties: [
			{ name: 'attributes__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'attributes__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'attributes__price', mapping: 'price', title: 'Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'AttributesWorkOrders'
			],
			belongsToMany: [
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'attributes__price'
		],

	},

	entity: {
		methods: {

			getAttributesWorkOrders: function() {
				const AttributesWorkOrders = this.getAssociatedRepository('AttributesWorkOrders');
				return AttributesWorkOrders.getBy((entity) => {
					return entity.attributes_work_orders__attribute_id === this.attributes__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
	    "isPaginated": false
	},

};

export default Attributes;
