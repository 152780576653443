import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Discounts = {
	
	name: 'Discounts',
	model: {

		idProperty: 'discounts__id',
		displayProperty: 'discounts__name',
		sortProperty: 'discounts__name',
		
		sorters: null,

		validator: yup.object({
			discounts__item_id: yup.number().integer().required(),
			discounts__name: yup.string().max(255).nullable(),
			discounts__qty: yup.string().max(10).nullable(),
			discounts__amount: yup.number().nullable()
		}),
		
		properties: [
			{ name: 'discounts__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'discounts__item_id', mapping: 'item_id', title: 'Item', type: 'int', isFk: true, fkIdField: 'items__id', fkDisplayField: 'items__label', filterType: {"type":"ItemsCombo","loadAfterRender":!1}, editorType: {"type":"ItemsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'discounts__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'discounts__qty', mapping: 'qty', title: 'Qty', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'discounts__amount', mapping: 'amount', title: 'Amount', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'items__id', mapping: 'item.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__label', mapping: 'item.label', title: 'Label', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__description', mapping: 'item.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__price', mapping: 'item.price', title: 'Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__client_id', mapping: 'item.client_id', title: 'Client', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Items'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'discounts__item_id',
			// 'discounts__amount'
		],

	},

	entity: {
		methods: {

			getItem: function() {
				const Items = this.getAssociatedRepository('Items');
				return Items.getById(this.discounts__item_id);
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Discounts;
