const WorkOrders = {
	
	name: 'WorkOrderDuplicates',
	model: {

		idProperty: 'work_order_duplicates__id',
		displayProperty: 'work_order_duplicates__idr',
		sortProperty: 'work_order_duplicates__id',
		
		sorters: null,
		
		properties: [
			{ name: 'work_order_duplicates__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. Identifiers', },
			{ name: 'work_order_duplicates__work_order_id', mapping: 'work_order_id', title: 'Work Order #', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. Identifiers', },
			{ name: 'work_order_duplicates__duplicate_id', mapping: 'duplicate_id', title: 'Duplicate #', isFilteringDisabled: true, fieldGroup: '1. Identifiers', },
		],

		associations: {
			belongsTo: [
				'WorkOrders',
			],

		},

		ancillaryFilters: {
		},

		defaultFilters: [
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default WorkOrders;
