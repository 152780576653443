/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import AttributesEditorWindow from '../../../Window/AttributesEditorWindow.js';

function AttributesTagEditor(props) {
	return <TagEditor
				reference="AttributesTagEditor"
				model="Attributes"
				uniqueRepository={true}
				Editor={AttributesEditorWindow}
				{...props}
			/>;
}

export default AttributesTagEditor;