import { useState, useEffect, } from 'react';
import {
	Modal,
} from 'native-base';
import {
	WORK_ORDER_STATUS__DUPLICATE,
} from '../../constants/WorkOrderStatuses';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import oneHatData from '@onehat/data';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import ClipboardCheck from '@onehat/ui/src/Components/Icons/ClipboardCheck';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';
import BidValidationWindow from '../Window/BidValidationWindow';
import AppGlobals from '../../AppGlobals';

function OCRValidationGrid(props) {
	const {
			alert,
			showInfo,
		} = props,
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false),
		[isViewerShown, setIsViewerShown] = useState(false),
		[iframe1Src, setIframe1Src] = useState(null),
		[iframe2Src, setIframe2Src] = useState(null),
		[item, setItem] = useState(null),
		onValidate = async (item) => {
			const
				params = {
					duplicate_id: item.id
				},
				result = await Repository._send('POST', 'WorkOrders/getDuplicateInfo', params);

			const {
				root,
				success,
				total,
				message
			} = Repository._processServerResponse(result);

			if (!success) {
				return;
			}

			// Get the images
			if (!root.existing) {
				alert('This work order has no images at this time!');
				return;
			}
			
			setIframe1Src(AppGlobals.baseURL + root.existing);
			setIframe2Src(AppGlobals.baseURL + root.duplicate);
			setItem(item);
			setIsViewerShown(true);
		},
		onMarkedNew = async () => {
			const
				params = {
					id: item.id
				},
				result = await Repository._send('POST', 'WorkOrders/markAsNew', params);

			const {
				root,
				success,
				total,
				message
			} = Repository._processServerResponse(result);

			if (!success) {
				return;
			}

			showInfo('Marked as new (unassigned).');
			Repository.reload();
			closeViewer();
		},
		onMarkedDuplicate = async () => {
			const
				params = {
					id: item.id
				},
				result = await Repository._send('POST', 'WorkOrders/markAsDuplicate', params);

			const {
				root,
				success,
				total,
				message
			} = Repository._processServerResponse(result);

			if (!success) {
				return;
			}

			showInfo('Marked as duplicate.');
			Repository.reload();
			closeViewer();
		},
		onMarkedApproved = async () => {
			const
				params = {
					id: item.id
				},
				result = await Repository._send('POST', 'WorkOrders/markAsBidApproved', params);

			const {
				root,
				success,
				total,
				message
			} = Repository._processServerResponse(result);

			if (!success) {
				return;
			}

			showInfo('Marked as bid approved.');
			Repository.reload();
			closeViewer();
		},
		closeViewer = () => setIsViewerShown(false);

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__DUPLICATE,
			});
			Repository.setSorters([{ name: 'work_orders__date', direction: 'DESC' }]);

			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}

	let assembledComponents = 
		<DashboardWorkOrdersGrid
			Repository={Repository}
			columnsConfig={[
				{
					header: 'Validate',
					w: '75px',
					sortable: false,
					isEditable: false,
					reorderable: false,
					resizable: false,
					renderer: (item) => {
						return <IconButton
									icon={ClipboardCheck}
									_icon={{
										size: 'md',
									}}
									py={0}
									onPress={() => onValidate(item)}
									tooltip="Validate"
								/>;
					},
				},
				{
					"header": "Work Order #",
					"fieldName": "work_orders__work_order_number",
					"sortable": true,
					"isEditable": true,
					"reorderable": true,
					"resizable": true,
					"w": 150
				},
				{
					"header": "Date",
					"fieldName": "work_orders__date",
					"sortable": true,
					"isEditable": true,
					"reorderable": true,
					"resizable": true,
					"w": 200 // MOD
				},
				{
					"header": "Type",
					"fieldName": "work_order_types__name",
					"sortable": true,
					"isEditable": true,
					"reorderable": true,
					"resizable": true,
					"w": 150 // MOD
				},
			]}
			{...props}
		/>;

	if (isViewerShown) {
		assembledComponents = 
				<>
					{assembledComponents}
					<Modal
						isOpen={true}
						onClose={closeViewer}
					>
						<BidValidationWindow
							iframe1Src={iframe1Src}
							iframe2Src={iframe2Src}
							onMarkedNew={onMarkedNew}
							onMarkedDuplicate={onMarkedDuplicate}
							onMarkedApproved={onMarkedApproved}
							onClose={closeViewer}

							Repository={Repository}
						/>
					</Modal>
				</>;
	}
	return assembledComponents;
}

export default withAlert(OCRValidationGrid);