import * as Linking from 'expo-linking';
import Inflector from 'inflector-js';
import AppGlobals from '../AppGlobals';

// NOTE: This section allows local development to not use a subdirectory
// while deployed apps use the /admin/ subdirectory
const
	rootDir = 'admin',
	usesPrefix = !!location.hostname.match(/\.jimax\.work/),
	urlPrefix = usesPrefix ? rootDir + '/' : '',
	prefix = Linking.createURL('/' + urlPrefix),
	// END subdirectory code

	linking = {
		prefixes: [
			prefix,
		],
		config: { // to match screens with paths
			// https://reactnavigation.org/docs/configuring-links/
			screens: { // Authenticator
				Login: urlPrefix + Inflector.dasherize(Inflector.underscore('Login')),
				AppNavigator: {
					path: '',
					exact: true,
					initialRouteName: urlPrefix + Inflector.dasherize(Inflector.underscore('Home')),
					screens: {
						Home: urlPrefix + Inflector.dasherize(Inflector.underscore('Home')),
						Dashboard: urlPrefix + Inflector.dasherize(Inflector.underscore('Dashboard')),
						WorkOrdersManager: urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders')),
						PaymentsManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Payments')),
						DiscountsManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Discounts')),
						DropdownsManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Dropdowns')),
						ReportsManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Reports')),
						UsersManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Users')),
						GroupsManager: urlPrefix + Inflector.dasherize(Inflector.underscore('Groups')),
						Util: urlPrefix + Inflector.dasherize(Inflector.underscore('Util')),
						Logout: urlPrefix + Inflector.dasherize(Inflector.underscore('Logout')),
					},
				},
				NotFound: '*',
			},
		},
		// getStateFromPath: (path, options) => {
		// 	// Return a state object here
		// 	// You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
		// },
		// getPathFromState(state, config) {
		// 	// Return a path string here
		// 	// You can also reuse the default logic by importing `getPathFromState` from `@react-navigation/native`
		// },
	};

AppGlobals.urlPrefix = urlPrefix;

export default linking;