/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import PermissionsEditorWindow from '../../../Window/PermissionsEditorWindow.js';

function PermissionsTagEditor(props) {
	return <TagEditor
				reference="PermissionsTagEditor"
				model="Permissions"
				uniqueRepository={true}
				Editor={PermissionsEditorWindow}
				{...props}
			/>;
}

export default PermissionsTagEditor;