/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import {
	Column,
	Row,
} from 'native-base';
import Button from '@onehat/ui/src/Components/Buttons/Button.js';
import UiGlobals from '@onehat/ui/src/UiGlobals.js';
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import useAdjustedWindowSize from '@onehat/ui/src/Hooks/useAdjustedWindowSize.js';

export default function BidValidationWindow(props) {
	const {
			iframe1Src,
			iframe2Src,
			onMarkedNew,
			onMarkedDuplicate,
			onMarkedApproved,
			onClose,
		} = props,
		styles = UiGlobals.styles,
		// [width, height] = useAdjustedWindowSize(2000, 2000), // make it full size
		buttonProps = {
			mr: 2,
		};
	return <Panel
				title="Bid Validation"
				reference="BidValidationWindow"
				isCollapsible={false}
				bg="#fff"
				w="90vw"
				h="80vh"
				flex={null}
			>
				<Row flex={1} w="100%" p={2}>
					<Column
						flex={1}
						h="100%"
						mr={2}
						borderWidth={1}
						borderColor="trueGray.400"
					>
						<iframe 
							src={iframe1Src}
							width="100%" 
							height="100%" 
							style={{ border: 'none' }}
						/>
					</Column>
					<Column
						flex={1}
						h="100%"
						borderWidth={1}
						borderColor="trueGray.400"
					>
						<iframe 
							src={iframe2Src}
							width="100%" 
							height="100%" 
							style={{ border: 'none' }}
						/>
					</Column>
				</Row>
				<Row p={2}>
					<Row flex={1} justifyContent="flex-start">
						<Button
							onPress={onMarkedNew}
							tooltip="This is a new work order"
							{...buttonProps}
						>New</Button>
						<Button
							onPress={onMarkedDuplicate}
							tooltip="This is a duplicate work order"
							{...buttonProps}
						>Duplicate</Button>
						<Button
							onPress={onMarkedApproved}
							tooltip="This is a response to a bid request, and the bid has been approved"
							{...buttonProps}
						>Bid Approved</Button>
					</Row>
					
					<Button
						key="closeBtn"
						variant="ghost"
						onPress={onClose}
						color="#fff"
					>Close</Button>
				</Row>
				
			</Panel>;
}

