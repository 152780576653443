import { useState, useEffect, } from 'react';
import {
	Column,
	Row,
} from 'native-base';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import chunkArray from '../functions/chunkArray';
import Panel from '@onehat/ui/src/Components/Panel/Panel';
import ItemsFilteredGridEditor from '../components/Grid/ItemsFilteredGridEditor';
import AttributesFilteredInlineGridEditor from '../components/Grid/AttributesFilteredInlineGridEditor';
import WorkOrderTypesFilteredInlineGridEditor from '../components/Grid/WorkOrderTypesFilteredInlineGridEditor';
import WorkOrderStatusesFilteredInlineGridEditor from '../components/Grid/WorkOrderStatusesFilteredInlineGridEditor';
import LotTypesFilteredInlineGridEditor from '../components/Grid/LotTypesFilteredInlineGridEditor';
import CrewsFilteredInlineGridEditor from '../components/Grid/CrewsFilteredInlineGridEditor';
import ClientsFilteredGridEditor from '../components/Grid/ClientsFilteredGridEditor';

export default function DropdownsManager(props) {
	const
		[containerWidth, setContainerWidth] = useState(500),
		[isRendered, setIsRendered] = useState(false),
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'DropdownsManager',
		onLayout = (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	useEffect(() => {
		if (!isActive) {
			setIsRendered(false);
		}
	}, [isActive]);

	if (!isActive) {
		return null;
	}

	let
		rows = [],
		items;
	if (isRendered && isActive) {
		items = [
			<Panel
				key="ItemsFilteredGridEditor"
				title="Items"
				flex={2}
				isCollapsible={false}
			>
				<ItemsFilteredGridEditor
					reference="ItemsFilteredGridEditor"
				/>
			</Panel>,
			<Panel
				key="AttributesFilteredInlineGridEditor"
				title="Attributes"
				flex={1}
				isCollapsible={false}
			>
				<AttributesFilteredInlineGridEditor
					reference="AttributesFilteredInlineGridEditor"
				/>
			</Panel>,
			<Panel
				key="WorkOrderTypesFilteredInlineGridEditor"
				title="Work Order Types"
				flex={1}
				isCollapsible={false}
			>
				<WorkOrderTypesFilteredInlineGridEditor
					reference="WorkOrderTypesFilteredInlineGridEditor"
				/>
			</Panel>,
			<Panel
				key="WorkOrderStatusesFilteredInlineGridEditor"
				title="Work Order Statuses"
				flex={1}
				isCollapsible={false}
			>
				<WorkOrderStatusesFilteredInlineGridEditor
					reference="WorkOrderStatusesFilteredInlineGridEditor"
				/>
			</Panel>,
			<Panel
				key="LotTypesFilteredInlineGridEditor"
				title="Lot Types"
				flex={1}
				isCollapsible={false}
			>
				<LotTypesFilteredInlineGridEditor
					reference="LotTypesFilteredInlineGridEditor"
				/>
			</Panel>,
			<Panel
				key="CrewsFilteredInlineGridEditor"
				title="Crews"
				flex={2}
				isCollapsible={false}
			>
				<CrewsFilteredInlineGridEditor
					reference="CrewsFilteredInlineGridEditor"
				/>
			</Panel>,
			<Panel
				key="ClientsFilteredGridEditor"
				title="Clients"
				flex={3}
				isCollapsible={false}
			>
				<ClientsFilteredGridEditor
					reference="ClientsFilteredGridEditor"
				/>
			</Panel>,
		];

		let itemsPerRow = 1;
		if (containerWidth > 400) {
			itemsPerRow = 2;
		}
		if (containerWidth > 600) {
			itemsPerRow = 3;
		}
		if (containerWidth > 800) {
			itemsPerRow = 4;
		}

		const chunks = chunkArray(items, itemsPerRow);
		_.each(chunks, (items, ix) => {
			rows.push(<Row key={'row' + ix} flex={1} justifyContent="space-between">{items}</Row>);
		});
	}

	return <Column flex={1} w="100%" onLayout={onLayout} >
				{isRendered && rows}
			</Column>;
}
