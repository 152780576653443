/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';

export default function AttributesFilteredGridPanel(props) {
	return <GridPanel
				reference="AttributesFilteredGridPanel"
				model="Attributes"
				useFilters={true}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "attributes__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Price",
				        "fieldName": "attributes__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}