/*
 * This file is categorized as 'Custom Source Code'
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

import './src/functions/ignoreWanings';
import { useEffect, useState, useRef, } from 'react';
import {
	Platform,
} from 'react-native';
import {
	NativeBaseProvider,
	extendTheme,
} from 'native-base';
// import { GluestackUIProvider, config } from '@gluestack-ui/themed';
// import gluestackConfig from './src/styles/gluestack-ui.config.js';
import axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import LocalStorage from '@onehat/data/src/Integration/Browser/Repository/LocalStorage';
import SessionStorage from '@onehat/data/src/Integration/Browser/Repository/SessionStorage';
import SecureLocalStorage from '@onehat/data/src/Integration/Browser/Repository/SecureLocalStorage';
import oneHatData from '@onehat/data';
import oneHatUi from '@onehat/ui'; // Must import this, as it initializes the library!
import { setGlobals } from '@onehat/ui/src/UiGlobals.js';
import registerWebComponents from '@onehat/ui/src/Functions/registerWebComponents.js';
import setUiSavesRepo from '@onehat/ui/src/Functions/setUiSavesRepo.js';
import deleteSaved from '@onehat/ui/src/Functions/deleteSaved.js';
import setThemeOverrides from '@onehat/ui/src/Functions/setThemeOverrides.js';
import getSecure from '@onehat/ui/src/Functions/getSecure';
import deleteSecure from '@onehat/ui/src/Functions/deleteSecure';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Provider as ReduxProvider, useDispatch, } from 'react-redux';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import Store from './src/models/Store.js'; // Must import this, as it initializes the Redux store!
import allSchemas from './src/models/Schemas/AllSchemas.js';
import {
	setCurrentScreen,
	setUserThunk,
} from './src/models/Slices/AppSlice.js';
import {
	setAlertMessage,
} from './src/models/Slices/DebugSlice';

import registerComponents from './src/functions/registerComponents.js';
import registerStyles from './src/functions/registerStyles.js';
import AppGlobals from './src/AppGlobals.js';
import linking from './src/navigators/Linking.js';
import ThemeOverrides from './src/styles/ThemeOverrides.js';
import { NavigationContainer } from '@react-navigation/native';
import { navigationRef } from './src/RootNavigation';
import Authenticator from './src/navigators/Authenticator';
import {
	useFonts,
	OpenSansCondensed_700Bold,
} from '@expo-google-fonts/open-sans-condensed';
import _ from 'lodash';
import '@onehat/ui/src/Styles/Global.css';
// import './src/styles/globalStyles.css'

setGlobals(AppGlobals);

export default function App() {
	const
		[fontsLoaded] = useFonts({
			OpenSansCondensed_700Bold,
		}),
		[isReady, setIsReady] = useState(false),
		routeNameRef = useRef(),
		Store = AppGlobals.redux,
		dispatch = Store.dispatch,
		onNavReady = () => {
			routeNameRef.current = navigationRef.current && navigationRef.current.getCurrentRoute && navigationRef.current.getCurrentRoute()?.name;
		},
		onNavStateChange = () => {
			const
				previous = routeNameRef.current,
				current = navigationRef.current && navigationRef.current.getCurrentRoute()?.name;

			if (previous !== current) {
				dispatch(setCurrentScreen(current));
			}

			routeNameRef.current = current;
		},
		setupOneHatData = async () => {
			if (oneHatData.hasSchemaWithName('Users')) {
				return;
			}

			axios.defaults.withCredentials = false; // Don't use cookies in Ajax requests. We'll supply a header token instead.

			const ohd = oneHatData
							.setRepositoryGlobals({
								debugMode: AppGlobals.debugMode,
								api: {
									baseURL: AppGlobals.baseURL,
								},
								timeout: 15000,
								pageSize: 20,
								useLongTimers: false,
								retryRate: '+30 seconds',
								headers: {
									// 'User-Timezone': timeZone,//Intl.DateTimeFormat().resolvedOptions().timeZone,
								},
								passphrase: 'Sh3Mg-f8GpL(k`vH90]8E!mXM', // for SecureLocalStorage
							})
							.registerRepositoryTypes([
								LocalStorage,
								SessionStorage,
								SecureLocalStorage,
							]);
			ohd.createSchemas(_.map(allSchemas, Schema => Schema));
			await ohd.createBoundRepositories();


			// Assign error handlers
			// oneHatData.setGlobalErrorHandler((error) => { // For when repositories actually throw an exception
			// 	let alertMessage = error;
			// 	if (!_.isString(error)) {
			// 		alertMessage = error?.message;
			// 	}
			// 	if (!AppGlobals.debugMode && !AppGlobals.showProgrammerErrorMessages && alertMessage !== 'Network Error') {
			// 		// alertMessage = 'An error occurred'; // If not in debugMode and not a network error, show generic error message
			// 	}
			// 	dispatch(setAlertMessage(alertMessage));
			// });
			_.each(oneHatData.repositories, (repository) => {
				repository.on('error', (alertMessage) => { // For when repositories emit an 'error' event
					dispatch(setAlertMessage(alertMessage)); // just pass along actual error message from server
				})
			});
			
			// Clear old data
			const
				localRepositories = oneHatData.getRepositoriesByType('local'),
				sessionRepositories = oneHatData.getRepositoriesByType('session'),
				secureRepositories = oneHatData.getRepositoriesByType('secure');
			let i, repository;
			if (AppGlobals.clearLocalData || AppGlobals.today < AppGlobals.clearLocalDataBefore) {
				for (i = 0; i < localRepositories.length; i++) {
					repository = localRepositories[i];
					await repository.clearAll();
				}
			}
			if (AppGlobals.clearSessionData) {
				for (i = 0; i < sessionRepositories.length; i++) {
					repository = sessionRepositories[i];
					await repository.clearAll();
				}
			}
			// if (AppGlobals.clearSecureData) {
			// 	for (i = 0; i < secureRepositories.length; i++) {
			// 		repository = secureRepositories[i];
			// 		await repository.clearAll();
			// 	}
			// }
		};

	useEffect(() => {
		(async () => {

			await setupOneHatData();

			// await deleteSecure('user');

			// const isOnline = await checkInternetConnection(networkProps.pingServerUrl);
			// oneHatData.setIsOnline(isOnline);
			
			// Set user
			const user = await getSecure('user');
			if (user) {
				await dispatch(setUserThunk(user));
			}
			
			registerComponents();
			registerWebComponents();
			registerStyles();
			setUiSavesRepo('Local'); // data repository where UI preferences will be saved
			setThemeOverrides(ThemeOverrides);
			
			setIsReady(true);

		})();

		return () => {
			oneHatData.destroy();
		};

	}, []);

	if (!isReady) {
		return null;
	}

	return <ReduxProvider store={Store}>
				<ActionSheetProvider>
					<DndProvider backend={HTML5Backend}>
						{/* <GluestackUIProvider config={gluestackConfig}> */}
							<NativeBaseProvider config={{ strictMode: 'off', }} theme={extendTheme(ThemeOverrides)}>
								<NavigationContainer ref={navigationRef} linking={linking} onReady={onNavReady} onStateChange={onNavStateChange}>
									<Authenticator />
								</NavigationContainer>
							</NativeBaseProvider>
						{/* </GluestackUIProvider> */}
					</DndProvider>
				</ActionSheetProvider>
			</ReduxProvider>;

}
