import { useState, useEffect, } from 'react';
import {
	WORK_ORDER_STATUS__RETURN_TO_INSPECTOR,
} from '../../constants/WorkOrderStatuses';
import oneHatData from '@onehat/data';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';

export default function ReturnToInspectorWorkOrdersGrid(props) {
	const
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false);

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__RETURN_TO_INSPECTOR,
			});
			Repository.setSorters([{ name: 'work_orders__completed_date', direction: 'ASC' }]);

			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}

	return <DashboardWorkOrdersGrid
				Repository={Repository}
				columnsConfig={[
					{
						"header": "Work Order #",
						"fieldName": "work_orders__work_order_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Assigned Date",
						"fieldName": "work_orders__assigned_date",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 200 // MOD
					},
					{
						"header": "Is Bid?",
						"fieldName": "work_orders__is_bid",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 100 // MOD
					},
					{
						"header": "Type",
						"fieldName": "work_order_types__name",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150 // MOD
					},
					{...props}
				]}
			/>;
}
