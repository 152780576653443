import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import WorkOrdersFilteredInlineGridEditor from '../components/Grid/WorkOrdersFilteredInlineGridEditor.js';
import WorkOrdersFilteredSideGridEditor from '../components/Grid/WorkOrdersFilteredSideGridEditor.js';

export default function WorkOrdersManager(props) {
	return <ManagerScreen
				title="Work Orders"
				reference="WorkOrdersManager"
				fullModeComponent={<WorkOrdersFilteredInlineGridEditor
										reference="workOrdersFilteredGridEditor"
										useUploadDownload={true}
										uniqueRepository={true}
									/>}
				sideModeComponent={<WorkOrdersFilteredSideGridEditor
										reference="workOrdersFilteredSideGridEditor"
										useUploadDownload={true}
									/>}
			/>;

}
