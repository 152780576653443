/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import WorkOrderTypesEditorWindow from '../../Window/WorkOrderTypesEditorWindow.js';

export default function WorkOrderTypesGridEditorPanel(props) {
	return <GridPanel
				reference="WorkOrderTypesGridEditorPanel"
				model="WorkOrderTypes"
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={WorkOrderTypesEditorWindow}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "work_order_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Price",
				        "fieldName": "work_order_types__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}