// This file needs to exist, even if it's blank.
// Override the default theme by creating constants below.

// export const PANEL_HEADER_BG = {
// 	linearGradient:{
// 		colors: ['#E31E24', '#B62322', '#962B24'],
// 		start: [0, 0],
// 		end: [1, 0],
// 	},
// };

// To get linear gradients working, check out these links:
// https://i.stack.imgur.com/O4cdE.png
// https://docs.nativebase.io/box (three different sections = Expo, RN, and web)


const
	DEFAULT_FONTSIZE = 11; // 15

export default {

	TAB_ACTIVE_BG: '#fff',
	TAB_ACTIVE_HOVER_BG: 'trueGray.400',
	TAB_ACTIVE_COLOR: '#000',
	TAB_ICON_COLOR: '#000',
	TAB_ACTIVE_ICON_COLOR: '#000',
	TAB_BAR_BG: 'trueGray.300',
	TAB_BG: 'trueGray.300',
	TAB_HOVER_BG: 'trueGray.400',
	TAB_COLOR: '#000',

	MANAGER_SCREEN_TITLE: 'OpenSansCondensed_700Bold',



	FILTER_LABEL_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_ANCILLARY_TITLE_FONTSIZE: 20, // 22
	FORM_ANCILLARY_DESCRIPTION_FONTSIZE: 14, // 16
	FORM_COLOR_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_COMBO_HEIGHT: '30px', // '40px'
	FORM_COMBO_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_COMBO_MENU_HEIGHT: 250,
	FORM_DATE_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FIELDSET_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FILE_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_FILE_READOUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_INPUT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_LABEL_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_NUMBER_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TAG_MB: 0, // 1
	FORM_TAG_MIN_HEIGHT: 5, // 10
	FORM_TAG_PADDING: 0, // 1
	FORM_TAG_VALUEBOX_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TAG_VALUEBOX_ICON_SIZE: 'xs', // 'sm'
	FORM_TEXT_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TEXTAREA_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TEXTAREA_HEIGHT: 130,
	FORM_TOGGLE_FONTSIZE: DEFAULT_FONTSIZE,
	FORM_TOGGLE_SIZE: 'sm', // 'md'
	GRID_CELL_FONTSIZE: DEFAULT_FONTSIZE,
	GRID_HEADER_FONTSIZE: DEFAULT_FONTSIZE,
	GRID_HEADER_CELL_PY: 1, // 3
	GRID_HEADER_ICON_MT: 2, // 3
	GRID_HEADER_ICON_MR: 1, // 2
	GRID_HEADER_ICON_SIZE: 'xs', // 'sm'
	GRID_CELL_PY: 1, // 3
	ICON_BUTTON_PY: 1, // 2
	PANEL_FOOTER_BG: '#e0e0e0', // :alpha.50
	PANEL_HEADER_BG: '#e0e0e0',
	PANEL_HEADER_BG_VERTICAL: '#e0e0e0',
	PANEL_HEADER_ICON_SIZE: 11, // 13
	PANEL_HEADER_ICON_COLOR: '#000',
	PANEL_HEADER_TEXT_FONTSIZE: 13, // 15
	PANEL_HEADER_TEXT_COLOR: '#000',
	TAB_FONTSIZE: DEFAULT_FONTSIZE,
	TEXT_FONTSIZE: DEFAULT_FONTSIZE,
	TREE_NODE_FONTSIZE: DEFAULT_FONTSIZE,
	TREE_NODE_PY: 1, // 3
	TOOLBAR_ITEMS_ICON_SIZE: 'xs', // 'sm
	TOOLBAR_PY: 1, // 2
	VIEWER_ANCILLARY_FONTSIZE: 18, // 22
};