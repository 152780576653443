/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import NotesEditor from '../Editor/NotesEditor.js';

export default function NotesSideGridEditor(props) {
	return <SideGridEditor
				reference="NotesSideGridEditor"
				model="Notes"
				isCollapsible={false}
				Editor={NotesEditor}
				columnsConfig={[
				    {
				        "header": "Note",
				        "fieldName": "notes__note",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    }
				]}
				
				
				{...props}
			/>;
}