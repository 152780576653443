/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';

export default function ItemsWorkOrdersGridPanel(props) {
	return <GridPanel
				reference="ItemsWorkOrdersGridPanel"
				model="ItemsWorkOrders"
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Quantity",
				        "fieldName": "items_work_orders__quantity",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Adjustment",
				        "fieldName": "items_work_orders__adjustment",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}