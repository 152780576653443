/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ItemsWorkOrdersInlineGridEditor from './ItemsWorkOrdersInlineGridEditor.js';

export default function ItemsWorkOrdersFilteredInlineGridEditor(props) {
	return <ItemsWorkOrdersInlineGridEditor
				reference="ItemsWorkOrdersFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}