/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import AttributesWorkOrdersEditorWindowSideA from '../Window/AttributesWorkOrdersEditorWindowSideA.js';

export default function AttributesWorkOrdersGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="AttributesWorkOrdersGridEditorSideA"
				model="AttributesWorkOrders"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={AttributesWorkOrdersEditorWindowSideA}
				columnsConfig={[
				    {
				        "header": "Attribute Id",
				        "fieldName": "attributes__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "flex": 1,
				        "minWidth": 150
				    }
				]}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				
				{...props}
			/>;
}