/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';

export default function DiscountsInlineGridEditor(props) {
	return <InlineGridEditor
				reference="DiscountsInlineGridEditor"
				model="Discounts"
				selectionMode={SELECTION_MODE_SINGLE}
				defaultRowHeight={24}
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 400, // MOD
				        "editField": "discounts__item_id"
				    },
				    {
				        "header": "Name",
				        "fieldName": "discounts__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Qty",
				        "fieldName": "discounts__qty",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Amount",
				        "fieldName": "discounts__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}