/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';

export default function ItemsFilteredGridPanel(props) {
	return <GridPanel
				reference="ItemsFilteredGridPanel"
				model="Items"
				useFilters={true}
				columnsConfig={[
				    {
				        "header": "Label",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Description",
				        "fieldName": "items__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Price",
				        "fieldName": "items__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Client",
				        "fieldName": "clients__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}