/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CrewsWorkOrdersEditor from '../Editor/CrewsWorkOrdersEditor.js';

export default function CrewsWorkOrdersSideGridEditor(props) {
	return <SideGridEditor
				reference="CrewsWorkOrdersSideGridEditor"
				model="CrewsWorkOrders"
				isCollapsible={false}
				Editor={CrewsWorkOrdersEditor}
				columnsConfig={[
				    {
				        "header": "Crew",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Display Order",
				        "fieldName": "crews_work_orders__display_order",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}