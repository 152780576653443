/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import UsersEditor from '../Editor/UsersEditor.js';

export default function UsersSideGridEditor(props) {
	return <SideGridEditor
				reference="UsersSideGridEditor"
				model="Users"
				isCollapsible={false}
				Editor={UsersEditor}
				defaultRowHeight={24}
				columnsConfig={[
				    {
				        "header": "Full Name",
				        "fieldName": "users__full_name",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    },
				    {
				        "header": "Username",
				        "fieldName": "users__username",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Groups",
				        "fieldName": "users__groups",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 300 // MOD
				    },
				    // {
				    //     "header": "Password",
				    //     "fieldName": "users__password",
				    //     "sortable": true,
				    //     "isEditable": true,
				    //     "reorderable": true,
				    //     "resizable": true,
				    //     "w": 150
				    // },
				    {
				        "header": "First Name",
				        "fieldName": "users__first_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Last Name",
				        "fieldName": "users__last_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Role",
				        "fieldName": "users__role",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Last Login",
				        "fieldName": "users__last_login",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    }
				]}
				
				
				{...props}
			/>;
}