/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrderStatusesEditorWindow from '../Window/WorkOrderStatusesEditorWindow.js';

export default function WorkOrderStatusesGridEditor(props) {
	return <WindowedGridEditor
				reference="WorkOrderStatusesGridEditor"
				model="WorkOrderStatuses"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WorkOrderStatusesEditorWindow}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "work_order_statuses__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}