/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import AttributesGridEditor from './AttributesGridEditor.js';

export default function AttributesFilteredGridEditor(props) {
	return <AttributesGridEditor
				reference="AttributesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}