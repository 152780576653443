import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import DiscountsFilteredInlineGridEditor from '../components/Grid/DiscountsFilteredInlineGridEditor.js';
import DiscountsFilteredSideGridEditor from '../components/Grid/DiscountsFilteredSideGridEditor.js';

export default function DiscountsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'DiscountsManager';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Discounts"
				reference="DiscountsManager"
				fullModeComponent={<DiscountsFilteredInlineGridEditor
										reference="DiscountsFilteredInlineGridEditor"
										DiscountemoteDuplicate={true}
									/>}
				sideModeComponent={<DiscountsFilteredSideGridEditor
										reference="DiscountsFilteredSideGridEditor"
										DiscountemoteDuplicate={true}
									/>}
			/>;
}
