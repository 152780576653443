/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrderStatusesEditor from '../Editor/WorkOrderStatusesEditor.js';

export default function WorkOrderStatusesSideGridEditor(props) {
	return <SideGridEditor
				reference="WorkOrderStatusesSideGridEditor"
				model="WorkOrderStatuses"
				isCollapsible={false}
				Editor={WorkOrderStatusesEditor}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "work_order_statuses__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}