/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CrewsWorkOrdersEditorWindow from '../Window/CrewsWorkOrdersEditorWindow.js';

export default function CrewsWorkOrdersGridEditor(props) {
	return <WindowedGridEditor
				reference="CrewsWorkOrdersGridEditor"
				model="CrewsWorkOrders"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={CrewsWorkOrdersEditorWindow}
				columnsConfig={[
				    {
				        "header": "Crew",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Display Order",
				        "fieldName": "crews_work_orders__display_order",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}