import { createSlice } from '@reduxjs/toolkit';
import {
	setDebugMessage,
	setAlertMessage,
	setInfoMessage,
} from './DebugSlice.js';
import { logout } from '../../RootNavigation.js';
import oneHatData from '@onehat/data';
import getTokenHeaders from '@onehat/ui/src/Functions/getTokenHeaders.js';
import setSecure from '@onehat/ui/src/Functions/setSecure';
import deleteSecure from '@onehat/ui/src/Functions/deleteSecure';
import AppGlobals from '../../AppGlobals.js';
import moment from 'moment';
import _ from 'lodash';


export const appSlice = createSlice({
	name: 'app',
	initialState: {
		appState: 'active',
		user: null,
		currentScreen: null,
		currentTab: 'App',
	},
	reducers: {
		setAppState: (state, action) => {
			state.appState = action.payload
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setCurrentScreen: (state, action) => {
			state.currentScreen = action.payload
		},
		setCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
	}
});


// action definitions

export const {
	setAppState,
	setUser,
	setCurrentScreen,
	setCurrentTab,
} = appSlice.actions;



// thunks

export const setUserThunk = (user) => async (dispatch, getState) => {
	dispatch(setUser(user?.getOriginalData()));
	
	let headers;
	if (user) {
		await setSecure('user', user);
		headers = getTokenHeaders();
	} else {
		await deleteSecure('user');
		headers = getTokenHeaders(true); // true for clearAll
	}
	if (!_.isEmpty(headers)) {
		oneHatData.setOptionsOnAllRepositories({ headers }); // already created
		oneHatData.setRepositoryGlobals({ headers }); // not yet created
	}
	return user;
}

export function logoutThunk() {
	return async (dispatch, getState) => {
		
		const Users = oneHatData.getRepository('Users');
		await Users.logout();

		dispatch(setDebugMessage(null));
		dispatch(setAlertMessage(null));
		dispatch(setInfoMessage(null));
		await dispatch(setUserThunk(null));

		// router.replace('/');
		logout();
	};
}




// selectors

export const selectAppState = state => state.app.appState;
export const selectUser = state => state.app.user;
export const selectCurrentScreen = state => state.app.currentScreen;
export const selectCurrentTab = state => state.app.currentTab;

export default appSlice.reducer;