/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function PaymentsGrid(props) {
	return <Grid
				reference="PaymentsGrid"
				model="Payments"
				columnsConfig={[
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Amount",
				        "fieldName": "payments__amount",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Payment Date",
				        "fieldName": "payments__payment_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Comments",
				        "fieldName": "payments__comments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    },
				    {
				        "header": "Display Name",
				        "fieldName": "payments__display_name",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}

				{...props}
			/>;
}