/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PaymentsGridEditor from './PaymentsGridEditor.js';

export default function PaymentsFilteredGridEditor(props) {
	return <PaymentsGridEditor
				reference="PaymentsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}