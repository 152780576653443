/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import LotTypesEditorWindow from '../../../Window/LotTypesEditorWindow.js';

function LotTypesTag(props) {
	return <Tag
				reference="LotTypesTag"
				model="LotTypes"
				uniqueRepository={true}
				Editor={LotTypesEditorWindow}
				{...props}
			/>;
}

export default LotTypesTag;