/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import AttributesWorkOrdersGridEditorSideA from './AttributesWorkOrdersGridEditorSideA.js';

export default function AttributesWorkOrdersFilteredGridEditorSideA(props) {
	return <AttributesWorkOrdersGridEditorSideA
				reference="AttributesWorkOrdersFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}