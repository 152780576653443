import * as yup from 'yup'; // https://github.com/jquense/yup#string

const GroupsUsers = {
	
	name: 'GroupsUsers',
	model: {

		idProperty: 'groups_users__id',
		displayProperty: 'groups_users__id',
		sortProperty: 'groups_users__id',
		
		sorters: null,

		validator: yup.object({
			groups_users__group_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'groups_users__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'groups_users__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'groups_users__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, editorType: {"type":"GroupsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__role', mapping: 'user.role', title: 'Role', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Groups',
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'groups_users__user_id',
			// 'groups_users__group_id'
		],

	},

	entity: {
		methods: {

			getGroup: function() {
				const Groups = this.getAssociatedRepository('Groups');
				return Groups.getById(this.groups_users__group_id);
			},

			getUser: function() {
				const Users = this.getAssociatedRepository('Users');
				return Users.getById(this.groups_users__user_id);
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default GroupsUsers;
