/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import CrewsEditorWindow from '../Window/CrewsEditorWindow.js';

export default function CrewsGridEditor(props) {
	return <WindowedGridEditor
				reference="CrewsGridEditor"
				model="Crews"
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={CrewsEditorWindow}
				columnsConfig={[
				    {
				        "header": "User",
				        "fieldName": "users__username",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Is Active?",
				        "fieldName": "crews__is_active",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Crew Name",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Back To Shop",
				        "fieldName": "crews__back_to_shop",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": '180px' // MOD
				    }
				]}

				{...props}
			/>;
}