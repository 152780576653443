/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import DiscountsInlineGridEditor from './DiscountsInlineGridEditor.js';

export default function DiscountsFilteredInlineGridEditor(props) {
	return <DiscountsInlineGridEditor
				reference="DiscountsFilteredInlineGridEditor"
				useFilters={true}
				customFilters={[
					{
						title: 'Client',
						type: 'ClientsCombo',
						field: 'client',
						getRepoFilters: (value) => {
							return {
								name: 'items__client_id',
								value,
							};
						},
					},
				]}

				{...props}
			/>;
}