/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';

export default function GroupsUsersInlineGridEditor(props) {
	return <InlineGridEditor
				reference="GroupsUsersInlineGridEditor"
				model="GroupsUsers"
				selectionMode={SELECTION_MODE_SINGLE}
				columnsConfig={[
				    {
				        "header": "Group",
				        "fieldName": "groups__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100,
				        "editField": "groups_users__group_id"
				    }
				]}
				
				
				{...props}
			/>;
}