/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import NotesSideGridEditor from './NotesSideGridEditor.js';

export default function NotesFilteredSideGridEditor(props) {
	return <NotesSideGridEditor
				reference="NotesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}