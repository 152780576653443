export const WORK_ORDER_STATUS__UNASSIGNED = 1;
export const WORK_ORDER_STATUS__ASSIGNED = 2;
export const WORK_ORDER_STATUS__COMPLETED = 3;
export const WORK_ORDER_STATUS__OUSTANDING = 4;
export const WORK_ORDER_STATUS__PAID = 5;
export const WORK_ORDER_STATUS__RETURN_FOR_BID = 6;
export const WORK_ORDER_STATUS__NEED_TO_INVOICE = 7;
export const WORK_ORDER_STATUS__RETURN_TO_INSPECTOR = 8;
export const WORK_ORDER_STATUS__DBO = 9;
export const WORK_ORDER_STATUS__DUPLICATE = 10;
export const WORK_ORDER_STATUS__HOLD = 11;
