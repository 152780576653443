import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Permissions = {
	
	name: 'Permissions',
	model: {

		idProperty: 'permissions__id',
		displayProperty: 'permissions__id',
		sortProperty: 'permissions__id',
		
		sorters: null,

		validator: yup.object({
			permissions__view_reports: yup.boolean().nullable(),
			permissions__view_dashboard: yup.boolean().nullable(),
			permissions__view_attachments: yup.boolean().nullable(),
			permissions__add_attachments: yup.boolean().nullable(),
			permissions__edit_attachments: yup.boolean().nullable(),
			permissions__delete_attachments: yup.boolean().nullable(),
			permissions__view_attributes: yup.boolean().nullable(),
			permissions__add_attributes: yup.boolean().nullable(),
			permissions__edit_attributes: yup.boolean().nullable(),
			permissions__delete_attributes: yup.boolean().nullable(),
			permissions__view_clients: yup.boolean().nullable(),
			permissions__add_clients: yup.boolean().nullable(),
			permissions__edit_clients: yup.boolean().nullable(),
			permissions__delete_clients: yup.boolean().nullable(),
			permissions__view_crews: yup.boolean().nullable(),
			permissions__add_crews: yup.boolean().nullable(),
			permissions__edit_crews: yup.boolean().nullable(),
			permissions__delete_crews: yup.boolean().nullable(),
			permissions__view_crews_work_orders: yup.boolean().nullable(),
			permissions__add_crews_work_orders: yup.boolean().nullable(),
			permissions__edit_crews_work_orders: yup.boolean().nullable(),
			permissions__delete_crews_work_orders: yup.boolean().nullable(),
			permissions__view_discounts: yup.boolean().nullable(),
			permissions__add_discounts: yup.boolean().nullable(),
			permissions__edit_discounts: yup.boolean().nullable(),
			permissions__delete_discounts: yup.boolean().nullable(),
			permissions__view_groups: yup.boolean().nullable(),
			permissions__add_groups: yup.boolean().nullable(),
			permissions__edit_groups: yup.boolean().nullable(),
			permissions__delete_groups: yup.boolean().nullable(),
			permissions__view_groups_users: yup.boolean().nullable(),
			permissions__add_groups_users: yup.boolean().nullable(),
			permissions__edit_groups_users: yup.boolean().nullable(),
			permissions__delete_groups_users: yup.boolean().nullable(),
			permissions__view_items: yup.boolean().nullable(),
			permissions__add_items: yup.boolean().nullable(),
			permissions__edit_items: yup.boolean().nullable(),
			permissions__delete_items: yup.boolean().nullable(),
			permissions__view_lot_types: yup.boolean().nullable(),
			permissions__add_lot_types: yup.boolean().nullable(),
			permissions__edit_lot_types: yup.boolean().nullable(),
			permissions__delete_lot_types: yup.boolean().nullable(),
			permissions__view_notes: yup.boolean().nullable(),
			permissions__add_notes: yup.boolean().nullable(),
			permissions__edit_notes: yup.boolean().nullable(),
			permissions__delete_notes: yup.boolean().nullable(),
			permissions__view_payments: yup.boolean().nullable(),
			permissions__add_payments: yup.boolean().nullable(),
			permissions__edit_payments: yup.boolean().nullable(),
			permissions__delete_payments: yup.boolean().nullable(),
			permissions__view_permissions: yup.boolean().nullable(),
			permissions__add_permissions: yup.boolean().nullable(),
			permissions__edit_permissions: yup.boolean().nullable(),
			permissions__delete_permissions: yup.boolean().nullable(),
			permissions__view_time_entries: yup.boolean().nullable(),
			permissions__add_time_entries: yup.boolean().nullable(),
			permissions__edit_time_entries: yup.boolean().nullable(),
			permissions__delete_time_entries: yup.boolean().nullable(),
			permissions__view_users: yup.boolean().nullable(),
			permissions__add_users: yup.boolean().nullable(),
			permissions__edit_users: yup.boolean().nullable(),
			permissions__delete_users: yup.boolean().nullable(),
			permissions__view_work_orders: yup.boolean().nullable(),
			permissions__add_work_orders: yup.boolean().nullable(),
			permissions__edit_work_orders: yup.boolean().nullable(),
			permissions__delete_work_orders: yup.boolean().nullable(),
			permissions__view_attributes_work_orders: yup.boolean().nullable(),
			permissions__add_attributes_work_orders: yup.boolean().nullable(),
			permissions__edit_attributes_work_orders: yup.boolean().nullable(),
			permissions__delete_attributes_work_orders: yup.boolean().nullable(),
			permissions__view_items_work_orders: yup.boolean().nullable(),
			permissions__add_items_work_orders: yup.boolean().nullable(),
			permissions__edit_items_work_orders: yup.boolean().nullable(),
			permissions__delete_items_work_orders: yup.boolean().nullable(),
			permissions__view_work_order_duplicates: yup.boolean().nullable(),
			permissions__add_work_order_duplicates: yup.boolean().nullable(),
			permissions__edit_work_order_duplicates: yup.boolean().nullable(),
			permissions__delete_work_order_duplicates: yup.boolean().nullable(),
			permissions__view_work_order_statuses: yup.boolean().nullable(),
			permissions__add_work_order_statuses: yup.boolean().nullable(),
			permissions__edit_work_order_statuses: yup.boolean().nullable(),
			permissions__delete_work_order_statuses: yup.boolean().nullable(),
			permissions__view_work_order_types: yup.boolean().nullable(),
			permissions__add_work_order_types: yup.boolean().nullable(),
			permissions__edit_work_order_types: yup.boolean().nullable(),
			permissions__delete_work_order_types: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'permissions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__view_reports', mapping: 'view_reports', title: 'View Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__view_dashboard', mapping: 'view_dashboard', title: 'View Dashboard', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__view_attachments', mapping: 'view_attachments', title: 'View Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__add_attachments', mapping: 'add_attachments', title: 'Add Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__edit_attachments', mapping: 'edit_attachments', title: 'Edit Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__delete_attachments', mapping: 'delete_attachments', title: 'Delete Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Attachments', },
			{ name: 'permissions__view_attributes', mapping: 'view_attributes', title: 'View Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attributes', },
			{ name: 'permissions__add_attributes', mapping: 'add_attributes', title: 'Add Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attributes', },
			{ name: 'permissions__edit_attributes', mapping: 'edit_attributes', title: 'Edit Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attributes', },
			{ name: 'permissions__delete_attributes', mapping: 'delete_attributes', title: 'Delete Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attributes', },
			{ name: 'permissions__view_clients', mapping: 'view_clients', title: 'View Clients', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Clients', },
			{ name: 'permissions__add_clients', mapping: 'add_clients', title: 'Add Clients', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Clients', },
			{ name: 'permissions__edit_clients', mapping: 'edit_clients', title: 'Edit Clients', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Clients', },
			{ name: 'permissions__delete_clients', mapping: 'delete_clients', title: 'Delete Clients', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Clients', },
			{ name: 'permissions__view_crews', mapping: 'view_crews', title: 'View Crews', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Crews', },
			{ name: 'permissions__add_crews', mapping: 'add_crews', title: 'Add Crews', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Crews', },
			{ name: 'permissions__edit_crews', mapping: 'edit_crews', title: 'Edit Crews', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Crews', },
			{ name: 'permissions__delete_crews', mapping: 'delete_crews', title: 'Delete Crews', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. Crews', },
			{ name: 'permissions__view_crews_work_orders', mapping: 'view_crews_work_orders', title: 'View Crews Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. CrewsWorkOrders', },
			{ name: 'permissions__add_crews_work_orders', mapping: 'add_crews_work_orders', title: 'Add Crews Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. CrewsWorkOrders', },
			{ name: 'permissions__edit_crews_work_orders', mapping: 'edit_crews_work_orders', title: 'Edit Crews Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. CrewsWorkOrders', },
			{ name: 'permissions__delete_crews_work_orders', mapping: 'delete_crews_work_orders', title: 'Delete Crews Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. CrewsWorkOrders', },
			{ name: 'permissions__view_discounts', mapping: 'view_discounts', title: 'View Discounts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Discounts', },
			{ name: 'permissions__add_discounts', mapping: 'add_discounts', title: 'Add Discounts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Discounts', },
			{ name: 'permissions__edit_discounts', mapping: 'edit_discounts', title: 'Edit Discounts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Discounts', },
			{ name: 'permissions__delete_discounts', mapping: 'delete_discounts', title: 'Delete Discounts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. Discounts', },
			{ name: 'permissions__view_groups', mapping: 'view_groups', title: 'View Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. Groups', },
			{ name: 'permissions__add_groups', mapping: 'add_groups', title: 'Add Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. Groups', },
			{ name: 'permissions__edit_groups', mapping: 'edit_groups', title: 'Edit Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. Groups', },
			{ name: 'permissions__delete_groups', mapping: 'delete_groups', title: 'Delete Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. Groups', },
			{ name: 'permissions__view_groups_users', mapping: 'view_groups_users', title: 'View Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. GroupsUsers', },
			{ name: 'permissions__add_groups_users', mapping: 'add_groups_users', title: 'Add Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. GroupsUsers', },
			{ name: 'permissions__edit_groups_users', mapping: 'edit_groups_users', title: 'Edit Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. GroupsUsers', },
			{ name: 'permissions__delete_groups_users', mapping: 'delete_groups_users', title: 'Delete Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. GroupsUsers', },
			{ name: 'permissions__view_items', mapping: 'view_items', title: 'View Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Items', },
			{ name: 'permissions__add_items', mapping: 'add_items', title: 'Add Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Items', },
			{ name: 'permissions__edit_items', mapping: 'edit_items', title: 'Edit Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Items', },
			{ name: 'permissions__delete_items', mapping: 'delete_items', title: 'Delete Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. Items', },
			{ name: 'permissions__view_lot_types', mapping: 'view_lot_types', title: 'View Lot Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. LotTypes', },
			{ name: 'permissions__add_lot_types', mapping: 'add_lot_types', title: 'Add Lot Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. LotTypes', },
			{ name: 'permissions__edit_lot_types', mapping: 'edit_lot_types', title: 'Edit Lot Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. LotTypes', },
			{ name: 'permissions__delete_lot_types', mapping: 'delete_lot_types', title: 'Delete Lot Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. LotTypes', },
			{ name: 'permissions__view_notes', mapping: 'view_notes', title: 'View Notes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Notes', },
			{ name: 'permissions__add_notes', mapping: 'add_notes', title: 'Add Notes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Notes', },
			{ name: 'permissions__edit_notes', mapping: 'edit_notes', title: 'Edit Notes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Notes', },
			{ name: 'permissions__delete_notes', mapping: 'delete_notes', title: 'Delete Notes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. Notes', },
			{ name: 'permissions__view_payments', mapping: 'view_payments', title: 'View Payments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Payments', },
			{ name: 'permissions__add_payments', mapping: 'add_payments', title: 'Add Payments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Payments', },
			{ name: 'permissions__edit_payments', mapping: 'edit_payments', title: 'Edit Payments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Payments', },
			{ name: 'permissions__delete_payments', mapping: 'delete_payments', title: 'Delete Payments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. Payments', },
			{ name: 'permissions__view_permissions', mapping: 'view_permissions', title: 'View Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Permissions', },
			{ name: 'permissions__add_permissions', mapping: 'add_permissions', title: 'Add Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Permissions', },
			{ name: 'permissions__edit_permissions', mapping: 'edit_permissions', title: 'Edit Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Permissions', },
			{ name: 'permissions__delete_permissions', mapping: 'delete_permissions', title: 'Delete Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. Permissions', },
			{ name: 'permissions__view_time_entries', mapping: 'view_time_entries', title: 'View Time Entries', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. TimeEntries', },
			{ name: 'permissions__add_time_entries', mapping: 'add_time_entries', title: 'Add Time Entries', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. TimeEntries', },
			{ name: 'permissions__edit_time_entries', mapping: 'edit_time_entries', title: 'Edit Time Entries', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. TimeEntries', },
			{ name: 'permissions__delete_time_entries', mapping: 'delete_time_entries', title: 'Delete Time Entries', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. TimeEntries', },
			{ name: 'permissions__view_users', mapping: 'view_users', title: 'View Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__add_users', mapping: 'add_users', title: 'Add Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__edit_users', mapping: 'edit_users', title: 'Edit Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__delete_users', mapping: 'delete_users', title: 'Delete Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. Users', },
			{ name: 'permissions__view_work_orders', mapping: 'view_work_orders', title: 'View Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. WorkOrders', },
			{ name: 'permissions__add_work_orders', mapping: 'add_work_orders', title: 'Add Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. WorkOrders', },
			{ name: 'permissions__edit_work_orders', mapping: 'edit_work_orders', title: 'Edit Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. WorkOrders', },
			{ name: 'permissions__delete_work_orders', mapping: 'delete_work_orders', title: 'Delete Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. WorkOrders', },
			{ name: 'permissions__view_attributes_work_orders', mapping: 'view_attributes_work_orders', title: 'View Work Orders Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. AttributesWorkOrders', },
			{ name: 'permissions__add_attributes_work_orders', mapping: 'add_attributes_work_orders', title: 'Add Work Orders Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. AttributesWorkOrders', },
			{ name: 'permissions__edit_attributes_work_orders', mapping: 'edit_attributes_work_orders', title: 'Edit Work Orders Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. AttributesWorkOrders', },
			{ name: 'permissions__delete_attributes_work_orders', mapping: 'delete_attributes_work_orders', title: 'Delete Work Orders Attributes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. AttributesWorkOrders', },
			{ name: 'permissions__view_items_work_orders', mapping: 'view_items_work_orders', title: 'View Work Orders Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ItemsWorkOrders', },
			{ name: 'permissions__add_items_work_orders', mapping: 'add_items_work_orders', title: 'Add Work Orders Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ItemsWorkOrders', },
			{ name: 'permissions__edit_items_work_orders', mapping: 'edit_items_work_orders', title: 'Edit Work Orders Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ItemsWorkOrders', },
			{ name: 'permissions__delete_items_work_orders', mapping: 'delete_items_work_orders', title: 'Delete Work Orders Items', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ItemsWorkOrders', },
			{ name: 'permissions__view_work_order_duplicates', mapping: 'view_work_order_duplicates', title: 'View Work Order Duplicates', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. WorkOrderDuplicates', },
			{ name: 'permissions__add_work_order_duplicates', mapping: 'add_work_order_duplicates', title: 'Add Work Order Duplicates', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. WorkOrderDuplicates', },
			{ name: 'permissions__edit_work_order_duplicates', mapping: 'edit_work_order_duplicates', title: 'Edit Work Order Duplicates', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. WorkOrderDuplicates', },
			{ name: 'permissions__delete_work_order_duplicates', mapping: 'delete_work_order_duplicates', title: 'Delete Work Order Duplicates', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. WorkOrderDuplicates', },
			{ name: 'permissions__view_work_order_statuses', mapping: 'view_work_order_statuses', title: 'View Work Order Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. WorkOrderStatuses', },
			{ name: 'permissions__add_work_order_statuses', mapping: 'add_work_order_statuses', title: 'Add Work Order Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. WorkOrderStatuses', },
			{ name: 'permissions__edit_work_order_statuses', mapping: 'edit_work_order_statuses', title: 'Edit Work Order Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. WorkOrderStatuses', },
			{ name: 'permissions__delete_work_order_statuses', mapping: 'delete_work_order_statuses', title: 'Delete Work Order Statuses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. WorkOrderStatuses', },
			{ name: 'permissions__view_work_order_types', mapping: 'view_work_order_types', title: 'View Work Order Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. WorkOrderTypes', },
			{ name: 'permissions__add_work_order_types', mapping: 'add_work_order_types', title: 'Add Work Order Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. WorkOrderTypes', },
			{ name: 'permissions__edit_work_order_types', mapping: 'edit_work_order_types', title: 'Edit Work Order Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. WorkOrderTypes', },
			{ name: 'permissions__delete_work_order_types', mapping: 'delete_work_order_types', title: 'Delete Work Order Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. WorkOrderTypes', },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__role', mapping: 'user.role', title: 'Role', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'permissions__user_id',
			// 'permissions__group_id',
			// 'permissions__view_reports',
			// 'permissions__view_dashboard',
			// 'permissions__view_attachments',
			// 'permissions__add_attachments',
			// 'permissions__edit_attachments',
			// 'permissions__delete_attachments',
			// 'permissions__view_attributes',
			// 'permissions__add_attributes',
			// 'permissions__edit_attributes',
			// 'permissions__delete_attributes',
			// 'permissions__view_clients',
			// 'permissions__add_clients',
			// 'permissions__edit_clients',
			// 'permissions__delete_clients',
			// 'permissions__view_crews',
			// 'permissions__add_crews',
			// 'permissions__edit_crews',
			// 'permissions__delete_crews',
			// 'permissions__view_crews_work_orders',
			// 'permissions__add_crews_work_orders',
			// 'permissions__edit_crews_work_orders',
			// 'permissions__delete_crews_work_orders',
			// 'permissions__view_discounts',
			// 'permissions__add_discounts',
			// 'permissions__edit_discounts',
			// 'permissions__delete_discounts',
			// 'permissions__view_groups',
			// 'permissions__add_groups',
			// 'permissions__edit_groups',
			// 'permissions__delete_groups',
			// 'permissions__view_groups_users',
			// 'permissions__add_groups_users',
			// 'permissions__edit_groups_users',
			// 'permissions__delete_groups_users',
			// 'permissions__view_items',
			// 'permissions__add_items',
			// 'permissions__edit_items',
			// 'permissions__delete_items',
			// 'permissions__view_lot_types',
			// 'permissions__add_lot_types',
			// 'permissions__edit_lot_types',
			// 'permissions__delete_lot_types',
			// 'permissions__view_notes',
			// 'permissions__add_notes',
			// 'permissions__edit_notes',
			// 'permissions__delete_notes',
			// 'permissions__view_payments',
			// 'permissions__add_payments',
			// 'permissions__edit_payments',
			// 'permissions__delete_payments',
			// 'permissions__view_permissions',
			// 'permissions__add_permissions',
			// 'permissions__edit_permissions',
			// 'permissions__delete_permissions',
			// 'permissions__view_time_entries',
			// 'permissions__add_time_entries',
			// 'permissions__edit_time_entries',
			// 'permissions__delete_time_entries',
			// 'permissions__view_users',
			// 'permissions__add_users',
			// 'permissions__edit_users',
			// 'permissions__delete_users',
			// 'permissions__view_work_orders',
			// 'permissions__add_work_orders',
			// 'permissions__edit_work_orders',
			// 'permissions__delete_work_orders',
			// 'permissions__view_attributes_work_orders',
			// 'permissions__add_attributes_work_orders',
			// 'permissions__edit_attributes_work_orders',
			// 'permissions__delete_attributes_work_orders',
			// 'permissions__view_items_work_orders',
			// 'permissions__add_items_work_orders',
			// 'permissions__edit_items_work_orders',
			// 'permissions__delete_items_work_orders',
			// 'permissions__view_work_order_duplicates',
			// 'permissions__add_work_order_duplicates',
			// 'permissions__edit_work_order_duplicates',
			// 'permissions__delete_work_order_duplicates',
			// 'permissions__view_work_order_statuses',
			// 'permissions__add_work_order_statuses',
			// 'permissions__edit_work_order_statuses',
			// 'permissions__delete_work_order_statuses',
			// 'permissions__view_work_order_types',
			// 'permissions__add_work_order_types',
			// 'permissions__edit_work_order_types',
			// 'permissions__delete_work_order_types'
		],

	},

	entity: {
		methods: {

			getUser: function() {
				const Users = this.getAssociatedRepository('Users');
				return Users.getById(this.permissions__user_id);
			},

			getGroup: function() {
				const Groups = this.getAssociatedRepository('Groups');
				return Groups.getById(this.permissions__group_id);
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Permissions;
