/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import registerComponents from '@onehat/ui/src/Functions/registerComponents.js';

import AttributesCombo from '../components/Form/Field/Combo/AttributesCombo.js';
import AttributesComboEditor from '../components/Form/Field/Combo/AttributesComboEditor.js';
import AttributesTag from '../components/Form/Field/Tag/AttributesTag.js';
import AttributesTagEditor from '../components/Form/Field/Tag/AttributesTagEditor.js';
import AttributesGrid from '../components/Grid/AttributesGrid.js';
import AttributesGridEditor from '../components/Grid/AttributesGridEditor.js';
import AttributesSideGridEditor from '../components/Grid/AttributesSideGridEditor.js';
import AttributesInlineGridEditor from '../components/Grid/AttributesInlineGridEditor.js';
import AttributesFilteredGrid from '../components/Grid/AttributesFilteredGrid.js';
import AttributesFilteredGridEditor from '../components/Grid/AttributesFilteredGridEditor.js';
import AttributesFilteredSideGridEditor from '../components/Grid/AttributesFilteredSideGridEditor.js';
import AttributesFilteredInlineGridEditor from '../components/Grid/AttributesFilteredInlineGridEditor.js';
import AttributesGridPanel from '../components/Panel/Grid/AttributesGrid.js';
import AttributesGridEditorPanel from '../components/Panel/Grid/AttributesGridEditor.js';
import AttributesSideGridEditorPanel from '../components/Panel/Grid/AttributesSideGridEditor.js';
import AttributesInlineGridEditorPanel from '../components/Panel/Grid/AttributesInlineGridEditor.js';
import AttributesFilteredGridPanel from '../components/Panel/Grid/AttributesFilteredGrid.js';
import AttributesFilteredGridEditorPanel from '../components/Panel/Grid/AttributesFilteredGridEditor.js';
import AttributesFilteredSideGridEditorPanel from '../components/Panel/Grid/AttributesFilteredSideGridEditor.js';
import AttributesFilteredInlineGridEditorPanel from '../components/Panel/Grid/AttributesFilteredInlineGridEditor.js';
import AttributesEditor from '../components/Editor/AttributesEditor.js';
import AttributesEditorWindow from '../components/Window/AttributesEditorWindow.js';
import AttributesWorkOrdersCombo from '../components/Form/Field/Combo/AttributesWorkOrdersCombo.js';
import AttributesWorkOrdersComboEditor from '../components/Form/Field/Combo/AttributesWorkOrdersComboEditor.js';
import AttributesWorkOrdersTag from '../components/Form/Field/Tag/AttributesWorkOrdersTag.js';
import AttributesWorkOrdersTagEditor from '../components/Form/Field/Tag/AttributesWorkOrdersTagEditor.js';
import AttributesWorkOrdersGrid from '../components/Grid/AttributesWorkOrdersGrid.js';
import AttributesWorkOrdersGridEditor from '../components/Grid/AttributesWorkOrdersGridEditor.js';
import AttributesWorkOrdersSideGridEditor from '../components/Grid/AttributesWorkOrdersSideGridEditor.js';
import AttributesWorkOrdersInlineGridEditor from '../components/Grid/AttributesWorkOrdersInlineGridEditor.js';
import AttributesWorkOrdersFilteredGrid from '../components/Grid/AttributesWorkOrdersFilteredGrid.js';
import AttributesWorkOrdersFilteredGridEditor from '../components/Grid/AttributesWorkOrdersFilteredGridEditor.js';
import AttributesWorkOrdersFilteredSideGridEditor from '../components/Grid/AttributesWorkOrdersFilteredSideGridEditor.js';
import AttributesWorkOrdersFilteredInlineGridEditor from '../components/Grid/AttributesWorkOrdersFilteredInlineGridEditor.js';
import AttributesWorkOrdersGridPanel from '../components/Panel/Grid/AttributesWorkOrdersGrid.js';
import AttributesWorkOrdersGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersGridEditor.js';
import AttributesWorkOrdersSideGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersSideGridEditor.js';
import AttributesWorkOrdersInlineGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersInlineGridEditor.js';
import AttributesWorkOrdersFilteredGridPanel from '../components/Panel/Grid/AttributesWorkOrdersFilteredGrid.js';
import AttributesWorkOrdersFilteredGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersFilteredGridEditor.js';
import AttributesWorkOrdersFilteredSideGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersFilteredSideGridEditor.js';
import AttributesWorkOrdersFilteredInlineGridEditorPanel from '../components/Panel/Grid/AttributesWorkOrdersFilteredInlineGridEditor.js';
import AttributesWorkOrdersEditor from '../components/Editor/AttributesWorkOrdersEditor.js';
import AttributesWorkOrdersEditorWindow from '../components/Window/AttributesWorkOrdersEditorWindow.js';
import AttributesWorkOrdersGridSideA from '../components/Grid/AttributesWorkOrdersGridSideA.js';
import AttributesWorkOrdersGridSideB from '../components/Grid/AttributesWorkOrdersGridSideB.js';
import AttributesWorkOrdersGridEditorSideA from '../components/Grid/AttributesWorkOrdersGridEditorSideA.js';
import AttributesWorkOrdersGridEditorSideB from '../components/Grid/AttributesWorkOrdersGridEditorSideB.js';
import AttributesWorkOrdersFilteredGridEditorSideA from '../components/Grid/AttributesWorkOrdersFilteredGridEditorSideA.js';
import AttributesWorkOrdersFilteredGridEditorSideB from '../components/Grid/AttributesWorkOrdersFilteredGridEditorSideB.js';
import AttributesWorkOrdersEditorWindowSideA from '../components/Window/AttributesWorkOrdersEditorWindowSideA.js';
import AttributesWorkOrdersEditorWindowSideB from '../components/Window/AttributesWorkOrdersEditorWindowSideB.js';
import AttributesWorkOrdersEditorSideA from '../components/Editor/AttributesWorkOrdersEditorSideA.js';
import AttributesWorkOrdersEditorSideB from '../components/Editor/AttributesWorkOrdersEditorSideB.js';
import ClientsCombo from '../components/Form/Field/Combo/ClientsCombo.js';
import ClientsComboEditor from '../components/Form/Field/Combo/ClientsComboEditor.js';
import ClientsTag from '../components/Form/Field/Tag/ClientsTag.js';
import ClientsTagEditor from '../components/Form/Field/Tag/ClientsTagEditor.js';
import ClientsGrid from '../components/Grid/ClientsGrid.js';
import ClientsGridEditor from '../components/Grid/ClientsGridEditor.js';
import ClientsSideGridEditor from '../components/Grid/ClientsSideGridEditor.js';
import ClientsInlineGridEditor from '../components/Grid/ClientsInlineGridEditor.js';
import ClientsFilteredGrid from '../components/Grid/ClientsFilteredGrid.js';
import ClientsFilteredGridEditor from '../components/Grid/ClientsFilteredGridEditor.js';
import ClientsFilteredSideGridEditor from '../components/Grid/ClientsFilteredSideGridEditor.js';
import ClientsFilteredInlineGridEditor from '../components/Grid/ClientsFilteredInlineGridEditor.js';
import ClientsGridPanel from '../components/Panel/Grid/ClientsGrid.js';
import ClientsGridEditorPanel from '../components/Panel/Grid/ClientsGridEditor.js';
import ClientsSideGridEditorPanel from '../components/Panel/Grid/ClientsSideGridEditor.js';
import ClientsInlineGridEditorPanel from '../components/Panel/Grid/ClientsInlineGridEditor.js';
import ClientsFilteredGridPanel from '../components/Panel/Grid/ClientsFilteredGrid.js';
import ClientsFilteredGridEditorPanel from '../components/Panel/Grid/ClientsFilteredGridEditor.js';
import ClientsFilteredSideGridEditorPanel from '../components/Panel/Grid/ClientsFilteredSideGridEditor.js';
import ClientsFilteredInlineGridEditorPanel from '../components/Panel/Grid/ClientsFilteredInlineGridEditor.js';
import ClientsEditor from '../components/Editor/ClientsEditor.js';
import ClientsEditorWindow from '../components/Window/ClientsEditorWindow.js';
import CrewsCombo from '../components/Form/Field/Combo/CrewsCombo.js';
import CrewsComboEditor from '../components/Form/Field/Combo/CrewsComboEditor.js';
import CrewsTag from '../components/Form/Field/Tag/CrewsTag.js';
import CrewsTagEditor from '../components/Form/Field/Tag/CrewsTagEditor.js';
import CrewsGrid from '../components/Grid/CrewsGrid.js';
import CrewsGridEditor from '../components/Grid/CrewsGridEditor.js';
import CrewsSideGridEditor from '../components/Grid/CrewsSideGridEditor.js';
import CrewsInlineGridEditor from '../components/Grid/CrewsInlineGridEditor.js';
import CrewsFilteredGrid from '../components/Grid/CrewsFilteredGrid.js';
import CrewsFilteredGridEditor from '../components/Grid/CrewsFilteredGridEditor.js';
import CrewsFilteredSideGridEditor from '../components/Grid/CrewsFilteredSideGridEditor.js';
import CrewsFilteredInlineGridEditor from '../components/Grid/CrewsFilteredInlineGridEditor.js';
import CrewsGridPanel from '../components/Panel/Grid/CrewsGrid.js';
import CrewsGridEditorPanel from '../components/Panel/Grid/CrewsGridEditor.js';
import CrewsSideGridEditorPanel from '../components/Panel/Grid/CrewsSideGridEditor.js';
import CrewsInlineGridEditorPanel from '../components/Panel/Grid/CrewsInlineGridEditor.js';
import CrewsFilteredGridPanel from '../components/Panel/Grid/CrewsFilteredGrid.js';
import CrewsFilteredGridEditorPanel from '../components/Panel/Grid/CrewsFilteredGridEditor.js';
import CrewsFilteredSideGridEditorPanel from '../components/Panel/Grid/CrewsFilteredSideGridEditor.js';
import CrewsFilteredInlineGridEditorPanel from '../components/Panel/Grid/CrewsFilteredInlineGridEditor.js';
import CrewsEditor from '../components/Editor/CrewsEditor.js';
import CrewsEditorWindow from '../components/Window/CrewsEditorWindow.js';
import CrewsWorkOrdersCombo from '../components/Form/Field/Combo/CrewsWorkOrdersCombo.js';
import CrewsWorkOrdersComboEditor from '../components/Form/Field/Combo/CrewsWorkOrdersComboEditor.js';
import CrewsWorkOrdersTag from '../components/Form/Field/Tag/CrewsWorkOrdersTag.js';
import CrewsWorkOrdersTagEditor from '../components/Form/Field/Tag/CrewsWorkOrdersTagEditor.js';
import CrewsWorkOrdersGrid from '../components/Grid/CrewsWorkOrdersGrid.js';
import CrewsWorkOrdersGridEditor from '../components/Grid/CrewsWorkOrdersGridEditor.js';
import CrewsWorkOrdersSideGridEditor from '../components/Grid/CrewsWorkOrdersSideGridEditor.js';
import CrewsWorkOrdersInlineGridEditor from '../components/Grid/CrewsWorkOrdersInlineGridEditor.js';
import CrewsWorkOrdersFilteredGrid from '../components/Grid/CrewsWorkOrdersFilteredGrid.js';
import CrewsWorkOrdersFilteredGridEditor from '../components/Grid/CrewsWorkOrdersFilteredGridEditor.js';
import CrewsWorkOrdersFilteredSideGridEditor from '../components/Grid/CrewsWorkOrdersFilteredSideGridEditor.js';
import CrewsWorkOrdersFilteredInlineGridEditor from '../components/Grid/CrewsWorkOrdersFilteredInlineGridEditor.js';
import CrewsWorkOrdersGridPanel from '../components/Panel/Grid/CrewsWorkOrdersGrid.js';
import CrewsWorkOrdersGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersGridEditor.js';
import CrewsWorkOrdersSideGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersSideGridEditor.js';
import CrewsWorkOrdersInlineGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersInlineGridEditor.js';
import CrewsWorkOrdersFilteredGridPanel from '../components/Panel/Grid/CrewsWorkOrdersFilteredGrid.js';
import CrewsWorkOrdersFilteredGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersFilteredGridEditor.js';
import CrewsWorkOrdersFilteredSideGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersFilteredSideGridEditor.js';
import CrewsWorkOrdersFilteredInlineGridEditorPanel from '../components/Panel/Grid/CrewsWorkOrdersFilteredInlineGridEditor.js';
import CrewsWorkOrdersEditor from '../components/Editor/CrewsWorkOrdersEditor.js';
import CrewsWorkOrdersEditorWindow from '../components/Window/CrewsWorkOrdersEditorWindow.js';
import CrewsWorkOrdersGridSideA from '../components/Grid/CrewsWorkOrdersGridSideA.js';
import CrewsWorkOrdersGridSideB from '../components/Grid/CrewsWorkOrdersGridSideB.js';
import CrewsWorkOrdersGridEditorSideA from '../components/Grid/CrewsWorkOrdersGridEditorSideA.js';
import CrewsWorkOrdersGridEditorSideB from '../components/Grid/CrewsWorkOrdersGridEditorSideB.js';
import CrewsWorkOrdersFilteredGridEditorSideA from '../components/Grid/CrewsWorkOrdersFilteredGridEditorSideA.js';
import CrewsWorkOrdersFilteredGridEditorSideB from '../components/Grid/CrewsWorkOrdersFilteredGridEditorSideB.js';
import CrewsWorkOrdersEditorWindowSideA from '../components/Window/CrewsWorkOrdersEditorWindowSideA.js';
import CrewsWorkOrdersEditorWindowSideB from '../components/Window/CrewsWorkOrdersEditorWindowSideB.js';
import CrewsWorkOrdersEditorSideA from '../components/Editor/CrewsWorkOrdersEditorSideA.js';
import CrewsWorkOrdersEditorSideB from '../components/Editor/CrewsWorkOrdersEditorSideB.js';
import DiscountsCombo from '../components/Form/Field/Combo/DiscountsCombo.js';
import DiscountsComboEditor from '../components/Form/Field/Combo/DiscountsComboEditor.js';
import DiscountsTag from '../components/Form/Field/Tag/DiscountsTag.js';
import DiscountsTagEditor from '../components/Form/Field/Tag/DiscountsTagEditor.js';
import DiscountsGrid from '../components/Grid/DiscountsGrid.js';
import DiscountsGridEditor from '../components/Grid/DiscountsGridEditor.js';
import DiscountsSideGridEditor from '../components/Grid/DiscountsSideGridEditor.js';
import DiscountsInlineGridEditor from '../components/Grid/DiscountsInlineGridEditor.js';
import DiscountsFilteredGrid from '../components/Grid/DiscountsFilteredGrid.js';
import DiscountsFilteredGridEditor from '../components/Grid/DiscountsFilteredGridEditor.js';
import DiscountsFilteredSideGridEditor from '../components/Grid/DiscountsFilteredSideGridEditor.js';
import DiscountsFilteredInlineGridEditor from '../components/Grid/DiscountsFilteredInlineGridEditor.js';
import DiscountsGridPanel from '../components/Panel/Grid/DiscountsGrid.js';
import DiscountsGridEditorPanel from '../components/Panel/Grid/DiscountsGridEditor.js';
import DiscountsSideGridEditorPanel from '../components/Panel/Grid/DiscountsSideGridEditor.js';
import DiscountsInlineGridEditorPanel from '../components/Panel/Grid/DiscountsInlineGridEditor.js';
import DiscountsFilteredGridPanel from '../components/Panel/Grid/DiscountsFilteredGrid.js';
import DiscountsFilteredGridEditorPanel from '../components/Panel/Grid/DiscountsFilteredGridEditor.js';
import DiscountsFilteredSideGridEditorPanel from '../components/Panel/Grid/DiscountsFilteredSideGridEditor.js';
import DiscountsFilteredInlineGridEditorPanel from '../components/Panel/Grid/DiscountsFilteredInlineGridEditor.js';
import DiscountsEditor from '../components/Editor/DiscountsEditor.js';
import DiscountsEditorWindow from '../components/Window/DiscountsEditorWindow.js';
import GroupsCombo from '../components/Form/Field/Combo/GroupsCombo.js';
import GroupsComboEditor from '../components/Form/Field/Combo/GroupsComboEditor.js';
import GroupsTag from '../components/Form/Field/Tag/GroupsTag.js';
import GroupsTagEditor from '../components/Form/Field/Tag/GroupsTagEditor.js';
import GroupsGrid from '../components/Grid/GroupsGrid.js';
import GroupsGridEditor from '../components/Grid/GroupsGridEditor.js';
import GroupsSideGridEditor from '../components/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditor from '../components/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGrid from '../components/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditor from '../components/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditor from '../components/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditor from '../components/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsGridPanel from '../components/Panel/Grid/GroupsGrid.js';
import GroupsGridEditorPanel from '../components/Panel/Grid/GroupsGridEditor.js';
import GroupsSideGridEditorPanel from '../components/Panel/Grid/GroupsSideGridEditor.js';
import GroupsInlineGridEditorPanel from '../components/Panel/Grid/GroupsInlineGridEditor.js';
import GroupsFilteredGridPanel from '../components/Panel/Grid/GroupsFilteredGrid.js';
import GroupsFilteredGridEditorPanel from '../components/Panel/Grid/GroupsFilteredGridEditor.js';
import GroupsFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsFilteredSideGridEditor.js';
import GroupsFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsFilteredInlineGridEditor.js';
import GroupsEditor from '../components/Editor/GroupsEditor.js';
import GroupsEditorWindow from '../components/Window/GroupsEditorWindow.js';
import GroupsUsersCombo from '../components/Form/Field/Combo/GroupsUsersCombo.js';
import GroupsUsersComboEditor from '../components/Form/Field/Combo/GroupsUsersComboEditor.js';
import GroupsUsersTag from '../components/Form/Field/Tag/GroupsUsersTag.js';
import GroupsUsersTagEditor from '../components/Form/Field/Tag/GroupsUsersTagEditor.js';
import GroupsUsersGrid from '../components/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditor from '../components/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditor from '../components/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditor from '../components/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGrid from '../components/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditor from '../components/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditor from '../components/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditor from '../components/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersGridPanel from '../components/Panel/Grid/GroupsUsersGrid.js';
import GroupsUsersGridEditorPanel from '../components/Panel/Grid/GroupsUsersGridEditor.js';
import GroupsUsersSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersSideGridEditor.js';
import GroupsUsersInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersInlineGridEditor.js';
import GroupsUsersFilteredGridPanel from '../components/Panel/Grid/GroupsUsersFilteredGrid.js';
import GroupsUsersFilteredGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredGridEditor.js';
import GroupsUsersFilteredSideGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredSideGridEditor.js';
import GroupsUsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/GroupsUsersFilteredInlineGridEditor.js';
import GroupsUsersEditor from '../components/Editor/GroupsUsersEditor.js';
import GroupsUsersEditorWindow from '../components/Window/GroupsUsersEditorWindow.js';
import GroupsUsersGridSideA from '../components/Grid/GroupsUsersGridSideA.js';
import GroupsUsersGridSideB from '../components/Grid/GroupsUsersGridSideB.js';
import GroupsUsersGridEditorSideA from '../components/Grid/GroupsUsersGridEditorSideA.js';
import GroupsUsersGridEditorSideB from '../components/Grid/GroupsUsersGridEditorSideB.js';
import GroupsUsersFilteredGridEditorSideA from '../components/Grid/GroupsUsersFilteredGridEditorSideA.js';
import GroupsUsersFilteredGridEditorSideB from '../components/Grid/GroupsUsersFilteredGridEditorSideB.js';
import GroupsUsersEditorWindowSideA from '../components/Window/GroupsUsersEditorWindowSideA.js';
import GroupsUsersEditorWindowSideB from '../components/Window/GroupsUsersEditorWindowSideB.js';
import GroupsUsersEditorSideA from '../components/Editor/GroupsUsersEditorSideA.js';
import GroupsUsersEditorSideB from '../components/Editor/GroupsUsersEditorSideB.js';
import ItemsCombo from '../components/Form/Field/Combo/ItemsCombo.js';
import ItemsComboEditor from '../components/Form/Field/Combo/ItemsComboEditor.js';
import ItemsTag from '../components/Form/Field/Tag/ItemsTag.js';
import ItemsTagEditor from '../components/Form/Field/Tag/ItemsTagEditor.js';
import ItemsGrid from '../components/Grid/ItemsGrid.js';
import ItemsGridEditor from '../components/Grid/ItemsGridEditor.js';
import ItemsSideGridEditor from '../components/Grid/ItemsSideGridEditor.js';
import ItemsInlineGridEditor from '../components/Grid/ItemsInlineGridEditor.js';
import ItemsFilteredGrid from '../components/Grid/ItemsFilteredGrid.js';
import ItemsFilteredGridEditor from '../components/Grid/ItemsFilteredGridEditor.js';
import ItemsFilteredSideGridEditor from '../components/Grid/ItemsFilteredSideGridEditor.js';
import ItemsFilteredInlineGridEditor from '../components/Grid/ItemsFilteredInlineGridEditor.js';
import ItemsGridPanel from '../components/Panel/Grid/ItemsGrid.js';
import ItemsGridEditorPanel from '../components/Panel/Grid/ItemsGridEditor.js';
import ItemsSideGridEditorPanel from '../components/Panel/Grid/ItemsSideGridEditor.js';
import ItemsInlineGridEditorPanel from '../components/Panel/Grid/ItemsInlineGridEditor.js';
import ItemsFilteredGridPanel from '../components/Panel/Grid/ItemsFilteredGrid.js';
import ItemsFilteredGridEditorPanel from '../components/Panel/Grid/ItemsFilteredGridEditor.js';
import ItemsFilteredSideGridEditorPanel from '../components/Panel/Grid/ItemsFilteredSideGridEditor.js';
import ItemsFilteredInlineGridEditorPanel from '../components/Panel/Grid/ItemsFilteredInlineGridEditor.js';
import ItemsEditor from '../components/Editor/ItemsEditor.js';
import ItemsEditorWindow from '../components/Window/ItemsEditorWindow.js';
import ItemsWorkOrdersCombo from '../components/Form/Field/Combo/ItemsWorkOrdersCombo.js';
import ItemsWorkOrdersComboEditor from '../components/Form/Field/Combo/ItemsWorkOrdersComboEditor.js';
import ItemsWorkOrdersTag from '../components/Form/Field/Tag/ItemsWorkOrdersTag.js';
import ItemsWorkOrdersTagEditor from '../components/Form/Field/Tag/ItemsWorkOrdersTagEditor.js';
import ItemsWorkOrdersGrid from '../components/Grid/ItemsWorkOrdersGrid.js';
import ItemsWorkOrdersGridEditor from '../components/Grid/ItemsWorkOrdersGridEditor.js';
import ItemsWorkOrdersSideGridEditor from '../components/Grid/ItemsWorkOrdersSideGridEditor.js';
import ItemsWorkOrdersInlineGridEditor from '../components/Grid/ItemsWorkOrdersInlineGridEditor.js';
import ItemsWorkOrdersFilteredGrid from '../components/Grid/ItemsWorkOrdersFilteredGrid.js';
import ItemsWorkOrdersFilteredGridEditor from '../components/Grid/ItemsWorkOrdersFilteredGridEditor.js';
import ItemsWorkOrdersFilteredSideGridEditor from '../components/Grid/ItemsWorkOrdersFilteredSideGridEditor.js';
import ItemsWorkOrdersFilteredInlineGridEditor from '../components/Grid/ItemsWorkOrdersFilteredInlineGridEditor.js';
import ItemsWorkOrdersGridPanel from '../components/Panel/Grid/ItemsWorkOrdersGrid.js';
import ItemsWorkOrdersGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersGridEditor.js';
import ItemsWorkOrdersSideGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersSideGridEditor.js';
import ItemsWorkOrdersInlineGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersInlineGridEditor.js';
import ItemsWorkOrdersFilteredGridPanel from '../components/Panel/Grid/ItemsWorkOrdersFilteredGrid.js';
import ItemsWorkOrdersFilteredGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersFilteredGridEditor.js';
import ItemsWorkOrdersFilteredSideGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersFilteredSideGridEditor.js';
import ItemsWorkOrdersFilteredInlineGridEditorPanel from '../components/Panel/Grid/ItemsWorkOrdersFilteredInlineGridEditor.js';
import ItemsWorkOrdersEditor from '../components/Editor/ItemsWorkOrdersEditor.js';
import ItemsWorkOrdersEditorWindow from '../components/Window/ItemsWorkOrdersEditorWindow.js';
import ItemsWorkOrdersGridSideA from '../components/Grid/ItemsWorkOrdersGridSideA.js';
import ItemsWorkOrdersGridSideB from '../components/Grid/ItemsWorkOrdersGridSideB.js';
import ItemsWorkOrdersGridEditorSideA from '../components/Grid/ItemsWorkOrdersGridEditorSideA.js';
import ItemsWorkOrdersGridEditorSideB from '../components/Grid/ItemsWorkOrdersGridEditorSideB.js';
import ItemsWorkOrdersFilteredGridEditorSideA from '../components/Grid/ItemsWorkOrdersFilteredGridEditorSideA.js';
import ItemsWorkOrdersFilteredGridEditorSideB from '../components/Grid/ItemsWorkOrdersFilteredGridEditorSideB.js';
import ItemsWorkOrdersEditorWindowSideA from '../components/Window/ItemsWorkOrdersEditorWindowSideA.js';
import ItemsWorkOrdersEditorWindowSideB from '../components/Window/ItemsWorkOrdersEditorWindowSideB.js';
import ItemsWorkOrdersEditorSideA from '../components/Editor/ItemsWorkOrdersEditorSideA.js';
import ItemsWorkOrdersEditorSideB from '../components/Editor/ItemsWorkOrdersEditorSideB.js';
import LotTypesCombo from '../components/Form/Field/Combo/LotTypesCombo.js';
import LotTypesComboEditor from '../components/Form/Field/Combo/LotTypesComboEditor.js';
import LotTypesTag from '../components/Form/Field/Tag/LotTypesTag.js';
import LotTypesTagEditor from '../components/Form/Field/Tag/LotTypesTagEditor.js';
import LotTypesGrid from '../components/Grid/LotTypesGrid.js';
import LotTypesGridEditor from '../components/Grid/LotTypesGridEditor.js';
import LotTypesSideGridEditor from '../components/Grid/LotTypesSideGridEditor.js';
import LotTypesInlineGridEditor from '../components/Grid/LotTypesInlineGridEditor.js';
import LotTypesFilteredGrid from '../components/Grid/LotTypesFilteredGrid.js';
import LotTypesFilteredGridEditor from '../components/Grid/LotTypesFilteredGridEditor.js';
import LotTypesFilteredSideGridEditor from '../components/Grid/LotTypesFilteredSideGridEditor.js';
import LotTypesFilteredInlineGridEditor from '../components/Grid/LotTypesFilteredInlineGridEditor.js';
import LotTypesGridPanel from '../components/Panel/Grid/LotTypesGrid.js';
import LotTypesGridEditorPanel from '../components/Panel/Grid/LotTypesGridEditor.js';
import LotTypesSideGridEditorPanel from '../components/Panel/Grid/LotTypesSideGridEditor.js';
import LotTypesInlineGridEditorPanel from '../components/Panel/Grid/LotTypesInlineGridEditor.js';
import LotTypesFilteredGridPanel from '../components/Panel/Grid/LotTypesFilteredGrid.js';
import LotTypesFilteredGridEditorPanel from '../components/Panel/Grid/LotTypesFilteredGridEditor.js';
import LotTypesFilteredSideGridEditorPanel from '../components/Panel/Grid/LotTypesFilteredSideGridEditor.js';
import LotTypesFilteredInlineGridEditorPanel from '../components/Panel/Grid/LotTypesFilteredInlineGridEditor.js';
import LotTypesEditor from '../components/Editor/LotTypesEditor.js';
import LotTypesEditorWindow from '../components/Window/LotTypesEditorWindow.js';
import NotesCombo from '../components/Form/Field/Combo/NotesCombo.js';
import NotesComboEditor from '../components/Form/Field/Combo/NotesComboEditor.js';
import NotesTag from '../components/Form/Field/Tag/NotesTag.js';
import NotesTagEditor from '../components/Form/Field/Tag/NotesTagEditor.js';
import NotesGrid from '../components/Grid/NotesGrid.js';
import NotesGridEditor from '../components/Grid/NotesGridEditor.js';
import NotesSideGridEditor from '../components/Grid/NotesSideGridEditor.js';
import NotesInlineGridEditor from '../components/Grid/NotesInlineGridEditor.js';
import NotesFilteredGrid from '../components/Grid/NotesFilteredGrid.js';
import NotesFilteredGridEditor from '../components/Grid/NotesFilteredGridEditor.js';
import NotesFilteredSideGridEditor from '../components/Grid/NotesFilteredSideGridEditor.js';
import NotesFilteredInlineGridEditor from '../components/Grid/NotesFilteredInlineGridEditor.js';
import NotesGridPanel from '../components/Panel/Grid/NotesGrid.js';
import NotesGridEditorPanel from '../components/Panel/Grid/NotesGridEditor.js';
import NotesSideGridEditorPanel from '../components/Panel/Grid/NotesSideGridEditor.js';
import NotesInlineGridEditorPanel from '../components/Panel/Grid/NotesInlineGridEditor.js';
import NotesFilteredGridPanel from '../components/Panel/Grid/NotesFilteredGrid.js';
import NotesFilteredGridEditorPanel from '../components/Panel/Grid/NotesFilteredGridEditor.js';
import NotesFilteredSideGridEditorPanel from '../components/Panel/Grid/NotesFilteredSideGridEditor.js';
import NotesFilteredInlineGridEditorPanel from '../components/Panel/Grid/NotesFilteredInlineGridEditor.js';
import NotesEditor from '../components/Editor/NotesEditor.js';
import NotesEditorWindow from '../components/Window/NotesEditorWindow.js';
import PaymentsCombo from '../components/Form/Field/Combo/PaymentsCombo.js';
import PaymentsComboEditor from '../components/Form/Field/Combo/PaymentsComboEditor.js';
import PaymentsTag from '../components/Form/Field/Tag/PaymentsTag.js';
import PaymentsTagEditor from '../components/Form/Field/Tag/PaymentsTagEditor.js';
import PaymentsGrid from '../components/Grid/PaymentsGrid.js';
import PaymentsGridEditor from '../components/Grid/PaymentsGridEditor.js';
import PaymentsSideGridEditor from '../components/Grid/PaymentsSideGridEditor.js';
import PaymentsInlineGridEditor from '../components/Grid/PaymentsInlineGridEditor.js';
import PaymentsFilteredGrid from '../components/Grid/PaymentsFilteredGrid.js';
import PaymentsFilteredGridEditor from '../components/Grid/PaymentsFilteredGridEditor.js';
import PaymentsFilteredSideGridEditor from '../components/Grid/PaymentsFilteredSideGridEditor.js';
import PaymentsFilteredInlineGridEditor from '../components/Grid/PaymentsFilteredInlineGridEditor.js';
import PaymentsGridPanel from '../components/Panel/Grid/PaymentsGrid.js';
import PaymentsGridEditorPanel from '../components/Panel/Grid/PaymentsGridEditor.js';
import PaymentsSideGridEditorPanel from '../components/Panel/Grid/PaymentsSideGridEditor.js';
import PaymentsInlineGridEditorPanel from '../components/Panel/Grid/PaymentsInlineGridEditor.js';
import PaymentsFilteredGridPanel from '../components/Panel/Grid/PaymentsFilteredGrid.js';
import PaymentsFilteredGridEditorPanel from '../components/Panel/Grid/PaymentsFilteredGridEditor.js';
import PaymentsFilteredSideGridEditorPanel from '../components/Panel/Grid/PaymentsFilteredSideGridEditor.js';
import PaymentsFilteredInlineGridEditorPanel from '../components/Panel/Grid/PaymentsFilteredInlineGridEditor.js';
import PaymentsEditor from '../components/Editor/PaymentsEditor.js';
import PaymentsEditorWindow from '../components/Window/PaymentsEditorWindow.js';
import PermissionsCombo from '../components/Form/Field/Combo/PermissionsCombo.js';
import PermissionsComboEditor from '../components/Form/Field/Combo/PermissionsComboEditor.js';
import PermissionsTag from '../components/Form/Field/Tag/PermissionsTag.js';
import PermissionsTagEditor from '../components/Form/Field/Tag/PermissionsTagEditor.js';
import PermissionsGrid from '../components/Grid/PermissionsGrid.js';
import PermissionsGridEditor from '../components/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditor from '../components/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditor from '../components/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGrid from '../components/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditor from '../components/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditor from '../components/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditor from '../components/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsGridPanel from '../components/Panel/Grid/PermissionsGrid.js';
import PermissionsGridEditorPanel from '../components/Panel/Grid/PermissionsGridEditor.js';
import PermissionsSideGridEditorPanel from '../components/Panel/Grid/PermissionsSideGridEditor.js';
import PermissionsInlineGridEditorPanel from '../components/Panel/Grid/PermissionsInlineGridEditor.js';
import PermissionsFilteredGridPanel from '../components/Panel/Grid/PermissionsFilteredGrid.js';
import PermissionsFilteredGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredGridEditor.js';
import PermissionsFilteredSideGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredSideGridEditor.js';
import PermissionsFilteredInlineGridEditorPanel from '../components/Panel/Grid/PermissionsFilteredInlineGridEditor.js';
import PermissionsEditor from '../components/Editor/PermissionsEditor.js';
import PermissionsEditorWindow from '../components/Window/PermissionsEditorWindow.js';
import TimeEntriesCombo from '../components/Form/Field/Combo/TimeEntriesCombo.js';
import TimeEntriesComboEditor from '../components/Form/Field/Combo/TimeEntriesComboEditor.js';
import TimeEntriesTag from '../components/Form/Field/Tag/TimeEntriesTag.js';
import TimeEntriesTagEditor from '../components/Form/Field/Tag/TimeEntriesTagEditor.js';
import TimeEntriesGrid from '../components/Grid/TimeEntriesGrid.js';
import TimeEntriesGridEditor from '../components/Grid/TimeEntriesGridEditor.js';
import TimeEntriesSideGridEditor from '../components/Grid/TimeEntriesSideGridEditor.js';
import TimeEntriesInlineGridEditor from '../components/Grid/TimeEntriesInlineGridEditor.js';
import TimeEntriesFilteredGrid from '../components/Grid/TimeEntriesFilteredGrid.js';
import TimeEntriesFilteredGridEditor from '../components/Grid/TimeEntriesFilteredGridEditor.js';
import TimeEntriesFilteredSideGridEditor from '../components/Grid/TimeEntriesFilteredSideGridEditor.js';
import TimeEntriesFilteredInlineGridEditor from '../components/Grid/TimeEntriesFilteredInlineGridEditor.js';
import TimeEntriesGridPanel from '../components/Panel/Grid/TimeEntriesGrid.js';
import TimeEntriesGridEditorPanel from '../components/Panel/Grid/TimeEntriesGridEditor.js';
import TimeEntriesSideGridEditorPanel from '../components/Panel/Grid/TimeEntriesSideGridEditor.js';
import TimeEntriesInlineGridEditorPanel from '../components/Panel/Grid/TimeEntriesInlineGridEditor.js';
import TimeEntriesFilteredGridPanel from '../components/Panel/Grid/TimeEntriesFilteredGrid.js';
import TimeEntriesFilteredGridEditorPanel from '../components/Panel/Grid/TimeEntriesFilteredGridEditor.js';
import TimeEntriesFilteredSideGridEditorPanel from '../components/Panel/Grid/TimeEntriesFilteredSideGridEditor.js';
import TimeEntriesFilteredInlineGridEditorPanel from '../components/Panel/Grid/TimeEntriesFilteredInlineGridEditor.js';
import TimeEntriesEditor from '../components/Editor/TimeEntriesEditor.js';
import TimeEntriesEditorWindow from '../components/Window/TimeEntriesEditorWindow.js';
import UsersCombo from '../components/Form/Field/Combo/UsersCombo.js';
import UsersComboEditor from '../components/Form/Field/Combo/UsersComboEditor.js';
import UsersTag from '../components/Form/Field/Tag/UsersTag.js';
import UsersTagEditor from '../components/Form/Field/Tag/UsersTagEditor.js';
import UsersGrid from '../components/Grid/UsersGrid.js';
import UsersGridEditor from '../components/Grid/UsersGridEditor.js';
import UsersSideGridEditor from '../components/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditor from '../components/Grid/UsersInlineGridEditor.js';
import UsersFilteredGrid from '../components/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditor from '../components/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditor from '../components/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditor from '../components/Grid/UsersFilteredInlineGridEditor.js';
import UsersGridPanel from '../components/Panel/Grid/UsersGrid.js';
import UsersGridEditorPanel from '../components/Panel/Grid/UsersGridEditor.js';
import UsersSideGridEditorPanel from '../components/Panel/Grid/UsersSideGridEditor.js';
import UsersInlineGridEditorPanel from '../components/Panel/Grid/UsersInlineGridEditor.js';
import UsersFilteredGridPanel from '../components/Panel/Grid/UsersFilteredGrid.js';
import UsersFilteredGridEditorPanel from '../components/Panel/Grid/UsersFilteredGridEditor.js';
import UsersFilteredSideGridEditorPanel from '../components/Panel/Grid/UsersFilteredSideGridEditor.js';
import UsersFilteredInlineGridEditorPanel from '../components/Panel/Grid/UsersFilteredInlineGridEditor.js';
import UsersEditor from '../components/Editor/UsersEditor.js';
import UsersEditorWindow from '../components/Window/UsersEditorWindow.js';
import WorkOrdersCombo from '../components/Form/Field/Combo/WorkOrdersCombo.js';
import WorkOrdersComboEditor from '../components/Form/Field/Combo/WorkOrdersComboEditor.js';
import WorkOrdersTag from '../components/Form/Field/Tag/WorkOrdersTag.js';
import WorkOrdersTagEditor from '../components/Form/Field/Tag/WorkOrdersTagEditor.js';
import WorkOrdersGrid from '../components/Grid/WorkOrdersGrid.js';
import WorkOrdersGridEditor from '../components/Grid/WorkOrdersGridEditor.js';
import WorkOrdersSideGridEditor from '../components/Grid/WorkOrdersSideGridEditor.js';
import WorkOrdersInlineGridEditor from '../components/Grid/WorkOrdersInlineGridEditor.js';
import WorkOrdersFilteredGrid from '../components/Grid/WorkOrdersFilteredGrid.js';
import WorkOrdersFilteredGridEditor from '../components/Grid/WorkOrdersFilteredGridEditor.js';
import WorkOrdersFilteredSideGridEditor from '../components/Grid/WorkOrdersFilteredSideGridEditor.js';
import WorkOrdersFilteredInlineGridEditor from '../components/Grid/WorkOrdersFilteredInlineGridEditor.js';
import WorkOrdersGridPanel from '../components/Panel/Grid/WorkOrdersGrid.js';
import WorkOrdersGridEditorPanel from '../components/Panel/Grid/WorkOrdersGridEditor.js';
import WorkOrdersSideGridEditorPanel from '../components/Panel/Grid/WorkOrdersSideGridEditor.js';
import WorkOrdersInlineGridEditorPanel from '../components/Panel/Grid/WorkOrdersInlineGridEditor.js';
import WorkOrdersFilteredGridPanel from '../components/Panel/Grid/WorkOrdersFilteredGrid.js';
import WorkOrdersFilteredGridEditorPanel from '../components/Panel/Grid/WorkOrdersFilteredGridEditor.js';
import WorkOrdersFilteredSideGridEditorPanel from '../components/Panel/Grid/WorkOrdersFilteredSideGridEditor.js';
import WorkOrdersFilteredInlineGridEditorPanel from '../components/Panel/Grid/WorkOrdersFilteredInlineGridEditor.js';
import WorkOrdersEditor from '../components/Editor/WorkOrdersEditor.js';
import WorkOrdersEditorWindow from '../components/Window/WorkOrdersEditorWindow.js';
import WorkOrderStatusesCombo from '../components/Form/Field/Combo/WorkOrderStatusesCombo.js';
import WorkOrderStatusesComboEditor from '../components/Form/Field/Combo/WorkOrderStatusesComboEditor.js';
import WorkOrderStatusesTag from '../components/Form/Field/Tag/WorkOrderStatusesTag.js';
import WorkOrderStatusesTagEditor from '../components/Form/Field/Tag/WorkOrderStatusesTagEditor.js';
import WorkOrderStatusesGrid from '../components/Grid/WorkOrderStatusesGrid.js';
import WorkOrderStatusesGridEditor from '../components/Grid/WorkOrderStatusesGridEditor.js';
import WorkOrderStatusesSideGridEditor from '../components/Grid/WorkOrderStatusesSideGridEditor.js';
import WorkOrderStatusesInlineGridEditor from '../components/Grid/WorkOrderStatusesInlineGridEditor.js';
import WorkOrderStatusesFilteredGrid from '../components/Grid/WorkOrderStatusesFilteredGrid.js';
import WorkOrderStatusesFilteredGridEditor from '../components/Grid/WorkOrderStatusesFilteredGridEditor.js';
import WorkOrderStatusesFilteredSideGridEditor from '../components/Grid/WorkOrderStatusesFilteredSideGridEditor.js';
import WorkOrderStatusesFilteredInlineGridEditor from '../components/Grid/WorkOrderStatusesFilteredInlineGridEditor.js';
import WorkOrderStatusesGridPanel from '../components/Panel/Grid/WorkOrderStatusesGrid.js';
import WorkOrderStatusesGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesGridEditor.js';
import WorkOrderStatusesSideGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesSideGridEditor.js';
import WorkOrderStatusesInlineGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesInlineGridEditor.js';
import WorkOrderStatusesFilteredGridPanel from '../components/Panel/Grid/WorkOrderStatusesFilteredGrid.js';
import WorkOrderStatusesFilteredGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesFilteredGridEditor.js';
import WorkOrderStatusesFilteredSideGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesFilteredSideGridEditor.js';
import WorkOrderStatusesFilteredInlineGridEditorPanel from '../components/Panel/Grid/WorkOrderStatusesFilteredInlineGridEditor.js';
import WorkOrderStatusesEditor from '../components/Editor/WorkOrderStatusesEditor.js';
import WorkOrderStatusesEditorWindow from '../components/Window/WorkOrderStatusesEditorWindow.js';
import WorkOrderTypesCombo from '../components/Form/Field/Combo/WorkOrderTypesCombo.js';
import WorkOrderTypesComboEditor from '../components/Form/Field/Combo/WorkOrderTypesComboEditor.js';
import WorkOrderTypesTag from '../components/Form/Field/Tag/WorkOrderTypesTag.js';
import WorkOrderTypesTagEditor from '../components/Form/Field/Tag/WorkOrderTypesTagEditor.js';
import WorkOrderTypesGrid from '../components/Grid/WorkOrderTypesGrid.js';
import WorkOrderTypesGridEditor from '../components/Grid/WorkOrderTypesGridEditor.js';
import WorkOrderTypesSideGridEditor from '../components/Grid/WorkOrderTypesSideGridEditor.js';
import WorkOrderTypesInlineGridEditor from '../components/Grid/WorkOrderTypesInlineGridEditor.js';
import WorkOrderTypesFilteredGrid from '../components/Grid/WorkOrderTypesFilteredGrid.js';
import WorkOrderTypesFilteredGridEditor from '../components/Grid/WorkOrderTypesFilteredGridEditor.js';
import WorkOrderTypesFilteredSideGridEditor from '../components/Grid/WorkOrderTypesFilteredSideGridEditor.js';
import WorkOrderTypesFilteredInlineGridEditor from '../components/Grid/WorkOrderTypesFilteredInlineGridEditor.js';
import WorkOrderTypesGridPanel from '../components/Panel/Grid/WorkOrderTypesGrid.js';
import WorkOrderTypesGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesGridEditor.js';
import WorkOrderTypesSideGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesSideGridEditor.js';
import WorkOrderTypesInlineGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesInlineGridEditor.js';
import WorkOrderTypesFilteredGridPanel from '../components/Panel/Grid/WorkOrderTypesFilteredGrid.js';
import WorkOrderTypesFilteredGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesFilteredGridEditor.js';
import WorkOrderTypesFilteredSideGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesFilteredSideGridEditor.js';
import WorkOrderTypesFilteredInlineGridEditorPanel from '../components/Panel/Grid/WorkOrderTypesFilteredInlineGridEditor.js';
import WorkOrderTypesEditor from '../components/Editor/WorkOrderTypesEditor.js';
import WorkOrderTypesEditorWindow from '../components/Window/WorkOrderTypesEditorWindow.js';

export default function registerComponentsFn() {

	registerComponents({
		AttributesCombo,
		AttributesComboEditor,
		AttributesTag,
		AttributesTagEditor,
		AttributesGrid,
		AttributesGridEditor,
		AttributesSideGridEditor,
		AttributesInlineGridEditor,
		AttributesFilteredGrid,
		AttributesFilteredGridEditor,
		AttributesFilteredSideGridEditor,
		AttributesFilteredInlineGridEditor,
		AttributesGridPanel,
		AttributesGridEditorPanel,
		AttributesSideGridEditorPanel,
		AttributesInlineGridEditorPanel,
		AttributesFilteredGridPanel,
		AttributesFilteredGridEditorPanel,
		AttributesFilteredSideGridEditorPanel,
		AttributesFilteredInlineGridEditorPanel,
		AttributesEditor,
		AttributesEditorWindow,
		AttributesWorkOrdersCombo,
		AttributesWorkOrdersComboEditor,
		AttributesWorkOrdersTag,
		AttributesWorkOrdersTagEditor,
		AttributesWorkOrdersGrid,
		AttributesWorkOrdersGridEditor,
		AttributesWorkOrdersSideGridEditor,
		AttributesWorkOrdersInlineGridEditor,
		AttributesWorkOrdersFilteredGrid,
		AttributesWorkOrdersFilteredGridEditor,
		AttributesWorkOrdersFilteredSideGridEditor,
		AttributesWorkOrdersFilteredInlineGridEditor,
		AttributesWorkOrdersGridPanel,
		AttributesWorkOrdersGridEditorPanel,
		AttributesWorkOrdersSideGridEditorPanel,
		AttributesWorkOrdersInlineGridEditorPanel,
		AttributesWorkOrdersFilteredGridPanel,
		AttributesWorkOrdersFilteredGridEditorPanel,
		AttributesWorkOrdersFilteredSideGridEditorPanel,
		AttributesWorkOrdersFilteredInlineGridEditorPanel,
		AttributesWorkOrdersEditor,
		AttributesWorkOrdersEditorWindow,
		AttributesWorkOrdersGridSideA,
		AttributesWorkOrdersGridSideB,
		AttributesWorkOrdersGridEditorSideA,
		AttributesWorkOrdersGridEditorSideB,
		AttributesWorkOrdersFilteredGridEditorSideA,
		AttributesWorkOrdersFilteredGridEditorSideB,
		AttributesWorkOrdersEditorWindowSideA,
		AttributesWorkOrdersEditorWindowSideB,
		AttributesWorkOrdersEditorSideA,
		AttributesWorkOrdersEditorSideB,
		ClientsCombo,
		ClientsComboEditor,
		ClientsTag,
		ClientsTagEditor,
		ClientsGrid,
		ClientsGridEditor,
		ClientsSideGridEditor,
		ClientsInlineGridEditor,
		ClientsFilteredGrid,
		ClientsFilteredGridEditor,
		ClientsFilteredSideGridEditor,
		ClientsFilteredInlineGridEditor,
		ClientsGridPanel,
		ClientsGridEditorPanel,
		ClientsSideGridEditorPanel,
		ClientsInlineGridEditorPanel,
		ClientsFilteredGridPanel,
		ClientsFilteredGridEditorPanel,
		ClientsFilteredSideGridEditorPanel,
		ClientsFilteredInlineGridEditorPanel,
		ClientsEditor,
		ClientsEditorWindow,
		CrewsCombo,
		CrewsComboEditor,
		CrewsTag,
		CrewsTagEditor,
		CrewsGrid,
		CrewsGridEditor,
		CrewsSideGridEditor,
		CrewsInlineGridEditor,
		CrewsFilteredGrid,
		CrewsFilteredGridEditor,
		CrewsFilteredSideGridEditor,
		CrewsFilteredInlineGridEditor,
		CrewsGridPanel,
		CrewsGridEditorPanel,
		CrewsSideGridEditorPanel,
		CrewsInlineGridEditorPanel,
		CrewsFilteredGridPanel,
		CrewsFilteredGridEditorPanel,
		CrewsFilteredSideGridEditorPanel,
		CrewsFilteredInlineGridEditorPanel,
		CrewsEditor,
		CrewsEditorWindow,
		CrewsWorkOrdersCombo,
		CrewsWorkOrdersComboEditor,
		CrewsWorkOrdersTag,
		CrewsWorkOrdersTagEditor,
		CrewsWorkOrdersGrid,
		CrewsWorkOrdersGridEditor,
		CrewsWorkOrdersSideGridEditor,
		CrewsWorkOrdersInlineGridEditor,
		CrewsWorkOrdersFilteredGrid,
		CrewsWorkOrdersFilteredGridEditor,
		CrewsWorkOrdersFilteredSideGridEditor,
		CrewsWorkOrdersFilteredInlineGridEditor,
		CrewsWorkOrdersGridPanel,
		CrewsWorkOrdersGridEditorPanel,
		CrewsWorkOrdersSideGridEditorPanel,
		CrewsWorkOrdersInlineGridEditorPanel,
		CrewsWorkOrdersFilteredGridPanel,
		CrewsWorkOrdersFilteredGridEditorPanel,
		CrewsWorkOrdersFilteredSideGridEditorPanel,
		CrewsWorkOrdersFilteredInlineGridEditorPanel,
		CrewsWorkOrdersEditor,
		CrewsWorkOrdersEditorWindow,
		CrewsWorkOrdersGridSideA,
		CrewsWorkOrdersGridSideB,
		CrewsWorkOrdersGridEditorSideA,
		CrewsWorkOrdersGridEditorSideB,
		CrewsWorkOrdersFilteredGridEditorSideA,
		CrewsWorkOrdersFilteredGridEditorSideB,
		CrewsWorkOrdersEditorWindowSideA,
		CrewsWorkOrdersEditorWindowSideB,
		CrewsWorkOrdersEditorSideA,
		CrewsWorkOrdersEditorSideB,
		DiscountsCombo,
		DiscountsComboEditor,
		DiscountsTag,
		DiscountsTagEditor,
		DiscountsGrid,
		DiscountsGridEditor,
		DiscountsSideGridEditor,
		DiscountsInlineGridEditor,
		DiscountsFilteredGrid,
		DiscountsFilteredGridEditor,
		DiscountsFilteredSideGridEditor,
		DiscountsFilteredInlineGridEditor,
		DiscountsGridPanel,
		DiscountsGridEditorPanel,
		DiscountsSideGridEditorPanel,
		DiscountsInlineGridEditorPanel,
		DiscountsFilteredGridPanel,
		DiscountsFilteredGridEditorPanel,
		DiscountsFilteredSideGridEditorPanel,
		DiscountsFilteredInlineGridEditorPanel,
		DiscountsEditor,
		DiscountsEditorWindow,
		GroupsCombo,
		GroupsComboEditor,
		GroupsTag,
		GroupsTagEditor,
		GroupsGrid,
		GroupsGridEditor,
		GroupsSideGridEditor,
		GroupsInlineGridEditor,
		GroupsFilteredGrid,
		GroupsFilteredGridEditor,
		GroupsFilteredSideGridEditor,
		GroupsFilteredInlineGridEditor,
		GroupsGridPanel,
		GroupsGridEditorPanel,
		GroupsSideGridEditorPanel,
		GroupsInlineGridEditorPanel,
		GroupsFilteredGridPanel,
		GroupsFilteredGridEditorPanel,
		GroupsFilteredSideGridEditorPanel,
		GroupsFilteredInlineGridEditorPanel,
		GroupsEditor,
		GroupsEditorWindow,
		GroupsUsersCombo,
		GroupsUsersComboEditor,
		GroupsUsersTag,
		GroupsUsersTagEditor,
		GroupsUsersGrid,
		GroupsUsersGridEditor,
		GroupsUsersSideGridEditor,
		GroupsUsersInlineGridEditor,
		GroupsUsersFilteredGrid,
		GroupsUsersFilteredGridEditor,
		GroupsUsersFilteredSideGridEditor,
		GroupsUsersFilteredInlineGridEditor,
		GroupsUsersGridPanel,
		GroupsUsersGridEditorPanel,
		GroupsUsersSideGridEditorPanel,
		GroupsUsersInlineGridEditorPanel,
		GroupsUsersFilteredGridPanel,
		GroupsUsersFilteredGridEditorPanel,
		GroupsUsersFilteredSideGridEditorPanel,
		GroupsUsersFilteredInlineGridEditorPanel,
		GroupsUsersEditor,
		GroupsUsersEditorWindow,
		GroupsUsersGridSideA,
		GroupsUsersGridSideB,
		GroupsUsersGridEditorSideA,
		GroupsUsersGridEditorSideB,
		GroupsUsersFilteredGridEditorSideA,
		GroupsUsersFilteredGridEditorSideB,
		GroupsUsersEditorWindowSideA,
		GroupsUsersEditorWindowSideB,
		GroupsUsersEditorSideA,
		GroupsUsersEditorSideB,
		ItemsCombo,
		ItemsComboEditor,
		ItemsTag,
		ItemsTagEditor,
		ItemsGrid,
		ItemsGridEditor,
		ItemsSideGridEditor,
		ItemsInlineGridEditor,
		ItemsFilteredGrid,
		ItemsFilteredGridEditor,
		ItemsFilteredSideGridEditor,
		ItemsFilteredInlineGridEditor,
		ItemsGridPanel,
		ItemsGridEditorPanel,
		ItemsSideGridEditorPanel,
		ItemsInlineGridEditorPanel,
		ItemsFilteredGridPanel,
		ItemsFilteredGridEditorPanel,
		ItemsFilteredSideGridEditorPanel,
		ItemsFilteredInlineGridEditorPanel,
		ItemsEditor,
		ItemsEditorWindow,
		ItemsWorkOrdersCombo,
		ItemsWorkOrdersComboEditor,
		ItemsWorkOrdersTag,
		ItemsWorkOrdersTagEditor,
		ItemsWorkOrdersGrid,
		ItemsWorkOrdersGridEditor,
		ItemsWorkOrdersSideGridEditor,
		ItemsWorkOrdersInlineGridEditor,
		ItemsWorkOrdersFilteredGrid,
		ItemsWorkOrdersFilteredGridEditor,
		ItemsWorkOrdersFilteredSideGridEditor,
		ItemsWorkOrdersFilteredInlineGridEditor,
		ItemsWorkOrdersGridPanel,
		ItemsWorkOrdersGridEditorPanel,
		ItemsWorkOrdersSideGridEditorPanel,
		ItemsWorkOrdersInlineGridEditorPanel,
		ItemsWorkOrdersFilteredGridPanel,
		ItemsWorkOrdersFilteredGridEditorPanel,
		ItemsWorkOrdersFilteredSideGridEditorPanel,
		ItemsWorkOrdersFilteredInlineGridEditorPanel,
		ItemsWorkOrdersEditor,
		ItemsWorkOrdersEditorWindow,
		ItemsWorkOrdersGridSideA,
		ItemsWorkOrdersGridSideB,
		ItemsWorkOrdersGridEditorSideA,
		ItemsWorkOrdersGridEditorSideB,
		ItemsWorkOrdersFilteredGridEditorSideA,
		ItemsWorkOrdersFilteredGridEditorSideB,
		ItemsWorkOrdersEditorWindowSideA,
		ItemsWorkOrdersEditorWindowSideB,
		ItemsWorkOrdersEditorSideA,
		ItemsWorkOrdersEditorSideB,
		LotTypesCombo,
		LotTypesComboEditor,
		LotTypesTag,
		LotTypesTagEditor,
		LotTypesGrid,
		LotTypesGridEditor,
		LotTypesSideGridEditor,
		LotTypesInlineGridEditor,
		LotTypesFilteredGrid,
		LotTypesFilteredGridEditor,
		LotTypesFilteredSideGridEditor,
		LotTypesFilteredInlineGridEditor,
		LotTypesGridPanel,
		LotTypesGridEditorPanel,
		LotTypesSideGridEditorPanel,
		LotTypesInlineGridEditorPanel,
		LotTypesFilteredGridPanel,
		LotTypesFilteredGridEditorPanel,
		LotTypesFilteredSideGridEditorPanel,
		LotTypesFilteredInlineGridEditorPanel,
		LotTypesEditor,
		LotTypesEditorWindow,
		NotesCombo,
		NotesComboEditor,
		NotesTag,
		NotesTagEditor,
		NotesGrid,
		NotesGridEditor,
		NotesSideGridEditor,
		NotesInlineGridEditor,
		NotesFilteredGrid,
		NotesFilteredGridEditor,
		NotesFilteredSideGridEditor,
		NotesFilteredInlineGridEditor,
		NotesGridPanel,
		NotesGridEditorPanel,
		NotesSideGridEditorPanel,
		NotesInlineGridEditorPanel,
		NotesFilteredGridPanel,
		NotesFilteredGridEditorPanel,
		NotesFilteredSideGridEditorPanel,
		NotesFilteredInlineGridEditorPanel,
		NotesEditor,
		NotesEditorWindow,
		PaymentsCombo,
		PaymentsComboEditor,
		PaymentsTag,
		PaymentsTagEditor,
		PaymentsGrid,
		PaymentsGridEditor,
		PaymentsSideGridEditor,
		PaymentsInlineGridEditor,
		PaymentsFilteredGrid,
		PaymentsFilteredGridEditor,
		PaymentsFilteredSideGridEditor,
		PaymentsFilteredInlineGridEditor,
		PaymentsGridPanel,
		PaymentsGridEditorPanel,
		PaymentsSideGridEditorPanel,
		PaymentsInlineGridEditorPanel,
		PaymentsFilteredGridPanel,
		PaymentsFilteredGridEditorPanel,
		PaymentsFilteredSideGridEditorPanel,
		PaymentsFilteredInlineGridEditorPanel,
		PaymentsEditor,
		PaymentsEditorWindow,
		PermissionsCombo,
		PermissionsComboEditor,
		PermissionsTag,
		PermissionsTagEditor,
		PermissionsGrid,
		PermissionsGridEditor,
		PermissionsSideGridEditor,
		PermissionsInlineGridEditor,
		PermissionsFilteredGrid,
		PermissionsFilteredGridEditor,
		PermissionsFilteredSideGridEditor,
		PermissionsFilteredInlineGridEditor,
		PermissionsGridPanel,
		PermissionsGridEditorPanel,
		PermissionsSideGridEditorPanel,
		PermissionsInlineGridEditorPanel,
		PermissionsFilteredGridPanel,
		PermissionsFilteredGridEditorPanel,
		PermissionsFilteredSideGridEditorPanel,
		PermissionsFilteredInlineGridEditorPanel,
		PermissionsEditor,
		PermissionsEditorWindow,
		TimeEntriesCombo,
		TimeEntriesComboEditor,
		TimeEntriesTag,
		TimeEntriesTagEditor,
		TimeEntriesGrid,
		TimeEntriesGridEditor,
		TimeEntriesSideGridEditor,
		TimeEntriesInlineGridEditor,
		TimeEntriesFilteredGrid,
		TimeEntriesFilteredGridEditor,
		TimeEntriesFilteredSideGridEditor,
		TimeEntriesFilteredInlineGridEditor,
		TimeEntriesGridPanel,
		TimeEntriesGridEditorPanel,
		TimeEntriesSideGridEditorPanel,
		TimeEntriesInlineGridEditorPanel,
		TimeEntriesFilteredGridPanel,
		TimeEntriesFilteredGridEditorPanel,
		TimeEntriesFilteredSideGridEditorPanel,
		TimeEntriesFilteredInlineGridEditorPanel,
		TimeEntriesEditor,
		TimeEntriesEditorWindow,
		UsersCombo,
		UsersComboEditor,
		UsersTag,
		UsersTagEditor,
		UsersGrid,
		UsersGridEditor,
		UsersSideGridEditor,
		UsersInlineGridEditor,
		UsersFilteredGrid,
		UsersFilteredGridEditor,
		UsersFilteredSideGridEditor,
		UsersFilteredInlineGridEditor,
		UsersGridPanel,
		UsersGridEditorPanel,
		UsersSideGridEditorPanel,
		UsersInlineGridEditorPanel,
		UsersFilteredGridPanel,
		UsersFilteredGridEditorPanel,
		UsersFilteredSideGridEditorPanel,
		UsersFilteredInlineGridEditorPanel,
		UsersEditor,
		UsersEditorWindow,
		WorkOrdersCombo,
		WorkOrdersComboEditor,
		WorkOrdersTag,
		WorkOrdersTagEditor,
		WorkOrdersGrid,
		WorkOrdersGridEditor,
		WorkOrdersSideGridEditor,
		WorkOrdersInlineGridEditor,
		WorkOrdersFilteredGrid,
		WorkOrdersFilteredGridEditor,
		WorkOrdersFilteredSideGridEditor,
		WorkOrdersFilteredInlineGridEditor,
		WorkOrdersGridPanel,
		WorkOrdersGridEditorPanel,
		WorkOrdersSideGridEditorPanel,
		WorkOrdersInlineGridEditorPanel,
		WorkOrdersFilteredGridPanel,
		WorkOrdersFilteredGridEditorPanel,
		WorkOrdersFilteredSideGridEditorPanel,
		WorkOrdersFilteredInlineGridEditorPanel,
		WorkOrdersEditor,
		WorkOrdersEditorWindow,
		WorkOrderStatusesCombo,
		WorkOrderStatusesComboEditor,
		WorkOrderStatusesTag,
		WorkOrderStatusesTagEditor,
		WorkOrderStatusesGrid,
		WorkOrderStatusesGridEditor,
		WorkOrderStatusesSideGridEditor,
		WorkOrderStatusesInlineGridEditor,
		WorkOrderStatusesFilteredGrid,
		WorkOrderStatusesFilteredGridEditor,
		WorkOrderStatusesFilteredSideGridEditor,
		WorkOrderStatusesFilteredInlineGridEditor,
		WorkOrderStatusesGridPanel,
		WorkOrderStatusesGridEditorPanel,
		WorkOrderStatusesSideGridEditorPanel,
		WorkOrderStatusesInlineGridEditorPanel,
		WorkOrderStatusesFilteredGridPanel,
		WorkOrderStatusesFilteredGridEditorPanel,
		WorkOrderStatusesFilteredSideGridEditorPanel,
		WorkOrderStatusesFilteredInlineGridEditorPanel,
		WorkOrderStatusesEditor,
		WorkOrderStatusesEditorWindow,
		WorkOrderTypesCombo,
		WorkOrderTypesComboEditor,
		WorkOrderTypesTag,
		WorkOrderTypesTagEditor,
		WorkOrderTypesGrid,
		WorkOrderTypesGridEditor,
		WorkOrderTypesSideGridEditor,
		WorkOrderTypesInlineGridEditor,
		WorkOrderTypesFilteredGrid,
		WorkOrderTypesFilteredGridEditor,
		WorkOrderTypesFilteredSideGridEditor,
		WorkOrderTypesFilteredInlineGridEditor,
		WorkOrderTypesGridPanel,
		WorkOrderTypesGridEditorPanel,
		WorkOrderTypesSideGridEditorPanel,
		WorkOrderTypesInlineGridEditorPanel,
		WorkOrderTypesFilteredGridPanel,
		WorkOrderTypesFilteredGridEditorPanel,
		WorkOrderTypesFilteredSideGridEditorPanel,
		WorkOrderTypesFilteredInlineGridEditorPanel,
		WorkOrderTypesEditor,
		WorkOrderTypesEditorWindow
	});
	
}
