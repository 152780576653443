/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WorkOrderStatusesGrid from './WorkOrderStatusesGrid.js';

export default function WorkOrderStatusesFilteredGrid(props) {
	return <WorkOrderStatusesGrid
				reference="WorkOrderStatusesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}