/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';

export default function WorkOrdersFilteredGridPanel(props) {
	return <GridPanel
				reference="WorkOrdersFilteredGridPanel"
				model="WorkOrders"
				useFilters={true}
				columnsConfig={[
				    {
				        "header": "UUID",
				        "fieldName": "work_orders__uuid",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Work Order #",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Case Number",
				        "fieldName": "work_orders__case_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Inspector",
				        "fieldName": "work_orders__inspector",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Status",
				        "fieldName": "work_order_statuses__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Client",
				        "fieldName": "clients__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Type",
				        "fieldName": "work_order_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Lot Type",
				        "fieldName": "lot_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Address Number",
				        "fieldName": "work_orders__address_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Address Direction",
				        "fieldName": "work_orders__address_direction",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Address Street",
				        "fieldName": "work_orders__address_street",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Work Desc",
				        "fieldName": "work_orders__work_description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Legal Desc",
				        "fieldName": "work_orders__legal_description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Tax ID Number",
				        "fieldName": "work_orders__tax_id_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Items",
				        "fieldName": "work_orders__items",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Attributes",
				        "fieldName": "work_orders__attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Assigned Crew",
				        "fieldName": "work_orders__crew",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Has Attachments",
				        "fieldName": "work_orders__has_attachments",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Has Notes",
				        "fieldName": "work_orders__has_notes",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Has Images",
				        "fieldName": "work_orders__has_images",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Estimate",
				        "fieldName": "work_orders__estimate",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Total",
				        "fieldName": "work_orders__total",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Amount Due",
				        "fieldName": "work_orders__amount_due",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Is Bid?",
				        "fieldName": "work_orders__is_bid",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Is Billed?",
				        "fieldName": "work_orders__is_billed",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Is Bid Approved?",
				        "fieldName": "work_orders__is_bid_approved",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "User",
				        "fieldName": "users__username",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "WO Date",
				        "fieldName": "work_orders__date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Assigned Date",
				        "fieldName": "work_orders__assigned_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Completed Date",
				        "fieldName": "work_orders__completed_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Completed Wk",
				        "fieldName": "work_orders__completed_week",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Invoiced Date",
				        "fieldName": "work_orders__invoiced_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Paid Date",
				        "fieldName": "work_orders__paid_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}