import { useRef, } from 'react';
import {
	Box,
} from 'native-base';
import {
	SELECTION_MODE_MULTI,
} from '@onehat/ui/src/Constants/Selection.js';
import {
	setIsWaitModalShown,
} from '../../models/Slices/DebugSlice';
import { useDispatch } from 'react-redux';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import XMark from '@onehat/ui/src/Components/Icons/Xmark.js';
import { WORK_ORDER_STATUS__ASSIGNED } from '../../constants/WorkOrderStatuses.js'
import CrewsGrid from './CrewsGrid.js';
import DashboardCrewsWorkOrdersGridEditor from './DashboardCrewsWorkOrdersGridEditor.js';
import _ from 'lodash';

function CrewAssignmentsGrid(props) {
	const
		{
			self,
			alert,
		} = props,
		dispatch = useDispatch(),
		cachedExpandedRowComponents = useRef({}),
		unassignAll = async (crew) => {
			dispatch(setIsWaitModalShown(true));

			const
				Crews = self.children.CrewsGrid.repository,
				UnassignedWorkOrdersGrid = self.parent.children.UnassignedWorkOrdersGrid,
				crew_id = crew.id,

				// Unassign all work orders via ajax
				params = {
					foreign_id: crew_id,
					toUnassign: 'Crews',
				},
				result = await Crews._send('POST', 'WorkOrders/unassignAll', params);
		
			const {
				root,
				success,
				total,
				message
			} = Crews._processServerResponse(result);

			if (!success) {
				return;
			}

			
			// Reload the UnassignedWorkOrdersGrid
			await UnassignedWorkOrdersGrid.repository.reload();
			
			// Reload the AssignedWorkOrdersGrid, if able
			if (cachedExpandedRowComponents.current[crew_id]?.grid) {
				await cachedExpandedRowComponents.current[crew_id].grid.repository.reload();
			}

			dispatch(setIsWaitModalShown(false));
		},
		onRowDrop = async (rowDroppedOn, droppedItem, crew) => {
			dispatch(setIsWaitModalShown(true));

			const Crews = self.children.CrewsGrid.repository;

			if (rowDroppedOn.isDestroyed) {
				const crew_id = crew?.id || rowDroppedOn.id;
				// FIX rowDroppedOn
				// Currently, line 492 of Grid.js is getting stale
				// get refreshed rowDroppedOn
				if (crew) {
					// dropped on a CrewsWorkOrders row
					const CrewsWorkOrders = self.children['CrewsWorkOrdersGridForCrew' + crew_id].repository;
					rowDroppedOn = CrewsWorkOrders.getById(rowDroppedOn.id);
				} else {
					// Dropped on a Crews row
					rowDroppedOn = Crews.getById(rowDroppedOn.id);
				}
			}

			switch(droppedItem.type) {
				case 'UnassignedWorkOrders': { // use brackets so we restrict scope	
					// user dragged a workOrder from UnassignedWorkOrdersGrid to a Crew grid or row
					const {
							id, // dragged item
							getSelection,
							type,
						} = droppedItem,
						ids = _.map(getSelection(), (item) => item.id),
						dropped_ids = !_.isEmpty(ids) ? ids : id,
						crew_id = crew?.id || rowDroppedOn.id, // crew in case drop was on to a Crew
						UnassignedWorkOrdersGrid = self.parent.children.UnassignedWorkOrdersGrid,
				
						// Assign this work order to the crew via ajax
						params = {
							this_ids: dropped_ids,
							foreign_id: crew_id,
							dropPosition: 'after', // for backwards compatibility
							dropRecord_id: crew ? rowDroppedOn.id : null, // If there is a crew, then this row is a CrewsWorkOrder
							toAssign: 'Crews',
							allowOnlyOneAssignment: true,
						},
						result = await Crews._send('POST', 'WorkOrders/assign', params);

					const {
						root,
						success,
						total,
						message
					} = Crews._processServerResponse(result);

					if (!success) {
						return;
					}
					
					// Reload the UnassignedWorkOrdersGrid
					await  UnassignedWorkOrdersGrid.repository.reload();
			
					// Reload the AssignedWorkOrdersGrid, if able
					if (cachedExpandedRowComponents.current[crew_id]?.grid) {
						await  cachedExpandedRowComponents.current[crew_id].grid.repository.reload();
					}

					break;
				}
				case 'AssignedWorkOrders': {
					// user dragged a CrewsWorkOrder from one Crew grid to itself or another
					const {
							id, // dragged crews_work_order_id
							getSelection,
							crew_id, // dragged crew_id
							work_order_id, // dragged work_order_id
						} = droppedItem,
						// dropped_ids = !_.isEmpty(ids) ? ids : work_order_id,
						selection = getSelection(),
						oldCrew_id = crew_id;
					let newCrew_id,
						dropRecord_id = null;

					if (rowDroppedOn.schema.name === 'Crews') {
						// row dropped on was a Crew
						newCrew_id = rowDroppedOn.id;

					} else if (rowDroppedOn.schema.name === 'CrewsWorkOrders') {
						// row dropped on was a CrewsWorkOrder
						newCrew_id = rowDroppedOn.crews_work_orders__crew_id;
						dropRecord_id = rowDroppedOn.crews_work_orders__id;

					}
					if (newCrew_id === oldCrew_id) {
						alert('Record must be unassigned or dragged to a different Crew.');
						return;
					}
					const
						// Assign this work order to the crew via ajax
						workOrderIds = _.map(selection, (item) => item.crews_work_orders__work_order_id),
						ids = workOrderIds.length === 0 ? work_order_id : workOrderIds,
						params = {
							this_ids: ids,
							foreign_id: newCrew_id,
							dropPosition: 'after', // for backwards compatibility
							dropRecord_id,
							toAssign: 'Crews',
							allowOnlyOneAssignment: true,
						},
						result = await Crews._send('POST', 'WorkOrders/assign', params);

					const {
						root,
						success,
						total,
						message
					} = Crews._processServerResponse(result);

					if (!success) {
						return;
					}
					
			
					// Reload the AssignedWorkOrdersGrids, if able
					if (cachedExpandedRowComponents.current[oldCrew_id]?.grid) {
						await cachedExpandedRowComponents.current[oldCrew_id].grid.repository.reload();
					}
					if (cachedExpandedRowComponents.current[newCrew_id]?.grid) {
						await cachedExpandedRowComponents.current[newCrew_id].grid.repository.reload();
					}



					break;
				}
			}

			dispatch(setIsWaitModalShown(false));
		};

	self.cachedExpandedRowComponents = cachedExpandedRowComponents;

	return <CrewsGrid
				reference="CrewsGrid"
				parent={self}
				areRowsDropTarget={true}
				dropTargetAccept={[
					'UnassignedWorkOrders',
					'AssignedWorkOrders',
				]}
				onRowDrop={onRowDrop}
				baseParams={{
					'conditions[crews__is_active]': true,
				}}
				showRowExpander={true}
				getExpandedRowContent={(row) => {
					const {
							item,
							index,
						} = row,
						crew = item;
					if (!cachedExpandedRowComponents.current[crew.id]) {
						// if the cached component doesn't exist, create it and cache it
						const component = <Box
												h='300px'
												mb={10}
												pl={8}
											>
												<DashboardCrewsWorkOrdersGridEditor
													reference={'CrewsWorkOrdersGridForCrew' + crew.id}
													parent={self}
													title={crew.name}
													baseParams={{
														'conditions[crews_work_orders__crew_id]': crew.id,
														'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__ASSIGNED,
													}}
													onRender={(self) => {
														// register the grid's self so we can access it later
														cachedExpandedRowComponents.current[crew.id].grid = self;
													}}
													areRowsDropTarget={true}
													dropTargetAccept={[
														'UnassignedWorkOrders',
														'AssignedWorkOrders',
													]}
													onRowDrop={(rowEntity, droppedItem) => onRowDrop(rowEntity, droppedItem, crew)}
													canRowsReorder={true}
													areRowsDragSource={true}
													rowDragSourceType="AssignedWorkOrders"
													getRowDragSourceItem={(item, getSelection, type) => {
														return {
															id: item.id, // dragged item
															crew_id: item.crews_work_orders__crew_id, // dragged crew_id
															work_order_id: item.crews_work_orders__work_order_id, // dragged work_order_id
															getSelection,
															type,
														};
													}}
													columnsConfig={[
														{
															header: '#',
															"fieldName": "crews_work_orders__display_order",
															"sortable": true,
															"isEditable": false,
															"reorderable": false,
															"resizable": false,
															w: '50px',
														},
														// {
														// 	"header": "Address",
														// 	"fieldName": "work_orders__address",
														// 	"sortable": false,
														// 	"isEditable": false,
														// 	"reorderable": false,
														// 	"resizable": false,
														// 	"w": 150
														// },
														{
															"header": "#",
															"fieldName": "work_orders__address_number",
															"sortable": true,
															"isEditable": true,
															"reorderable": true,
															"resizable": true,
															"w": 50
														},
														{
															"header": "Dir",
															"fieldName": "work_orders__address_direction",
															"sortable": true,
															"isEditable": true,
															"reorderable": true,
															"resizable": true,
															"w": '40px'
														},
														{
															"header": "Street",
															"fieldName": "work_orders__address_street",
															"sortable": true,
															"isEditable": true,
															"reorderable": true,
															"resizable": true,
															"w": 150
														},
														{
															"header": "Work Order",
															"fieldName": "work_orders__work_order_number",
															"sortable": false,
															"isEditable": false,
															"reorderable": false,
															"resizable": false,
															w: 120,
														},
													]}
												/>
											</Box>;
						cachedExpandedRowComponents.current[crew.id] = {
							component,
							grid: null,
						};
					}
					return cachedExpandedRowComponents.current[crew.id].component;
				}}
				columnsConfig={[
				    {
				        "header": "Crew Name",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": false,
				        "resizable": false,
				        // "w": 150
						flex: 1,
				    },
					{
						header: 'Unassign All',
						w: 100,
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={XMark}
										_icon={{
											size: 'md',
										}}
										py={0}
										onPress={() => unassignAll(item)}
										tooltip="Unassign All"
									/>;
						},
					},
				]}
			/>;
}

export default withComponent(withAlert(CrewAssignmentsGrid));