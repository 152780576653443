import { useState, useEffect, } from 'react';
import {
	WORK_ORDER_STATUS__OUSTANDING,
	WORK_ORDER_STATUS__PAID,
} from '../../constants/WorkOrderStatuses';
import {
	setIsWaitModalShown,
} from '../../models/Slices/DebugSlice';
import { useDispatch } from 'react-redux';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert';
import oneHatData from '@onehat/data';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import MoneyBill from '@onehat/ui/src/Components/Icons/MoneyBill';

function OutstandingWorkOrdersGrid(props) {
	const {
			alert,
		} = props,
		dispatch = useDispatch(),
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false),
		markPaid = async (item) => {
			dispatch(setIsWaitModalShown(true));
			
			item.work_orders__work_order_status_id = WORK_ORDER_STATUS__PAID;
			await item.save();
			alert('Marked as paid'); // TODO: handle errors

			await Repository.reload();

			dispatch(setIsWaitModalShown(false));
		};

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__OUSTANDING,
			});
			Repository.setSorters([{ name: 'work_orders__invoiced_date', direction: 'DESC' }]);
			
			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}

	return <DashboardWorkOrdersGrid
				Repository={Repository}
				columnsConfig={[
					{
						header: 'Paid',
						w: '50px',
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={MoneyBill}
										_icon={{
											size: 'md',
										}}
										py={0}
										onPress={() => markPaid(item)}
										tooltip="Mark as Paid"
									/>;
						},
					},
					{
						"header": "Work Order #",
						"fieldName": "work_orders__work_order_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Invoiced Date",
						"fieldName": "work_orders__invoiced_date",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 200 // MOD
					},
					{
						"header": "Is Bid?",
						"fieldName": "work_orders__is_bid",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 100 // MOD
					},
				]}
				{...props}
			/>;
}

export default withAlert(OutstandingWorkOrdersGrid);