import { useState, useEffect, } from 'react';
import {
	SELECTION_MODE_MULTI,
} from '@onehat/ui/src/Constants/Selection.js';
import {
	WORK_ORDER_STATUS__UNASSIGNED,
} from '../../constants/WorkOrderStatuses';
import {
	setIsWaitModalShown,
} from '../../models/Slices/DebugSlice';
import { useDispatch } from 'react-redux';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import oneHatData from '@onehat/data';
import DashboardWorkOrdersGrid from './DashboardWorkOrdersGrid';

function UnassignedWorkOrdersGrid(props) {
	const
		{
			self,
		} = props,
		dispatch = useDispatch(),
		[Repository, setRepository] = useState(null),
		[isReady, setIsReady] = useState(false),
		onDrop = async (item) => {
			dispatch(setIsWaitModalShown(true));

			const
				{
					id: crews_work_order_id,
					crew_id,
					work_order_id,
				} = item,
				UnassignedWorkOrders = self.repository,
				CrewAssignmentsGrid = self.parent.children.CrewAssignmentsGrid,
				// crews_work_order_id = item.id,

				// Unassign this work order to the crew via ajax
				params = {
					junction_id: crews_work_order_id,
					toUnassign: 'Crews',
				},
				result = await UnassignedWorkOrders._send('POST', 'WorkOrders/unassign', params);

			const {
				root,
				success,
				total,
				message
			} = UnassignedWorkOrders._processServerResponse(result);

			if (!success) {
				return;
			}
			
			// Reload the this grid
			await UnassignedWorkOrders.reload();

			// Reload the AssignedWorkOrdersGrid, if able
			if (CrewAssignmentsGrid.cachedExpandedRowComponents.current[crew_id]?.grid) {
				await CrewAssignmentsGrid.cachedExpandedRowComponents.current[crew_id].grid.repository.reload();
			}
			
			dispatch(setIsWaitModalShown(false));
		};

	useEffect(() => {
		let Repository;
		(async () => {
			const schema = oneHatData.getSchema('WorkOrders');
			Repository = await oneHatData.createRepository({ schema });
			Repository.setBaseParams({
				'conditions[work_orders__work_order_status_id]': WORK_ORDER_STATUS__UNASSIGNED,
			});
			Repository.setSorters([{ name: 'work_orders__date', direction: 'ASC' }]);
			
			self.repository = Repository;
			
			setRepository(Repository);
			setIsReady(true);
		})();
		return () => {
			if (Repository) {
				Repository.destroy();
			}
		};
	}, []);

	if (!isReady) {
		return null;
	}

	return <DashboardWorkOrdersGrid
				Repository={Repository}
				selectionMode={SELECTION_MODE_MULTI}
				areRowsDragSource={true}
				rowDragSourceType="UnassignedWorkOrders"
				isDropTarget={true}
				dropTargetAccept="AssignedWorkOrders"
				onDrop={onDrop}
				columnsConfig={[
					{
						"header": "#",
						"fieldName": "work_orders__address_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 50
					},
					{
						"header": "Dir",
						"fieldName": "work_orders__address_direction",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": '40px'
					},
					{
						"header": "Street",
						"fieldName": "work_orders__address_street",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Work Order #",
						"fieldName": "work_orders__work_order_number",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150
					},
					{
						"header": "Inspector",
						"fieldName": "work_orders__inspector",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 100
					},
					{
						"header": "WO Date",
						"fieldName": "work_orders__date",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 150 // MOD
					},
					{
						"header": "Is Bid?",
						"fieldName": "work_orders__is_bid",
						"sortable": true,
						"isEditable": true,
						"reorderable": true,
						"resizable": true,
						"w": 60
					},
					{
						"header": "Type",
						"fieldName": "work_order_types__name",
						"sortable": false,
						"isEditable": false,
						"reorderable": true,
						"resizable": true,
						"w": 100
					},
				]}
				{...props}
			/>;
}

export default withComponent(UnassignedWorkOrdersGrid);