/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import PaymentsInlineGridEditor from './PaymentsInlineGridEditor.js';

export default function PaymentsFilteredInlineGridEditor(props) {
	return <PaymentsInlineGridEditor
				reference="PaymentsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}