/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import UiGlobals from '@onehat/ui/src/UiGlobals.js';
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import useAdjustedWindowSize from '@onehat/ui/src/Hooks/useAdjustedWindowSize.js';
import ItemsWorkOrdersEditor from '../Editor/ItemsWorkOrdersEditor.js';

export default function ItemsWorkOrdersEditorWindow(props) {
	const
		styles = UiGlobals.styles,
		[width, height] = useAdjustedWindowSize(styles.DEFAULT_WINDOW_WIDTH, styles.DEFAULT_WINDOW_HEIGHT);
	
	return <Panel
				{...props}
				reference="ItemsWorkOrdersEditorWindow"
				isCollapsible={false}
				model="ItemsWorkOrders"
				titleSuffix={props.editorMode === 'EDITOR_MODE__VIEW' || props.isEditorViewOnly ? ' Viewer' : ' Editor'}
				bg="#fff"
				w={width}
				h={height}
				flex={null}
			>
				<ItemsWorkOrdersEditor {...props} />
			</Panel>;
}

