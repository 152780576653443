/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import GroupsEditor from '../Editor/GroupsEditor.js';

export default function GroupsSideGridEditor(props) {
	return <SideGridEditor
				reference="GroupsSideGridEditor"
				model="Groups"
				isCollapsible={false}
				Editor={GroupsEditor}
				defaultRowHeight={24}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "groups__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 250 // MOD
				    },
				    {
				        "header": "Description",
				        "fieldName": "groups__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 450 // MOD
				    }
				]}

				{...props}
			/>;
}