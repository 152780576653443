/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function NotesGrid(props) {
	return <Grid
				reference="NotesGrid"
				model="Notes"
				columnsConfig={[
				    {
				        "header": "Note",
				        "fieldName": "notes__note",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200
				    }
				]}

				{...props}
			/>;
}