/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import AttributesEditorWindow from '../../../Window/AttributesEditorWindow.js';

function AttributesTag(props) {
	return <Tag
				reference="AttributesTag"
				model="Attributes"
				uniqueRepository={true}
				Editor={AttributesEditorWindow}
				{...props}
			/>;
}

export default AttributesTag;