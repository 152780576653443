/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Print from '@onehat/ui/src/Components/Icons/Print.js';
import Duplicate from '@onehat/ui/src/Components/Icons/Duplicate.js';
import AppGlobals from '../AppGlobals';

export default function getWorkOrderEditorButtons(alert, cb) {
	return [
		{
			key: 'duplicateBtn',
			text: 'Duplicate',
			icon: Duplicate,
			handler: async (selection) => {
				if (!selection.length) {
					alert('No work order(s) selected!');
					return;
				}

				// create duplicate(s)
				const
					repository = selection[0].repository,
					params = {};
				if (selection.length === 1) {
					params.id = selection[0].id;
				} else {
					params.ids = selection.map((entity) => entity.id).join(',');
				}
				const result = await repository._send('POST', 'WorkOrders/duplicate', params);

				const {
					root,
					success,
					total,
					message
				} = repository._processServerResponse(result);
				
				if (success) {
					await repository.reload();
					if (cb) {
						cb(root, 'duplicateBtn');
					}
					alert('Duplication complete');
				} else {
					alert('Duplication failed! ' + message);
				}

			},
		},
		{
			key: 'printWorkOrderBtn',
			text: 'Print',
			icon: Print,
			handler: (self) => {
				const selection = self.selection;
				if (selection.length) {	
					const
						ids = selection.length === 1 ? selection[0].id : selection.map((entity) => entity.id).join(','),
						action = selection.length === 1 ? 'viewInvoice' : 'viewInvoices',
						url = AppGlobals.baseURL + 'WorkOrders/' + action + '/' + ids,
						win = window.open(url, '_blank');
					win.focus();
				} else {
					alert('No work order selected!');
				}
			},
		},
	];
};