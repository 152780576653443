/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ItemsEditorWindow from '../../../Window/ItemsEditorWindow.js';

function ItemsComboEditor(props) {
	return <ComboEditor
				reference="ItemsComboEditor"
				model="Items"
				uniqueRepository={true}
				Editor={ItemsEditorWindow}
				{...props}
			/>;
}

export default ItemsComboEditor;