/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';

export default function WorkOrderStatusesFilteredGridPanel(props) {
	return <GridPanel
				reference="WorkOrderStatusesFilteredGridPanel"
				model="WorkOrderStatuses"
				useFilters={true}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "work_order_statuses__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}

				{...props}
			/>;
}