/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import WorkOrdersEditorWindow from '../../../Window/WorkOrdersEditorWindow.js';

function WorkOrdersComboEditor(props) {
	return <ComboEditor
				reference="WorkOrdersComboEditor"
				model="WorkOrders"
				uniqueRepository={true}
				Editor={WorkOrdersEditorWindow}
				{...props}
			/>;
}

export default WorkOrdersComboEditor;