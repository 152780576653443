import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Items = {
	
	name: 'Items',
	model: {

		idProperty: 'items__id',
		displayProperty: 'items__label',
		sortProperty: 'items__id',
		
		sorters: null,

		validator: yup.object({
			items__label: yup.string().max(100).required(),
			items__description: yup.string().max(500).nullable(),
			items__price: yup.number().required(),
			items__client_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'items__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'items__label', mapping: 'label', title: 'Label', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'items__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'items__price', mapping: 'price', title: 'Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'items__client_id', mapping: 'client_id', title: 'Client', type: 'int', isFk: true, fkIdField: 'clients__id', fkDisplayField: 'clients__name', filterType: {"type":"ClientsCombo","loadAfterRender":!1}, editorType: {"type":"ClientsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'clients__id', mapping: 'client.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__name', mapping: 'client.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__vendor_number', mapping: 'client.vendor_number', title: 'Vendor Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_coversheet', mapping: 'client.show_coversheet', title: 'Show Coversheet?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_invoice', mapping: 'client.show_invoice', title: 'Show Invoice?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__show_original_pdf', mapping: 'client.show_original_pdf', title: 'Show Original PDF?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'clients__parser_keyword', mapping: 'client.parser_keyword', title: 'Parser Keyword', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'ItemsWorkOrders',
				'Discounts'
			],
			belongsTo: [
				'Clients'
			],
			belongsToMany: [
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'items__price',
			// 'items__client_id'
		],

	},

	entity: {
		methods: {

			getItemsWorkOrders: function() {
				const ItemsWorkOrders = this.getAssociatedRepository('ItemsWorkOrders');
				return ItemsWorkOrders.getBy((entity) => {
					return entity.items_work_orders__item_id === this.items__id;
				});
			},

			getClient: function() {
				const Clients = this.getAssociatedRepository('Clients');
				return Clients.getById(this.items__client_id);
			},

			getDiscounts: function() {
				const Discounts = this.getAssociatedRepository('Discounts');
				return Discounts.getBy((entity) => {
					return entity.discounts__item_id === this.items__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Items;
