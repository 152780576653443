/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function CrewsWorkOrdersFilteredInlineGridEditorPanel(props) {
	return <GridPanel
				reference="CrewsWorkOrdersFilteredInlineGridEditorPanel"
				model="CrewsWorkOrders"
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={[
				    {
				        "header": "Crew",
				        "fieldName": "crews__crew_name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Work Order",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Display Order",
				        "fieldName": "crews_work_orders__display_order",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}