/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import TimeEntriesEditorWindow from '../../Window/TimeEntriesEditorWindow.js';

export default function TimeEntriesGridEditorPanel(props) {
	return <GridPanel
				reference="TimeEntriesGridEditorPanel"
				model="TimeEntries"
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={TimeEntriesEditorWindow}
				columnsConfig={[
				    {
				        "header": "Start Time",
				        "fieldName": "time_entries__start_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "End Time",
				        "fieldName": "time_entries__end_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Description",
				        "fieldName": "time_entries__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}