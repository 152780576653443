/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import LotTypesEditor from '../../Editor/LotTypesEditor.js';

export default function LotTypesFilteredSideGridEditorPanel(props) {
	return <GridPanel
				reference="LotTypesFilteredSideGridEditorPanel"
				model="LotTypes"
				isCollapsible={false}
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={LotTypesEditor}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "lot_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}