/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import { useState, } from 'react';
import {
	Modal,
} from 'native-base';
import {
	SELECTION_MODE_SINGLE,
	SELECTION_MODE_MULTI,
} from '@onehat/ui/src/Constants/Selection.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import CrewsWorkOrdersGridEditor from './CrewsWorkOrdersGridEditor';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent';
import getWorkOrderRowProps from '../../functions/getWorkOrderRowProps.js';
import WorkOrdersEditorWindow from '../Window/WorkOrdersEditorWindow';
import Eye from '@onehat/ui/src/Components/Icons/Eye.js';
import Pdf from '@onehat/ui/src/Components/Icons/Pdf.js';
import Print from '@onehat/ui/src/Components/Icons/Print';
import viewPdf from '../../functions/viewPdf';
import printInvoice from '../../functions/printInvoice.js';

function DashboardCrewsWorkOrdersGridEditor(props) {
	const {
			Repository,
			alert,
			self,
		} = props,
		[isViewerShown, setIsViewerShown] = useState(false),
		[viewerSelection, setViewerSelection] = useState([]),
		onViewerClose = () => setIsViewerShown(false),
		onViewPdf = (selection) => {
			if (selection.selection) { // toolbar button was pressed
				selection = selection.selection;
			}
			if (!selection || selection.length === 0) {
				alert('Please make a selection first.');
				return;
			}

			const item = selection[0];
			viewPdf(item, alert);
		},
		onPrintInvoice = (selection) => {
			if (selection.selection) { // toolbar button was pressed
				selection = selection.selection;
			}
			if (!selection || selection.length === 0) {
				alert('Please make a selection first.');
				return;
			}

			const item = selection[0];
			printInvoice(item);
		},
		contextMenuItems = [
			{
				text: 'View PDF',
				handler: onViewPdf,
				icon: Pdf,
			},
			{
				text: 'Print Invoice',
				handler: onPrintInvoice,
				icon: Print,
			},
		],
		additionalToolbarButtons = [
			{
				key: 'viewPdfBtn',
				text: 'View PDF',
				handler: onViewPdf,
				icon: Pdf,
			},
			// doesn't include eye button because this is a CrewsWorkOrder, not a WorkOrder
			{
				key: 'printInvoiceBtn',
				text: 'Print Invoice',
				handler: onPrintInvoice,
				icon: Print,
			},
		];

	let assembledComponents =
		<CrewsWorkOrdersGridEditor
			selectionMode={SELECTION_MODE_MULTI}
			defaultRowHeight={20}
			contextMenuItems={contextMenuItems}
			additionalToolbarButtons={additionalToolbarButtons}
			getRowProps={getWorkOrderRowProps}

			uniqueRepository={true}
			autoAdjustPageSizeToHeight={false}
			disableAdd={true}
			disableEdit={true}
			disableDelete={true}
			disableView={true}
			disableCopy={true}
			disableDuplicate={true}
			disablePrint={true}

			{...props}
		/>;
	if (isViewerShown) {
		const propsForViewer = _.pick(props, [
			'disableCopy',
			'disableDuplicate',
			'disablePrint',
			'disableView',
			'value',
			'Repository',
			'data',
			'displayField',
			'displayIx',
			'fields',
			'idField',
			'idIx',
			'model',
			'name',
		]);
		assembledComponents = 
				<>
					{assembledComponents}
					<Modal
						isOpen={true}
						onClose={onViewerClose}
					>
						<WorkOrdersEditorWindow
							{...propsForViewer}
							editorType={EDITOR_TYPE__WINDOWED}
							isEditorViewOnly={true}
							px={0}
							py={0}
							w="100%"
							parent={self}
							reference="viewer"
							selection={viewerSelection}
							onEditorClose={onViewerClose}

							Repository={Repository}
						/>
					</Modal>
				</>;
	}
	return assembledComponents;
}

export default withComponent(withAlert(DashboardCrewsWorkOrdersGridEditor));