/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import TimeEntriesEditor from '../Editor/TimeEntriesEditor.js';

export default function TimeEntriesSideGridEditor(props) {
	return <SideGridEditor
				reference="TimeEntriesSideGridEditor"
				model="TimeEntries"
				isCollapsible={false}
				Editor={TimeEntriesEditor}
				columnsConfig={[
				    {
				        "header": "Start Time",
				        "fieldName": "time_entries__start_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "End Time",
				        "fieldName": "time_entries__end_time",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Description",
				        "fieldName": "time_entries__description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 400 // MOD
				    }
				]}

				{...props}
			/>;
}