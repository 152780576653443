import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Crews = {
	
	name: 'Crews',
	model: {

		idProperty: 'crews__id',
		displayProperty: 'crews__crew_name',
		sortProperty: 'crews__crew_name',
		
		sorters: null,

		validator: yup.object({
			crews__user_id: yup.number().integer().required(),
			crews__is_active: yup.boolean().required(),
			crews__crew_name: yup.string().max(50).required(),
			crews__back_to_shop: yup.boolean().required()
		}),
		
		properties: [
			{ name: 'crews__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'crews__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'crews__is_active', mapping: 'is_active', title: 'Is Active?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'crews__crew_name', mapping: 'crew_name', title: 'Crew Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'crews__back_to_shop', mapping: 'back_to_shop', title: 'Back To Shop', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__role', mapping: 'user.role', title: 'Role', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'CrewsWorkOrders'
			],
			belongsTo: [
				'Users'
			],
			belongsToMany: [
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'crews__user_id',
			// 'crews__is_active',
			// 'crews__back_to_shop'
		],

	},

	entity: {
		methods: {

			getCrewsWorkOrders: function() {
				const CrewsWorkOrders = this.getAssociatedRepository('CrewsWorkOrders');
				return CrewsWorkOrders.getBy((entity) => {
					return entity.crews_work_orders__crew_id === this.crews__id;
				});
			},

			getUser: function() {
				const Users = this.getAssociatedRepository('Users');
				return Users.getById(this.crews__user_id);
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
	    "isPaginated": false
	},

};

export default Crews;
