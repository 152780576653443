/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import WorkOrderTypesEditor from '../../Editor/WorkOrderTypesEditor.js';

export default function WorkOrderTypesFilteredSideGridEditorPanel(props) {
	return <GridPanel
				reference="WorkOrderTypesFilteredSideGridEditorPanel"
				model="WorkOrderTypes"
				isCollapsible={false}
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={WorkOrderTypesEditor}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "work_order_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Price",
				        "fieldName": "work_order_types__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}