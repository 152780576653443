/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import AttributesEditor from '../../Editor/AttributesEditor.js';

export default function AttributesSideGridEditorPanel(props) {
	return <GridPanel
				reference="AttributesSideGridEditorPanel"
				model="Attributes"
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={AttributesEditor}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "attributes__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Price",
				        "fieldName": "attributes__price",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}