import {
	WORK_ORDER_STATUS__UNASSIGNED,
	WORK_ORDER_STATUS__ASSIGNED,
	WORK_ORDER_STATUS__COMPLETED,
	WORK_ORDER_STATUS__OUSTANDING,
	WORK_ORDER_STATUS__PAID,
	WORK_ORDER_STATUS__RETURN_FOR_BID,
	WORK_ORDER_STATUS__NEED_TO_INVOICE,
	WORK_ORDER_STATUS__RETURN_TO_INSPECTOR,
	WORK_ORDER_STATUS__DBO,
	WORK_ORDER_STATUS__DUPLICATE,
	WORK_ORDER_STATUS__HOLD,
} from '../constants/WorkOrderStatuses';
import inArray from '@onehat/ui/src/Functions/inArray';

const HAS_NOTES = 'HAS_NOTES';
const NO_IMAGES = 'NO_IMAGES';
const BACK_TO_SHOP = 'BACK_TO_SHOP';


export default function getWorkOrderRowProps(item) {

	const classes = [];

	if (item.crews__back_to_shop) {
		classes.push(BACK_TO_SHOP);
	}
	if (item.work_orders__has_notes) {
		classes.push(HAS_NOTES);
	}
	if (!item.work_orders__has_images && 
		item.work_orders__work_order_status_id !== WORK_ORDER_STATUS__UNASSIGNED && 
		item.work_orders__work_order_status_id !== WORK_ORDER_STATUS__ASSIGNED) {
			classes.push(NO_IMAGES);
	}

	let bg = null;
	if (inArray(BACK_TO_SHOP, classes)) {
		bg = '#fcc';
	}
	if (inArray(HAS_NOTES, classes)) {
		bg = '#c2d7f1';
	}
	if (inArray(NO_IMAGES, classes)) {
		bg = '#f5bebe';
	}
	if (inArray(HAS_NOTES, classes) && inArray(NO_IMAGES, classes)) {
		bg = '#d0c2f1';
	}

	return {
		bg,
		borderBottomWidth: 1,
		borderBottomColor: 'trueGray.500',
	};
}