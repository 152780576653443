import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ItemsWorkOrders = {
	
	name: 'ItemsWorkOrders',
	model: {

		idProperty: 'items_work_orders__id',
		displayProperty: 'items_work_orders__adjustment',
		sortProperty: 'items_work_orders__id',
		
		sorters: null,

		validator: yup.object({
			items_work_orders__item_id: yup.number().integer().required(),
			items_work_orders__quantity: yup.number().integer().required(),
			items_work_orders__adjustment: yup.number().required()
		}),
		
		properties: [
			{ name: 'items_work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'items_work_orders__item_id', mapping: 'item_id', title: 'Item', type: 'int', isFk: true, fkIdField: 'items__id', fkDisplayField: 'items__label', filterType: {"type":"ItemsCombo","loadAfterRender":!1}, editorType: {"type":"ItemsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'items_work_orders__quantity', mapping: 'quantity', title: 'Quantity', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'items_work_orders__adjustment', mapping: 'adjustment', title: 'Adjustment', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'items_work_orders__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__work_order_number', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__uuid', mapping: 'work_order.uuid', title: 'UUID', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__work_order_number', mapping: 'work_order.work_order_number', title: 'Work Order #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__case_number', mapping: 'work_order.case_number', title: 'Case Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__inspector', mapping: 'work_order.inspector', title: 'Inspector', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__work_order_status_id', mapping: 'work_order.work_order_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__client_id', mapping: 'work_order.client_id', title: 'Client', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__work_order_type_id', mapping: 'work_order.work_order_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__lot_type_id', mapping: 'work_order.lot_type_id', title: 'Lot Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__address', mapping: 'work_order.address', title: 'Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__address_number', mapping: 'work_order.address_number', title: 'Address Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__address_direction', mapping: 'work_order.address_direction', title: 'Address Direction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__address_street', mapping: 'work_order.address_street', title: 'Address Street', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__work_description', mapping: 'work_order.work_description', title: 'Work Desc', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__legal_description', mapping: 'work_order.legal_description', title: 'Legal Desc', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__tax_id_number', mapping: 'work_order.tax_id_number', title: 'Tax ID Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__items', mapping: 'work_order.items', title: 'Items', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__attributes', mapping: 'work_order.attributes', title: 'Attributes', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__crew', mapping: 'work_order.crew', title: 'Assigned Crew', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__has_attachments', mapping: 'work_order.has_attachments', title: 'Has Attachments', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__has_notes', mapping: 'work_order.has_notes', title: 'Has Notes', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__has_images', mapping: 'work_order.has_images', title: 'Has Images', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__display_order', mapping: 'work_order.display_order', title: 'Display Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__estimate', mapping: 'work_order.estimate', title: 'Estimate', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__amount_due', mapping: 'work_order.amount_due', title: 'Amount Due', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_bid', mapping: 'work_order.is_bid', title: 'Is Bid?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_billed', mapping: 'work_order.is_billed', title: 'Is Billed?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_bid_approved', mapping: 'work_order.is_bid_approved', title: 'Is Bid Approved?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__user_id', mapping: 'work_order.user_id', title: 'User', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__date', mapping: 'work_order.date', title: 'WO Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__assigned_date', mapping: 'work_order.assigned_date', title: 'Assigned Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__completed_date', mapping: 'work_order.completed_date', title: 'Completed Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__completed_week', mapping: 'work_order.completed_week', title: 'Completed Wk', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoiced_date', mapping: 'work_order.invoiced_date', title: 'Invoiced Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__paid_date', mapping: 'work_order.paid_date', title: 'Paid Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__id', mapping: 'item.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__label', mapping: 'item.label', title: 'Label', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__description', mapping: 'item.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__price', mapping: 'item.price', title: 'Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'items__client_id', mapping: 'item.client_id', title: 'Client', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'WorkOrders',
				'Items'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'items_work_orders__item_id',
			// 'items_work_orders__quantity',
			// 'items_work_orders__adjustment',
			// 'items_work_orders__work_order_id'
		],

	},

	entity: {
		methods: {

			getWorkOrder: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getById(this.items_work_orders__work_order_id);
			},

			getItem: function() {
				const Items = this.getAssociatedRepository('Items');
				return Items.getById(this.items_work_orders__item_id);
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ItemsWorkOrders;
