/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import {
	TextArea,
} from 'native-base';
import * as yup from 'yup'; // https://github.com/jquense/yup#string
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import useAdjustedWindowSize from '@onehat/ui/src/Hooks/useAdjustedWindowSize.js';
import Form from '@onehat/ui/src/Components/Form/Form.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import {
	EDITOR_TYPE__PLAIN,
} from '@onehat/ui/src/Constants/Editor.js';
import _ from 'lodash';

function EmailBidEstimateWindow(props) {
	const {
			onCancel,
			onSubmit,

			// withComponent
			self,
		} = props,
		[width, height] = useAdjustedWindowSize(400, 470);

	return <Panel
				title="Email Bid Estimate"
				reference="EmailBidEstimateWindow"
				isCollapsible={false}
				bg="#fff"
				w={width}
				h={height}
				flex={null}
			>
				<Form
					items={[
						{
							type: "FieldSet",
							items: [
								{
									type: 'Input',
									label: 'Email Address(es)',
									name: 'email',
									tooltip: 'Email addresses to send list to, separated by commas'
								},
								{
									type: 'Input',
									label: 'Subject Line',
									name: 'subject',
									tooltip: 'The subject line for the email'
								},
								{
									type: 'TextArea',
									label: 'Message',
									name: 'message',
									h: 100,
									flex: null,
									tooltip: 'The message body for the email'
								}
							],
						},
					]}
					validator={yup.object({
						email: yup.string().required(),
						subject: yup.string().required(),
						message: yup.string().nullable(),
					})}
					startingValues={{
						email: null,
						subject: 'Alerts',
						message: null,
					}}
					editorType={EDITOR_TYPE__PLAIN}
					onCancel={onCancel}
					onClose={onCancel}
					onSubmit={onSubmit}
					parent={self}
					reference="RequireEstimateForm"
				/>
			</Panel>;
}

export default withComponent(EmailBidEstimateWindow);