import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Clients = {
	
	name: 'Clients',
	model: {

		idProperty: 'clients__id',
		displayProperty: 'clients__name',
		sortProperty: 'clients__name',
		
		sorters: null,

		validator: yup.object({
			clients__name: yup.string().max(50).required(),
			clients__vendor_number: yup.string().max(50).required(),
			clients__show_coversheet: yup.boolean().required(),
			clients__show_invoice: yup.boolean().required(),
			clients__show_original_pdf: yup.boolean().nullable(),
			clients__parser_keyword: yup.string().max(100).nullable()
		}),
		
		properties: [
			{ name: 'clients__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'clients__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'clients__vendor_number', mapping: 'vendor_number', title: 'Vendor Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'clients__show_coversheet', mapping: 'show_coversheet', title: 'Show Coversheet?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'clients__show_invoice', mapping: 'show_invoice', title: 'Show Invoice?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'clients__show_original_pdf', mapping: 'show_original_pdf', title: 'Show Original PDF?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'clients__parser_keyword', mapping: 'parser_keyword', title: 'Parser Keyword', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'WorkOrders',
				'Items'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'clients__show_coversheet',
			// 'clients__show_invoice',
			// 'clients__show_original_pdf'
		],

	},

	entity: {
		methods: {

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__client_id === this.clients__id;
				});
			},

			getItems: function() {
				const Items = this.getAssociatedRepository('Items');
				return Items.getBy((entity) => {
					return entity.items__client_id === this.clients__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
	    "isPaginated": false
	},

};

export default Clients;
