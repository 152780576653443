/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import {
	commonApplicationMimeTypes,
	commonAudioMimeTypes,
	commonImageMimeTypes,
	commonTextMimeTypes,
	commonVideoMimeTypes,
} from '@onehat/ui/src/Constants/MimeTypes.js';
import File from '@onehat/ui/src/Components/Icons/File';

import _ from 'lodash';

export default function WorkOrdersEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Identifiers",
		                "reference": "identifiers",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__uuid",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__work_order_number"
		                    },
		                    {
		                        "name": "work_orders__case_number"
		                    },
		                    {
		                        "name": "work_orders__inspector"
		                    },
		                    {
		                        "name": "work_orders__work_order_status_id"
		                    },
		                    {
		                        "name": "work_orders__client_id"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Description",
		                "reference": "description",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__work_order_type_id"
		                    },
		                    {
		                        "name": "work_orders__lot_type_id"
		                    },
		                    {
		                        "name": "work_orders__address_number"
		                    },
		                    {
		                        "name": "work_orders__address_direction"
		                    },
		                    {
		                        "name": "work_orders__address_street"
		                    },
		                    {
		                        "name": "work_orders__work_description"
		                    },
		                    {
		                        "name": "work_orders__legal_description"
		                    },
		                    {
		                        "name": "work_orders__tax_id_number"
		                    },
		                    // {
		                    //     "name": "work_orders__items"
		                    // },
		                    {
		                        "name": "work_orders__attributes"
		                    },
		                    {
		                        "name": "work_orders__crew",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__has_attachments",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__has_notes",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__has_images",
		                        "isEditable": false
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Amounts",
		                "reference": "amounts",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__estimate"
		                    },
		                    {
		                        "name": "work_orders__total",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__amount_due",
		                        "isEditable": false
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Switches",
		                "reference": "switches",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__is_bid"
		                    },
		                    {
		                        "name": "work_orders__is_billed"
		                    },
		                    {
		                        "name": "work_orders__is_bid_approved"
		                    },
		                    {
		                        "name": "work_orders__user_id"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Dates",
		                "reference": "dates",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "work_orders__date"
		                    },
		                    {
		                        "name": "work_orders__assigned_date"
		                    },
		                    {
		                        "name": "work_orders__completed_date"
		                    },
		                    {
		                        "name": "work_orders__completed_week",
		                        "isEditable": false
		                    },
		                    {
		                        "name": "work_orders__invoiced_date"
		                    },
		                    {
		                        "name": "work_orders__paid_date"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
			// {
			// 	title: 'Photos',
			// 	type: 'Attachments',
			// 	accept: commonImageMimeTypes.join(','),
			// 	confirmBeforeDelete: true,
			// },
			{
				title: 'Files',
				type: 'Attachments',
				// accept: [
				// 	...commonApplicationMimeTypes,
				// 	...commonAudioMimeTypes,
				// 	...commonTextMimeTypes,
				// 	...commonVideoMimeTypes,
				// ].join(','),
				confirmBeforeDelete: true,
				useFileMosaic: false,
			},
		    {
		        "title": "Crews",
		        "type": "CrewsWorkOrdersGridEditorSideA",
		        "selectorId": "crews_work_orders__work_order_id"
		    },
		    {
		        "title": "Items",
		        "type": "ItemsWorkOrdersGridEditorSideA",
		        "selectorId": "items_work_orders__work_order_id"
		    },
		    {
		        "title": "Notes",
		        "type": "NotesGridEditor",
		        "selectorId": "notes__work_order_id"
		    },
		    {
		        "title": "Payments",
		        "type": "PaymentsGridEditor",
		        "selectorId": "payments__work_order_id"
		    },
		    {
		        "title": "Time Entries",
		        "type": "TimeEntriesGridEditor",
		        "selectorId": "time_entries__work_order_id"
		    },
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="WorkOrdersEditor"
				title="WorkOrders"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				showViewPdfBtn={true}
				
				{...props}
			/>;
}

