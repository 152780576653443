/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function AttributesWorkOrdersGridSideB(props) {
	return <Grid
				reference="AttributesWorkOrdersGridSideB"
				model="AttributesWorkOrders"
				columnsConfig={[]}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}