/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import AttributesGrid from './AttributesGrid.js';

export default function AttributesFilteredGrid(props) {
	return <AttributesGrid
				reference="AttributesFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}