/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CrewsWorkOrdersGrid from './CrewsWorkOrdersGrid.js';

export default function CrewsWorkOrdersFilteredGrid(props) {
	return <CrewsWorkOrdersGrid
				reference="CrewsWorkOrdersFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}