/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CrewsWorkOrdersSideGridEditor from './CrewsWorkOrdersSideGridEditor.js';

export default function CrewsWorkOrdersFilteredSideGridEditor(props) {
	return <CrewsWorkOrdersSideGridEditor
				reference="CrewsWorkOrdersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}