export default async function(item, alert) {
	const
		repository = item.repository,
		data = {
			work_order_id: item.work_orders__id,
		};

	const result = await repository._send('POST', 'WorkOrders/getFirstPdf', data);
	const response = repository._processServerResponse(result);
	if (response.success) {
		if (response.root) {
			// show it in a new browser window
			window.open(response.root, '_blank');
		} else {
			alert('No PDF to display.');
		}
	}
};