/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function ItemsWorkOrdersInlineGridEditorPanel(props) {
	return <GridPanel
				reference="ItemsWorkOrdersInlineGridEditorPanel"
				model="ItemsWorkOrders"
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={[
				    {
				        "header": "Item",
				        "fieldName": "items__label",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100,
				        "editField": "items_work_orders__item_id"
				    },
				    {
				        "header": "Quantity",
				        "fieldName": "items_work_orders__quantity",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Adjustment",
				        "fieldName": "items_work_orders__adjustment",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}