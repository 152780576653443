/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import AttributesWorkOrdersEditorWindow from '../../Window/AttributesWorkOrdersEditorWindow.js';

export default function AttributesWorkOrdersGridEditorPanel(props) {
	return <GridPanel
				reference="AttributesWorkOrdersGridEditorPanel"
				model="AttributesWorkOrders"
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={AttributesWorkOrdersEditorWindow}
				columnsConfig={[
				    {
				        "header": "Attribute Id",
				        "fieldName": "attributes__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				
				
				{...props}
			/>;
}