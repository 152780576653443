/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function GroupsUsersGrid(props) {
	return <Grid
				reference="GroupsUsersGrid"
				model="GroupsUsers"
				columnsConfig={[
				    {
				        "header": "Group",
				        "fieldName": "groups__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}

				{...props}
			/>;
}