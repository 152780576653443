import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

export const debugSlice = createSlice({
	name: 'debug',
	initialState: {
		debugMessage: null,
		debugStack: [],
		alertMessage: null,
		infoMessage: null,
		isWaitModalShown: false,
		waitStack: {},
	},
	reducers: {
		setDebugMessage: (state, action) => {
			state.debugMessage = action.payload
		},
		setDebugStack: (state, action) => {
			state.debugStack = action.payload
		},
		setAlertMessage: (state, action) => {
			state.alertMessage = action.payload
		},
		setInfoMessage: (state, action) => {
			state.infoMessage = action.payload
		},
		setIsWaitModalShownAction: (state, action) => {
			state.isWaitModalShown = action.payload
		},
		setWaitStack: (state, action) => {
			state.waitStack = action.payload
		},
		// postAdded: {
		// 	reducer(state, action) {
		// 		state.push(action.payload)
		// 	},
		// 	prepare(title, content) {
		// 		return {
		// 			payload: {
		// 				id: nanoid(),
		// 				title,
		// 				content
		// 			}
		// 		}
		// 	}
		// }
	}
});


// action definitions

export const {
	setDebugMessage,
	setDebugStack,
	setAlertMessage,
	setInfoMessage,
	setIsWaitModalShownAction,
	setWaitStack,
} = debugSlice.actions;


// thunks

export function setIsWaitModalShown(bool, process = 'default') {
	return (dispatch, getState) => {
		const stack = _.clone(selectWaitStack(getState()));
		if (bool) {
			// Show it
			if (_.isEmpty(stack)) {
				dispatch(setIsWaitModalShownAction(true));
			}
			if (stack[process]) {
				stack[process]++;
			} else {
				stack[process] = 1;
			}
		} else {
			// Hide it
			if (stack[process] !== 1) {
				stack[process]--;
			} else {
				delete stack[process];
			}
			if (_.isEmpty(stack)) {
				dispatch(setIsWaitModalShownAction(false));
			}
		}
		dispatch(setWaitStack(stack));
	};
}


// selectors

export const selectDebugMessage = state => state.debug.debugMessage;
export const selectDebugStack = state => state.debug.debugStack;
export const selectAlertMessage = state => state.debug.alertMessage;
export const selectInfoMessage = state => state.debug.infoMessage;
export const selectIsWaitModalShown = state => state.debug.isWaitModalShown;
export const selectWaitStack = state => state.debug.waitStack;


export default debugSlice.reducer;