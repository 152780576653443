/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';

export default function ItemsWorkOrdersGridSideB(props) {
	return <Grid
				reference="ItemsWorkOrdersGridSideB"
				model="ItemsWorkOrders"
				columnsConfig={[
				    {
				        "header": "Quantity",
				        "fieldName": "items_work_orders__quantity",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Adjustment",
				        "fieldName": "items_work_orders__adjustment",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    }
				]}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}