/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__INLINE,
} from '@onehat/ui/src/Constants/Editor.js';

export default function ClientsFilteredInlineGridEditorPanel(props) {
	return <GridPanel
				reference="ClientsFilteredInlineGridEditorPanel"
				model="Clients"
				useFilters={true}
				isEditor={true}
				editorType={EDITOR_TYPE__INLINE}
				columnsConfig={[
				    {
				        "header": "Name",
				        "fieldName": "clients__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Vendor Number",
				        "fieldName": "clients__vendor_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Show Coversheet?",
				        "fieldName": "clients__show_coversheet",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Show Invoice?",
				        "fieldName": "clients__show_invoice",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Show Original PDF?",
				        "fieldName": "clients__show_original_pdf",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Parser Keyword",
				        "fieldName": "clients__parser_keyword",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    }
				]}
				
				
				{...props}
			/>;
}