/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import CrewsWorkOrdersGridEditor from './CrewsWorkOrdersGridEditor.js';

export default function CrewsWorkOrdersFilteredGridEditor(props) {
	return <CrewsWorkOrdersGridEditor
				reference="CrewsWorkOrdersFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}