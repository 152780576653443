/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import PermissionsEditorWindow from '../../Window/PermissionsEditorWindow.js';

export default function PermissionsGridEditorPanel(props) {
	return <GridPanel
				reference="PermissionsGridEditorPanel"
				model="Permissions"
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={PermissionsEditorWindow}
				columnsConfig={[
				    {
				        "header": "View Reports",
				        "fieldName": "permissions__view_reports",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Dashboard",
				        "fieldName": "permissions__view_dashboard",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Attachments",
				        "fieldName": "permissions__view_attachments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Attachments",
				        "fieldName": "permissions__add_attachments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Attachments",
				        "fieldName": "permissions__edit_attachments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Attachments",
				        "fieldName": "permissions__delete_attachments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Attributes",
				        "fieldName": "permissions__view_attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Attributes",
				        "fieldName": "permissions__add_attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Attributes",
				        "fieldName": "permissions__edit_attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Attributes",
				        "fieldName": "permissions__delete_attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Clients",
				        "fieldName": "permissions__view_clients",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Clients",
				        "fieldName": "permissions__add_clients",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Clients",
				        "fieldName": "permissions__edit_clients",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Clients",
				        "fieldName": "permissions__delete_clients",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Crews",
				        "fieldName": "permissions__view_crews",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Crews",
				        "fieldName": "permissions__add_crews",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Crews",
				        "fieldName": "permissions__edit_crews",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Crews",
				        "fieldName": "permissions__delete_crews",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Crews Work Orders",
				        "fieldName": "permissions__view_crews_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Crews Work Orders",
				        "fieldName": "permissions__add_crews_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Crews Work Orders",
				        "fieldName": "permissions__edit_crews_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Crews Work Orders",
				        "fieldName": "permissions__delete_crews_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Discounts",
				        "fieldName": "permissions__view_discounts",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Discounts",
				        "fieldName": "permissions__add_discounts",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Discounts",
				        "fieldName": "permissions__edit_discounts",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Discounts",
				        "fieldName": "permissions__delete_discounts",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Groups",
				        "fieldName": "permissions__view_groups",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Groups",
				        "fieldName": "permissions__add_groups",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Groups",
				        "fieldName": "permissions__edit_groups",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Groups",
				        "fieldName": "permissions__delete_groups",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Groups Users",
				        "fieldName": "permissions__view_groups_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Groups Users",
				        "fieldName": "permissions__add_groups_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Groups Users",
				        "fieldName": "permissions__edit_groups_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Groups Users",
				        "fieldName": "permissions__delete_groups_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Items",
				        "fieldName": "permissions__view_items",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Items",
				        "fieldName": "permissions__add_items",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Items",
				        "fieldName": "permissions__edit_items",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Items",
				        "fieldName": "permissions__delete_items",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Lot Types",
				        "fieldName": "permissions__view_lot_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Lot Types",
				        "fieldName": "permissions__add_lot_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Lot Types",
				        "fieldName": "permissions__edit_lot_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Lot Types",
				        "fieldName": "permissions__delete_lot_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Notes",
				        "fieldName": "permissions__view_notes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Notes",
				        "fieldName": "permissions__add_notes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Notes",
				        "fieldName": "permissions__edit_notes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Notes",
				        "fieldName": "permissions__delete_notes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Payments",
				        "fieldName": "permissions__view_payments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Payments",
				        "fieldName": "permissions__add_payments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Payments",
				        "fieldName": "permissions__edit_payments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Payments",
				        "fieldName": "permissions__delete_payments",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Permissions",
				        "fieldName": "permissions__view_permissions",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Permissions",
				        "fieldName": "permissions__add_permissions",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Permissions",
				        "fieldName": "permissions__edit_permissions",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Permissions",
				        "fieldName": "permissions__delete_permissions",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Time Entries",
				        "fieldName": "permissions__view_time_entries",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Time Entries",
				        "fieldName": "permissions__add_time_entries",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Time Entries",
				        "fieldName": "permissions__edit_time_entries",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Time Entries",
				        "fieldName": "permissions__delete_time_entries",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Users",
				        "fieldName": "permissions__view_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Users",
				        "fieldName": "permissions__add_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Users",
				        "fieldName": "permissions__edit_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Users",
				        "fieldName": "permissions__delete_users",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Orders",
				        "fieldName": "permissions__view_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Orders",
				        "fieldName": "permissions__add_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Orders",
				        "fieldName": "permissions__edit_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Orders",
				        "fieldName": "permissions__delete_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Orders Attributes",
				        "fieldName": "permissions__view_attributes_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Orders Attributes",
				        "fieldName": "permissions__add_attributes_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Orders Attributes",
				        "fieldName": "permissions__edit_attributes_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Orders Attributes",
				        "fieldName": "permissions__delete_attributes_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Orders Items",
				        "fieldName": "permissions__view_items_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Orders Items",
				        "fieldName": "permissions__add_items_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Orders Items",
				        "fieldName": "permissions__edit_items_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Orders Items",
				        "fieldName": "permissions__delete_items_work_orders",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Order Duplicates",
				        "fieldName": "permissions__view_work_order_duplicates",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Order Duplicates",
				        "fieldName": "permissions__add_work_order_duplicates",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Order Duplicates",
				        "fieldName": "permissions__edit_work_order_duplicates",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Order Duplicates",
				        "fieldName": "permissions__delete_work_order_duplicates",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Order Statuses",
				        "fieldName": "permissions__view_work_order_statuses",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Order Statuses",
				        "fieldName": "permissions__add_work_order_statuses",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Order Statuses",
				        "fieldName": "permissions__edit_work_order_statuses",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Order Statuses",
				        "fieldName": "permissions__delete_work_order_statuses",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "View Work Order Types",
				        "fieldName": "permissions__view_work_order_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Add Work Order Types",
				        "fieldName": "permissions__add_work_order_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Edit Work Order Types",
				        "fieldName": "permissions__edit_work_order_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Delete Work Order Types",
				        "fieldName": "permissions__delete_work_order_types",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    }
				]}
				
				
				{...props}
			/>;
}