import {
	Column,
	Row,
} from 'native-base';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import CrewAssignmentsGrid from '../components/Grid/CrewAssignmentsGrid.js';
import OCRValidationGrid from '../components/Grid/OCRValidationGrid.js';
import OutstandingWorkOrdersGrid from '../components/Grid/OutstandingWorkOrdersGrid.js';
import RecentlyCompletedWorkOrdersGrid from '../components/Grid/RecentlyCompletedWorkOrdersGrid.js';
import ReturnForBidWorkOrdersGrid from '../components/Grid/ReturnForBidWorkOrdersGrid.js';
import ReturnToInspectorWorkOrdersGrid from '../components/Grid/ReturnToInspectorWorkOrdersGrid.js';
import UnassignedWorkOrdersGrid from '../components/Grid/UnassignedWorkOrdersGrid.js';

function Dashboard(props) {
	const {
			self,
		} = props,
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Dashboard';
		
	if (!isActive) {
		return null;
	}

	return <Column flex={1} w="100%">
				<Row flex={2}>
					<Panel
						key="UnassignedWorkOrders"
						title="Unassigned Work Orders"
						flex={3}
						isCollapsible={false}
					>
						<UnassignedWorkOrdersGrid reference="UnassignedWorkOrdersGrid" parent={self} />
					</Panel>
					<Panel
						key="CrewAssignments"
						title="Crew Assignments"
						flex={2}
						isCollapsible={false}
					>
						<CrewAssignmentsGrid reference="CrewAssignmentsGrid" parent={self} />
					</Panel>
					<Panel
						key="RecentlyCompletedWorkOrders"
						title="Recently Completed Work Orders"
						flex={2}
						isCollapsible={false}
					>
						<RecentlyCompletedWorkOrdersGrid reference="RecentlyCompletedWorkOrdersGrid" />
					</Panel>
				</Row>
				
				<Row flex={1}>
					<Panel
						key="OutstandingWorkOrders"
						title="Outstanding Work Orders"
						flex={1}
						isCollapsible={false}
					>
						<OutstandingWorkOrdersGrid reference="OutstandingWorkOrdersGrid" />
					</Panel>
					<Panel
						key="ReturnForBidWorkOrders"
						title={'"Return for Bid" Work Orders'}
						flex={1}
						isCollapsible={false}
					>
						<ReturnForBidWorkOrdersGrid reference="ReturnForBidWorkOrdersGrid" />
					</Panel>
					<Panel
						key="ReturnToInspectorWorkOrders"
						title={'"Return to Inspector" Work Orders'}
						flex={1}
						isCollapsible={false}
					>
						<ReturnToInspectorWorkOrdersGrid reference="ReturnToInspectorWorkOrdersGrid" />
					</Panel>
					<Panel
						key="OCRValidation"
						title="OCR Validation"
						flex={1}
						isCollapsible={false}
					>
						<OCRValidationGrid reference="OCRValidationGrid" />
					</Panel>
				</Row>
			</Column>;
}

function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="Dashboard"
					{...props}
				/>;
	};
}

export default withAdditionalProps(withComponent(Dashboard));
