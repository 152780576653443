/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Attachments from './Attachments.js';
import Attributes from './Attributes.js';
import AttributesWorkOrders from './AttributesWorkOrders.js';
import Clients from './Clients.js';
import Crews from './Crews.js';
import CrewsWorkOrders from './CrewsWorkOrders.js';
import Discounts from './Discounts.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import Items from './Items.js';
import ItemsWorkOrders from './ItemsWorkOrders.js';
import Local from './Local.js';
import LotTypes from './LotTypes.js';
import Notes from './Notes.js';
import Payments from './Payments.js';
import Permissions from './Permissions.js';
import Prefs from './Prefs.js';
import Secure from './Secure.js';
import TimeEntries from './TimeEntries.js';
import Users from './Users.js';
import WorkOrders from './WorkOrders.js';
import WorkOrderDuplicates from './WorkOrderDuplicates.js';
import WorkOrderStatuses from './WorkOrderStatuses.js';
import WorkOrderTypes from './WorkOrderTypes.js';

const obj = {
	Attachments,
	Attributes,
	AttributesWorkOrders,
	Clients,
	Crews,
	CrewsWorkOrders,
	Discounts,
	Groups,
	GroupsUsers,
	Items,
	ItemsWorkOrders,
	Local,
	LotTypes,
	Notes,
	Payments,
	Permissions,
	Prefs,
	Secure,
	TimeEntries,
	Users,
	WorkOrders,
	WorkOrderDuplicates,
	WorkOrderStatuses,
	WorkOrderTypes
};
export default obj;