/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { InlineGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import getWorkOrderEditorButtons from '../../functions/getWorkOrderEditorButtons.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import Pdf from '@onehat/ui/src/Components/Icons/Pdf.js';
import viewPdf from '../../functions/viewPdf';
import _ from 'lodash';

function WorkOrdersInlineGridEditor(props) {
	const
		{
			self,
			alert,
		} = props,
		onDuplicateCallback = async (root, whichBtn) => {
			if (whichBtn !== 'duplicateBtn') {
				return;
			}

			if (_.isArray(root)) {
				return; // If multiple duplicates made, do nothing!
			}

			// Select the duplicate work order
			const 
				repository	= self.repository,
				newWorkOrder = repository.getById(root.id);
			if (newWorkOrder) {
				self.setSelection([newWorkOrder]);
			}
		},
		buttons = getWorkOrderEditorButtons(props.alert, onDuplicateCallback);
	return <InlineGridEditor
				parent={self}
				reference="WorkOrdersInlineGridEditor"
				model="WorkOrders"
				selectionMode={SELECTION_MODE_SINGLE}
				defaultRowHeight={24}
				disableDuplicate={true}
				contextMenuItems={buttons}
				additionalToolbarButtons={buttons}
				columnsConfig={[
					{
						header: 'PDF',
						w: 60,
						sortable: false,
						isEditable: false,
						reorderable: false,
						resizable: false,
						renderer: (item) => {
							return <IconButton
										icon={Pdf}
										_icon={{
											size: 'sm',
										}}
										onPress={() => viewPdf(item, alert)}
										tooltip="View Work Order"
									/>;
						},
					},
				    {
				        "header": "WO Date",
				        "fieldName": "work_orders__date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "UUID",
				        "fieldName": "work_orders__uuid",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Work Order #",
				        "fieldName": "work_orders__work_order_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Case Number",
				        "fieldName": "work_orders__case_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Inspector",
				        "fieldName": "work_orders__inspector",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Status",
				        "fieldName": "work_order_statuses__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100,
				        "editField": "work_orders__work_order_status_id",
				    },
				    {
				        "header": "Client",
				        "fieldName": "clients__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 300, // MOD
				        "editField": "work_orders__client_id",
				    },
				    {
				        "header": "Assigned Crew",
				        "fieldName": "work_orders__crew",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
					{
				        "header": "Has Attachments",
				        "fieldName": "work_orders__has_attachments",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Has Notes",
				        "fieldName": "work_orders__has_notes",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Has Images",
				        "fieldName": "work_orders__has_images",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50
				    },
				    {
				        "header": "Type",
				        "fieldName": "work_order_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150, // MOD
				        "editField": "work_orders__work_order_type_id",
				    },
				    {
				        "header": "Lot Type",
				        "fieldName": "lot_types__name",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150, // MOD
				        "editField": "work_orders__lot_type_id",
				    },
				    {
				        "header": "Number", // MOD
				        "fieldName": "work_orders__address_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Dir", // MOD
				        "fieldName": "work_orders__address_direction",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 50 // MOD
				    },
				    {
				        "header": "Street", // MOD
				        "fieldName": "work_orders__address_street",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Work Desc",
				        "fieldName": "work_orders__work_description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Legal Desc",
				        "fieldName": "work_orders__legal_description",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    {
				        "header": "Tax ID Number",
				        "fieldName": "work_orders__tax_id_number",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150
				    },
				    // {
				    //     "header": "Items",
				    //     "fieldName": "work_orders__items",
				    //     "sortable": true,
				    //     "isEditable": true,
				    //     "reorderable": true,
				    //     "resizable": true,
				    //     "w": 100
				    // },
				    {
				        "header": "Attributes",
				        "fieldName": "work_orders__attributes",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 250 // MOD
				    },
				    {
				        "header": "Estimate",
				        "fieldName": "work_orders__estimate",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Total",
				        "fieldName": "work_orders__total",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Amount Due",
				        "fieldName": "work_orders__amount_due",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Is Bid?",
				        "fieldName": "work_orders__is_bid",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Is Billed?",
				        "fieldName": "work_orders__is_billed",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100 // MOD
				    },
				    {
				        "header": "Is Bid Approved?",
				        "fieldName": "work_orders__is_bid_approved",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "User",
				        "fieldName": "users__username",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100,
				        "editField": "work_orders__user_id"
				    },
				    {
				        "header": "Assigned Date",
				        "fieldName": "work_orders__assigned_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Completed Date",
				        "fieldName": "work_orders__completed_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Completed Wk",
				        "fieldName": "work_orders__completed_week",
				        "sortable": true,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 100
				    },
				    {
				        "header": "Invoiced Date",
				        "fieldName": "work_orders__invoiced_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Paid Date",
				        "fieldName": "work_orders__paid_date",
				        "sortable": true,
				        "isEditable": true,
				        "reorderable": true,
				        "resizable": true,
				        "w": 200 // MOD
				    },
				    {
				        "header": "Has Attachments?",
				        "fieldName": "work_orders__has_attachments",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Has Notes?",
				        "fieldName": "work_orders__has_notes",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    },
				    {
				        "header": "Has Images?",
				        "fieldName": "work_orders__has_images",
				        "sortable": false,
				        "isEditable": false,
				        "reorderable": true,
				        "resizable": true,
				        "w": 150 // MOD
				    }
				]}

				{...props}
			/>;
}

export default withComponent(withAlert(WorkOrdersInlineGridEditor));
