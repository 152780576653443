/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ItemsWorkOrdersSideGridEditor from './ItemsWorkOrdersSideGridEditor.js';

export default function ItemsWorkOrdersFilteredSideGridEditor(props) {
	return <ItemsWorkOrdersSideGridEditor
				reference="ItemsWorkOrdersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}