/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import * as yup from 'yup'; // https://github.com/jquense/yup#string
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import useAdjustedWindowSize from '@onehat/ui/src/Hooks/useAdjustedWindowSize.js';
import Form from '@onehat/ui/src/Components/Form/Form.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import {
	EDITOR_TYPE__PLAIN,
} from '@onehat/ui/src/Constants/Editor.js';
import _ from 'lodash';


function RequireEstimateWindow(props) {
	const {
			onCancel,
			onSubmit,

			// withComponent
			self,
		} = props,
		[width, height] = useAdjustedWindowSize(400, 270);
	return <Panel
				title="Requires Bid Estimate"
				reference="AttributesEditorWindow"
				isCollapsible={false}
				bg="#fff"
				{...props}
				w={width}
				h={height}
				flex={null}
			>
				<Form
					items={[
						{
							type: "FieldSet",
							items: [
								{
									type:'DisplayField',
									name: 'instructions',
									value: 'This bid ($$$$) requires an estimated amount before it can be printed or emailed.'
								},
								{
									label: 'Estimate',
									name: 'work_orders__estimate',
									type: 'Number',
									tooltip: 'Estimated dollar amount for this bid'
								}
							],
						},
					]}
					validator={yup.object({
						work_orders__estimate: yup.number().required(),
					})}
					editorType={EDITOR_TYPE__PLAIN}
					onCancel={onCancel}
					onClose={onCancel}
					onSubmit={onSubmit}
					parent={self}
					reference="RequireEstimateForm"
				/>
			</Panel>;
}

export default withComponent(RequireEstimateWindow);